import { gql } from 'apollo-boost'

const COMPLETED_HTML_MENU_SCRAPING_JOBS_FOR_MENU = gql`
  query completedHtmlMenuScrapingJobsForMenu($menuId: ID!) {
    completedHtmlMenuScrapingJobsForMenu(menuId: $menuId) {
      id
      completedDate
      responseType
      scrapingStatus
    }
  }
`

export default COMPLETED_HTML_MENU_SCRAPING_JOBS_FOR_MENU
