import React, { useCallback, useEffect, useState } from 'react'
import { FormProvider, useFieldArray, useForm } from 'react-hook-form'
import ReactJson from 'react-json-view'
import { useHistory, useLocation } from 'react-router-dom'
import { useLazyQuery } from '@apollo/react-hooks'
import { yupResolver } from '@hookform/resolvers'
import {
  Checkbox as AntCheckbox,
  Col,
  Collapse,
  Empty,
  Form as AntForm,
  Icon,
  List,
  Row,
  Typography,
} from 'antd'
import fileDownload from 'js-file-download'
import { isEmpty } from 'lodash-es'
import PropTypes from 'prop-types'

import {
  FormDate,
  FormInternalLink,
  FormLink,
  FormText,
  PopoverReactJson,
} from '../../../core/components'
import { Button, Panel } from '../../../core/components/styled'
import { getQueryParams } from '../../../core/utils/services/queryParams'

import {
  HistoryDrawer,
  ScrapingInstructionsVersionsDrawer,
  Spinner,
} from '../../../common/components'
import {
  PageActions,
  PageHeader,
  PageTitle,
} from '../../../common/components/styled'
import {
  BOOLEAN_TYPES,
  DEFAULT_PAGE,
  EMPTY_VALUE,
  MENU,
} from '../../../common/constants'
import { showErrorNotification } from '../../../common/helpers'

import { LocationsList } from '../../../tasks/components/pages/sections'
import {
  HTML_NOT_SCRAPEABLE,
  MENU_FORMATS,
  MENU_NOT_SCRAPEABLE_REASONS,
} from '../../constants'
import { MENU_SCRAPING_REPORT_URL } from '../../graphql/queries'
import { isMenuScrapeable, isMenuScrapedSuccessfully } from '../../helpers'
import { HtmlMenuScrapingDebugModal } from '../pages'
import HtmlMenuTitlesAndDishesTables from '../pages/sections/html-menus/html-menu-titles-and-dishes-tables'

import { menuSchema } from './schemas'

const MenuForm = ({ menu, isUpdatingMenu, menuExcludeFromCurationChanged }) => {
  const history = useHistory()

  const [isScrapingDebugModalOpen, setIsScrapingDebugModalOpen] = useState(
    false,
  )
  const [selectedItemHash, setSelectedItemHash] = useState(null)
  const [isExcludedFromCuration, setIsExcludedFromCuration] = useState(
    menu.isExcludedFromCuration,
  )

  const formMethods = useForm({
    defaultValues: menu,
    resolver: yupResolver(menuSchema),
  })

  const { control } = formMethods

  const { search } = useLocation()
  const { page = DEFAULT_PAGE, pageSize = 10 } = getQueryParams(search)

  const [
    getScrapingReportUrl,
    { data: { menuScrapingReportUrl } = {}, loading },
  ] = useLazyQuery(MENU_SCRAPING_REPORT_URL, {
    variables: { id: menu.id },
    onCompleted() {
      window.open(menuScrapingReportUrl, '_blank')
    },
    onError({ message }) {
      showErrorNotification({
        message: 'Download scraping report failed.',
        description: message,
      })
    },
    fetchPolicy: 'no-cache',
  })

  const isScrapeable = isMenuScrapeable(menu.format)
  const isAttachedToCluster = !!menu.menuScrapingClusterId

  useEffect(() => {
    setIsExcludedFromCuration(menu.isExcludedFromCuration)
  }, [menu])

  const handleOpenScrapingDebugModal = useCallback(hash => {
    setSelectedItemHash(hash)
    setIsScrapingDebugModalOpen(true)
  }, [])

  const handleCloseScrapingDebugModal = useCallback(() => {
    setSelectedItemHash(null)
    setIsScrapingDebugModalOpen(false)
  }, [])

  const getDishReviewTaskLink = useCallback(
    dishReviewTaskId => `/tasks/dish-review/${dishReviewTaskId}`,
    [],
  )

  const getMenuScrapingClusterLink = useCallback(
    menuScrapingClusterId =>
      `/menu-scraping-templates/clusters/${menuScrapingClusterId}`,
    [],
  )

  const { fields: menuTitles } = useFieldArray({
    control,
    name: 'menuTitles',
  })

  const { fields: dishes } = useFieldArray({
    control,
    name: 'dishes',
  })

  const onMenuExcludeFromCurationChanged = useCallback(
    value => {
      setIsExcludedFromCuration(value)
      menuExcludeFromCurationChanged(value)
    },
    [menuExcludeFromCurationChanged],
  )

  return (
    <>
      {isUpdatingMenu && <Spinner size="large" />}
      <FormProvider {...formMethods}>
        <PageHeader sticky>
          <PageTitle>
            Menu
            <HistoryDrawer entityType={MENU} entityId={menu.id} />
          </PageTitle>
          <PageActions>
            <Button onClick={() => history.goBack()} margin="no small">
              Go back
            </Button>
          </PageActions>
        </PageHeader>
        <Panel>
          <Row gutter={[16, 16]}>
            <Col span={2}>
              <FormText name="id" label="Menu ID" />
            </Col>
            <Col span={4}>
              <FormText name="brand.name" label="Brand" />
            </Col>
            {!isEmpty(menu.locations) && (
              <Col span={5}>
                <LocationsList name="locations" label="Location(s):" />
              </Col>
            )}
            <Col span={6}>
              <FormLink name="url" label="Menu website" />
            </Col>

            <Col span={2}>
              <FormText
                label="Is example"
                name="isExample"
                transform={value => BOOLEAN_TYPES[value].name}
              />
            </Col>
            <Col span={3}>
              <AntForm.Item label="Exclude from curation">
                <AntCheckbox
                  onChange={({ target: { checked } }) =>
                    onMenuExcludeFromCurationChanged(checked)
                  }
                  checked={isExcludedFromCuration}
                />
              </AntForm.Item>
            </Col>
            <Col span={2}>
              <FormDate name="modifiedDate" label="Updated at" />
            </Col>
          </Row>

          <Row gutter={16}>
            {isAttachedToCluster && (
              <Col span={3}>
                <FormInternalLink
                  transform={getMenuScrapingClusterLink}
                  name="menuScrapingClusterId"
                  label="Scraping cluster ID"
                />
              </Col>
            )}
            {(isScrapeable || isAttachedToCluster) && (
              <Col span={3}>
                <FormInternalLink
                  transform={getDishReviewTaskLink}
                  name="dishReviewTask.id"
                  label="Dish review task ID"
                />
              </Col>
            )}
            <Col span={4}>
              <FormText
                label="Format"
                name="format"
                transform={value => MENU_FORMATS[value].name}
              />
            </Col>

            {menu.format === HTML_NOT_SCRAPEABLE && (
              <Col span={4}>
                <FormText
                  label="Not scrapeable reason"
                  name="notScrapeableReason"
                  transform={value =>
                    value && MENU_NOT_SCRAPEABLE_REASONS[value].name
                  }
                />
              </Col>
            )}

            {menu.menuScrapingAvailability && (
              <Col span={3}>
                Scraping availability:
                <Panel margin="xSmall no no no" style={{ width: 500 }}>
                  <PopoverReactJson source={menu.menuScrapingAvailability} />
                </Panel>
              </Col>
            )}
          </Row>
          <Row gutter={16} style={{ marginTop: '1rem' }}>
            {menu.format === HTML_NOT_SCRAPEABLE && (
              <Col span={8}>
                <FormText
                  label="Not scrapeable comment"
                  name="notScrapeableComment"
                />
              </Col>
            )}
          </Row>
        </Panel>

        {isScrapeable && !isAttachedToCluster && (
          <Panel margin="small no">
            <Typography.Title level={4} style={{ margin: '5px' }}>
              Scraping instructions
            </Typography.Title>

            {menu.scrapingInstructions && (
              <Button
                size="small"
                onClick={() =>
                  fileDownload(
                    menu.scrapingInstructions,
                    `${menu.id}-menu.json`,
                  )
                }
              >
                Download
                <Icon type="download" />
              </Button>
            )}

            {menu.scrapingInstructionsVersionNumber > 1 && (
              <ScrapingInstructionsVersionsDrawer menu={menu} />
            )}

            {menu.scrapingInstructions ? (
              <ReactJson
                src={JSON.parse(menu.scrapingInstructions)}
                name={false}
                enableClipboard={false}
                displayDataTypes={false}
                collapsed
              />
            ) : (
              <Empty
                image={Empty.PRESENTED_IMAGE_SIMPLE}
                description="No scraping instructions set"
              />
            )}
          </Panel>
        )}

        {(isScrapeable || isAttachedToCluster) && menu.scrapingSummary && (
          <Collapse defaultActiveKey={['scraping-service-section']}>
            <Collapse.Panel
              header="Scraping service information"
              key="scraping-service-section"
            >
              <Row>
                {menu.scrapingSummary.scrapingReport?.fileKey && (
                  <Col span={3}>
                    <AntForm.Item label="Scraping report">
                      <Button
                        margin="no small no no"
                        loading={loading}
                        onClick={() => getScrapingReportUrl()}
                      >
                        Download
                        <Icon type="download" />
                      </Button>
                    </AntForm.Item>
                  </Col>
                )}
                <Col span={3}>
                  <AntForm.Item label="Scraping details">
                    <Button
                      margin="no small no medium"
                      icon="eye"
                      disabled={!menu.isHtmlMenuScrapedInDebugMode}
                      onClick={() => handleOpenScrapingDebugModal(menu.id)}
                    />
                  </AntForm.Item>
                </Col>
                <Col span={4}>
                  <FormText
                    name="menu.scrapingSummary.menuAnnotations"
                    label="Menu annotations"
                  />
                </Col>

                {menu.scrapingSummary.status &&
                  !isMenuScrapedSuccessfully(menu.scrapingSummary.status) && (
                    <Col span={3}>
                      <FormText
                        name="menu.scrapingSummary.status"
                        label="Error code"
                      />
                    </Col>
                  )}

                <Col span={11}>
                  <AntForm.Item
                    label="Messages"
                    style={{ maxHeight: '230px', overflowY: 'scroll' }}
                  >
                    {isEmpty(menu.scrapingSummary.messages) ? (
                      EMPTY_VALUE
                    ) : (
                      <List
                        style={{ marginBottom: 15 }}
                        dataSource={menu.scrapingSummary.messages || []}
                        renderItem={message => (
                          <List.Item>{message.text}</List.Item>
                        )}
                      />
                    )}
                  </AntForm.Item>
                </Col>
              </Row>
            </Collapse.Panel>
          </Collapse>
        )}

        {!isEmpty(menuTitles) && (
          <HtmlMenuTitlesAndDishesTables
            menu={menu}
            menuTitles={menuTitles}
            dishes={dishes}
            isExpanded={false}
            page={page}
            pageSize={pageSize}
            onShowScrapingDetailsButtonClick={handleOpenScrapingDebugModal}
          />
        )}
      </FormProvider>

      <HtmlMenuScrapingDebugModal
        menu={menu}
        itemHash={selectedItemHash}
        isModalOpen={isScrapingDebugModalOpen}
        closeModal={handleCloseScrapingDebugModal}
      />
    </>
  )
}

MenuForm.propTypes = {
  menu: PropTypes.object.isRequired,
  isUpdatingMenu: PropTypes.bool.isRequired,
  menuExcludeFromCurationChanged: PropTypes.func.isRequired,
}

export default MenuForm
