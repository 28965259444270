import { gql } from 'apollo-boost'

const RESOLVE_MENU_SCRAPING_TASK_BY_REJECT_PDF_MENU = gql`
  mutation resolveMenuScrapingTaskByRejectPdfMenu(
    $id: ID!
    $menuPdfFilesData: [MenuScrapingTaskMenuPdfFileInput!]
    $crawlingRejectedComment: String!
  ) {
    resolveMenuScrapingTaskByRejectPdfMenu(
      id: $id
      menuPdfFilesData: $menuPdfFilesData
      crawlingRejectedComment: $crawlingRejectedComment
    ) {
      id
    }
  }
`

export default RESOLVE_MENU_SCRAPING_TASK_BY_REJECT_PDF_MENU
