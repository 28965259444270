import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import ReactJson from 'react-json-view'
import { useLazyQuery, useMutation } from '@apollo/react-hooks'
import { yupResolver } from '@hookform/resolvers'
import {
  Badge,
  Col,
  Collapse,
  Empty,
  Form as AntForm,
  Form,
  Icon,
  List,
  Row,
  Typography,
} from 'antd'
import copyToClipboard from 'copy-to-clipboard'
import fileDownload from 'js-file-download'
import { has, isEmpty, isNil, join, map, omit, values } from 'lodash-es'
import PropTypes from 'prop-types'

import {
  Breadcrumb,
  FormDate,
  FormInternalLink,
  FormLink,
  FormText,
  HiddenCheckboxes,
  HiddenInputs,
  Select,
  TextArea,
  Upload,
} from '../../../core/components'
import { Button, Panel } from '../../../core/components/styled'
import { THEME } from '../../../core/constants'

import {
  HistoryDrawer,
  HtmlMenuScrapingHistoryDrawer,
  ScrapingInstructionsDiffModal,
  ScrapingInstructionsVersionsDrawer,
  Spinner,
  VisibilityContainer,
} from '../../../common/components'
import {
  PageActions,
  PageHeader,
  PageTitle,
} from '../../../common/components/styled'
import {
  ADMIN,
  EMPTY_VALUE,
  MENU_SCRAPING_TASK,
  MENU_SCRAPING_TASKS_PERMISSIONS,
  TEAM_LEADER,
} from '../../../common/constants'
import {
  showErrorNotification,
  showSuccessNotification,
} from '../../../common/helpers'
import { useIsLoggedInUser, useUserHasRoles } from '../../../common/hooks'

import { AuthContext } from '../../../account/contexts'
import { HtmlMenuScrapingDebugModal } from '../../../menus/components/pages'
import {
  AUTO_REJECTED,
  HTML,
  HTML_NOT_SCRAPEABLE,
  MENU_FORMATS,
  MENU_NOT_SCRAPEABLE_REASONS,
  MENU_STATUSES,
  PDF,
} from '../../../menus/constants'
import { MENU_SCRAPING_REPORT_URL } from '../../../menus/graphql/queries'
import {
  isMenuScrapeable,
  isMenuScrapedSuccessfully,
} from '../../../menus/helpers'
import {
  MANUAL_CHANGE,
  MENU_SCRAPING,
  REOPENED,
  TASK_AWAITING_REASONS,
  TASK_REOPEN_REASONS,
  TASK_VERIFIED_STATUSES,
} from '../../constants'
import {
  ASSIGN_MENU_SCRAPING_TASK,
  MARK_MENU_SCRAPING_TASK_AS_AWAITING,
  MARK_MENU_SCRAPING_TASK_AS_VERIFIED,
  MARK_MENU_SCRAPING_TASK_MISC_AS_QC_VERIFIED,
  MARK_MENU_SCRAPING_TASK_MISC_AS_VERIFIED,
  REOPEN_MENU_SCRAPING_TASK,
  UNASSIGN_MENU_SCRAPING_TASK,
  UNMARK_MENU_SCRAPING_TASK_AS_AWAITING,
  UNMARK_MENU_SCRAPING_TASK_AS_VERIFIED,
} from '../../graphql/mutations'
import { getBreadcrumbItemsForMenuScrapingTaskPage } from '../../helpers'
import { useCanUserReopenMenuScrapingTask } from '../../hooks'
import {
  AwaitingReasonField,
  LocationsList,
  MarkMiscTaskAsVerifiedModal,
  MenuScrapingIssueCommentModal,
  MiscTaskVerificationThreeStateSwitch,
  ScrapingVerificationThreeStateSwitch,
  TaskActionSection,
} from '../pages/sections'

import { menuScrapingTaskFormInitialValues } from './initial-values'
import {
  resolveMenuScrapingTaskSchema,
  saveMenuScrapingTaskSchema,
} from './schemas'
import { withSchemaValidator } from './validators'

const HtmlMenuScrapingTaskForm = ({
  task,
  isSavingTask,
  isResolvingTask,
  onSubmit,
}) => {
  const { user: loggedInUser } = useContext(AuthContext)

  const [
    isScrapingIssueCommentModalOpen,
    setIsScrapingIssueCommentModalOpen,
  ] = useState(false)

  const [isScrapingIssueCommentSet, setIsScrapingIssueCommentSet] = useState(
    !!task.menu.scrapingIssueComment,
  )

  const [isScrapingDebugModalOpen, setIsScrapingDebugModalOpen] = useState(
    false,
  )

  const [isQCVerified, setIsQCVerified] = useState(false)

  const [
    isMarkMiscTaskAsVerifiedModalOpen,
    setIsMarkMiscTaskAsVerifiedModalOpen,
  ] = useState(false)

  const [selectedItemHash, setSelectedItemHash] = useState(null)

  const [
    scrapingInstructionsDiffModalSettings,
    setScrapingInstructionsDiffModalSettings,
  ] = useState({
    isOpen: false,
  })

  const formInitialValues = useMemo(
    () => menuScrapingTaskFormInitialValues(task),
    [task],
  )

  const formMethods = useForm({
    defaultValues: formInitialValues,
    resolver: yupResolver(saveMenuScrapingTaskSchema),
  })

  const {
    handleSubmit,
    watch,
    setValue,
    getValues,
    errors,
    setError,
    clearErrors,
  } = formMethods

  const isLoggedInUser = useIsLoggedInUser(watch('task.assignedTo'))

  const isTaskAssignedToLoggedInUser = watch('task.isAssigned')
    ? isLoggedInUser
    : false

  const isScrapeable = isMenuScrapeable(watch('menu.format'))

  const { menu } = task

  const isAdmin = useUserHasRoles(ADMIN)
  const isTeamLeader = useUserHasRoles(TEAM_LEADER)

  const [isSaveButtonDisabled, setIsSaveButtonDisabled] = useState(true)
  const [initialMenuFormat, setInitialMenuFormat] = useState(menu.format)

  const [
    initialMenuNotScrapeableReason,
    setInitialMenuNotScrapeableReason,
  ] = useState(menu.notScrapeableReason)

  const [
    initialMenuNotScrapeableComment,
    setInitialMenuNotScrapeableComment,
  ] = useState(menu.notScrapeableComment)

  const newScrapingInstructions = watch('menu.scrapingInstructions')
  const taskStatus = watch('task.status')
  const menuFormat = watch('menu.format')
  const menuNotScrapeableReason = watch('menu.notScrapeableReason')
  const menuNotScrapeableComment = watch('menu.notScrapeableComment')

  const normalizeValue = value => value ?? ''

  useEffect(() => {
    setValue('menu.status', menu.status && MENU_STATUSES[menu.status].name)
    setValue('menu.modifiedDate', menu.modifiedDate)
    setInitialMenuFormat(menu.format)
    setInitialMenuNotScrapeableReason(menu.notScrapeableReason)
    setInitialMenuNotScrapeableComment(menu.notScrapeableComment)
  }, [menu, setValue])

  useEffect(() => {
    const normalizedMenuNotScrapeableComment = normalizeValue(
      menuNotScrapeableComment,
    )

    const normalizedInitialMenuNotScrapeableComment = normalizeValue(
      initialMenuNotScrapeableComment,
    )

    if (
      menuFormat !== initialMenuFormat ||
      menuNotScrapeableReason !== initialMenuNotScrapeableReason ||
      normalizedMenuNotScrapeableComment !==
        normalizedInitialMenuNotScrapeableComment
    ) {
      setIsSaveButtonDisabled(false)
    } else {
      setIsSaveButtonDisabled(true)
    }
  }, [
    menuFormat,
    initialMenuFormat,
    menuNotScrapeableReason,
    initialMenuNotScrapeableReason,
    menuNotScrapeableComment,
    initialMenuNotScrapeableComment,
    getValues,
  ])

  const [assignTask, { loading: isAssigningTask }] = useMutation(
    ASSIGN_MENU_SCRAPING_TASK,
    {
      onCompleted({ assignMenuScrapingTask }) {
        const { task } = getValues()

        const updatedTask = {
          ...task,
          modifiedDate: assignMenuScrapingTask.modifiedDate,
          isAssigned: true,
          assignedTo: {
            id: loggedInUser.id,
            name: loggedInUser.name,
          },
        }

        setValue('task', updatedTask)

        showSuccessNotification({
          message: 'Menu scraping task assigned.',
        })
      },
      onError({ message }) {
        showErrorNotification({
          message: 'Menu scraping task assign failed.',
          description: message,
        })
      },
    },
  )

  const [unassignTask, { loading: isUnassigningTask }] = useMutation(
    UNASSIGN_MENU_SCRAPING_TASK,
    {
      onCompleted({ unassignMenuScrapingTask }) {
        const { task } = getValues()

        const updatedTask = {
          ...task,
          modifiedDate: unassignMenuScrapingTask.modifiedDate,
          isAssigned: false,
          assignedTo: {
            id: '',
            name: '',
          },
        }

        setValue('task', updatedTask)
        showSuccessNotification({
          message: 'Menu scraping task unassigned.',
        })
      },
      onError({ message }) {
        showErrorNotification({
          message: 'Menu scraping task unassign failed.',
          description: message,
        })
      },
    },
  )

  const [
    markTaskAsAwaiting,
    { loading: isMarkingTaskAsAwaiting },
  ] = useMutation(MARK_MENU_SCRAPING_TASK_AS_AWAITING, {
    onCompleted({ markMenuScrapingTaskAsAwaiting }) {
      const { task } = getValues()

      const updatedTask = {
        ...task,
        modifiedDate: markMenuScrapingTaskAsAwaiting.modifiedDate,
        isAwaiting: true,
        awaitingReason:
          TASK_AWAITING_REASONS[markMenuScrapingTaskAsAwaiting.awaitingReason]
            .name,
        awaitingReasonComment:
          markMenuScrapingTaskAsAwaiting.awaitingReasonComment,
      }

      setValue('task', updatedTask)

      showSuccessNotification({
        message: 'Menu scraping task marked as awaiting.',
      })
    },
    onError({ message }) {
      showErrorNotification({
        message: 'Menu scraping task mark as awaiting failed.',
        description: message,
      })
    },
  })

  const [
    unmarkTaskAsAwaiting,
    { loading: isUnmarkingTaskAsAwaiting },
  ] = useMutation(UNMARK_MENU_SCRAPING_TASK_AS_AWAITING, {
    onCompleted({ unmarkMenuScrapingTaskAsAwaiting }) {
      const { task } = getValues()

      const updatedTask = {
        ...task,
        modifiedDate: unmarkMenuScrapingTaskAsAwaiting.modifiedDate,
        isAwaiting: false,
        awaitingReason: '',
        awaitingReasonComment: '',
      }

      setValue('task', updatedTask)

      showSuccessNotification({
        message: 'Menu scraping task unmarked as awaiting.',
      })
    },
    onError({ message }) {
      showErrorNotification({
        message: 'Menu scraping task unmark as awaiting failed.',
        description: message,
      })
    },
  })

  const [reopenTask, { loading: isReopeningTask }] = useMutation(
    REOPEN_MENU_SCRAPING_TASK,
    {
      onCompleted({ reopenMenuScrapingTask: { modifiedDate, reopenedDate } }) {
        const { task } = getValues()

        const updatedTask = {
          ...task,
          modifiedDate,
          verifiedBy: {
            id: null,
            name: null,
          },
          verifiedDate: null,
          isVerifiedInDebugMode: false,
          reopenedBy: {
            id: loggedInUser.id,
            name: loggedInUser.name,
          },
          reopenedDate,
          reopenReason: TASK_REOPEN_REASONS[MANUAL_CHANGE].name,
          status: REOPENED,
        }

        setValue('task', updatedTask)

        showSuccessNotification({
          message: 'Menu scraping task reopened.',
        })
      },
      onError({ message }) {
        showErrorNotification({
          message: 'Menu scraping task reopen failed.',
          description: message,
        })
      },
    },
  )

  const [
    markTaskAsVerified,
    { loading: isMarkingTaskAsVerified },
  ] = useMutation(MARK_MENU_SCRAPING_TASK_AS_VERIFIED, {
    onCompleted({ markMenuScrapingTaskAsVerified: { isVerifiedInDebugMode } }) {
      const { task } = getValues()

      const updatedTask = {
        ...task,
        verifiedBy: {
          id: loggedInUser.id,
          name: loggedInUser.name,
        },
        verifiedDate: new Date().toISOString(),
        isVerifiedInDebugMode,
      }

      setValue('task', updatedTask)

      showSuccessNotification({
        message: 'Menu scraping task marked as verified.',
      })
    },
    onError({ message }) {
      showErrorNotification({
        message: 'Menu scraping task mark as verified failed.',
        description: message,
      })
    },
  })

  const [
    unmarkTaskAsVerified,
    { loading: isUnmarkingTaskAsVerified },
  ] = useMutation(UNMARK_MENU_SCRAPING_TASK_AS_VERIFIED, {
    onCompleted() {
      const { task } = getValues()

      const updatedTask = {
        ...task,
        verifiedBy: {
          id: null,
          name: null,
        },
        verifiedDate: null,
        isVerifiedInDebugMode: false,
      }

      setValue('task', updatedTask)

      showSuccessNotification({
        message: 'Menu scraping task unmarked as verified.',
      })
    },
    onError({ message }) {
      showErrorNotification({
        message: 'Menu scraping task unmark as verified failed.',
        description: message,
      })
    },
  })

  const [
    markMiscTaskAsVerified,
    { loading: isMarkingMiscTaskAsVerified },
  ] = useMutation(MARK_MENU_SCRAPING_TASK_MISC_AS_VERIFIED, {
    onCompleted({
      markMenuScrapingTaskMiscAsVerified: {
        miscTaskVerifiedDate,
        miscTaskVerifiedComment,
      },
    }) {
      const { task } = getValues()

      const updatedTask = {
        ...task,
        miscTaskVerifiedBy: {
          name: loggedInUser.name,
        },
        miscTaskVerifiedDate,
        miscTaskVerifiedComment,
      }

      setValue('task', updatedTask)

      showSuccessNotification({
        message: 'Miscellaneous task marked as verified.',
      })
    },
    onError({ message }) {
      showErrorNotification({
        message: 'Miscellaneous task mark as verified failed.',
        description: message,
      })
    },
  })

  const [
    markMiscTaskAsQCVerified,
    { loading: isMarkingMiscTaskAsQCVerified },
  ] = useMutation(MARK_MENU_SCRAPING_TASK_MISC_AS_QC_VERIFIED, {
    onCompleted({
      markMenuScrapingTaskMiscAsQCVerified: {
        miscTaskQCVerifiedDate,
        miscTaskQCVerifiedComment,
      },
    }) {
      const { task } = getValues()

      const updatedTask = {
        ...task,
        miscTaskQCVerifiedBy: {
          name: loggedInUser.name,
        },
        miscTaskQCVerifiedDate,
        miscTaskQCVerifiedComment,
      }

      setValue('task', updatedTask)

      showSuccessNotification({
        message: 'Miscellaneous task marked as QC verified.',
      })
    },
    onError({ message }) {
      showErrorNotification({
        message: 'Miscellaneous task mark as QC verified failed.',
        description: message,
      })
    },
  })

  const [
    getScrapingReportUrl,
    { data: { menuScrapingReportUrl } = {}, loading },
  ] = useLazyQuery(MENU_SCRAPING_REPORT_URL, {
    variables: { id: task.menu.id },
    onCompleted() {
      window.open(menuScrapingReportUrl, '_blank')
    },
    onError({ message }) {
      showErrorNotification({
        message: 'Download scraping report failed.',
        description: message,
      })
    },
    fetchPolicy: 'no-cache',
  })

  const onAssignTask = useCallback(
    () =>
      assignTask({
        variables: { id: task.id, toUserId: loggedInUser.id },
      }),
    [assignTask, task, loggedInUser],
  )

  const onUnassignTask = useCallback(
    () => unassignTask({ variables: { id: task.id } }),
    [unassignTask, task],
  )

  const onMarkTaskAsAwaiting = useCallback(
    awaitingReasonData =>
      markTaskAsAwaiting({
        variables: { id: task.id, ...awaitingReasonData },
      }),
    [markTaskAsAwaiting, task],
  )

  const onUnmarkTaskAsAwaiting = useCallback(() => {
    unmarkTaskAsAwaiting({ variables: { id: task.id } })
  }, [unmarkTaskAsAwaiting, task])

  const onReopenTask = useCallback(
    () => reopenTask({ variables: { id: task.id } }),
    [reopenTask, task],
  )

  const onVerifiedChanged = useCallback(
    status => {
      switch (status) {
        case TASK_VERIFIED_STATUSES.VERIFIED_IN_DEFAULT_MODE:
          markTaskAsVerified({
            variables: { id: task.id, isVerifiedInDebugMode: false },
          })
          return
        case TASK_VERIFIED_STATUSES.VERIFIED_IN_DEBUG_MODE:
          markTaskAsVerified({
            variables: { id: task.id, isVerifiedInDebugMode: true },
          })
          return
        default:
          unmarkTaskAsVerified({
            variables: { id: task.id },
          })
      }
    },
    [markTaskAsVerified, unmarkTaskAsVerified, task],
  )

  const onMiscTaskVerifiedChanged = isQCVerifiedButtonClicked => {
    setIsQCVerified(isQCVerifiedButtonClicked)
    setIsMarkMiscTaskAsVerifiedModalOpen(true)
  }

  const handleMarkMiscTaskAsVerified = useCallback(
    ({ comment }) => {
      const data = { variables: { id: task.id, comment } }
      setIsMarkMiscTaskAsVerifiedModalOpen(false)

      return isQCVerified
        ? markMiscTaskAsQCVerified(data)
        : markMiscTaskAsVerified(data)
    },
    [isQCVerified, markMiscTaskAsVerified, markMiscTaskAsQCVerified, task],
  )

  const onBrandMenuFormatChange = useCallback(
    format => {
      if (!isMenuScrapeable(format)) {
        setValue('menu.scrapingInstructions', null)
      }

      if (format !== HTML_NOT_SCRAPEABLE) {
        setValue('menu.notScrapeableReason', null)
      }

      if (format !== HTML_NOT_SCRAPEABLE) {
        setValue('menu.notScrapeableComment', null)
      }
    },
    [setValue],
  )

  const onCommentChanged = useCallback(
    (comment, status) => {
      setIsScrapingIssueCommentSet(!!comment)
      setValue('menu.status', MENU_STATUSES[status].name)
    },
    [setIsScrapingIssueCommentSet, setValue],
  )

  const pageTitle = useMemo(
    () =>
      menuFormat === HTML ? 'HTML Menu Scraping Task' : 'Menu Scraping Task',
    [menuFormat],
  )

  const scrapingIssueButtonColor = useMemo(
    () => (isScrapingIssueCommentSet ? THEME.colors.red : THEME.colors.grey),
    [isScrapingIssueCommentSet],
  )

  const isAttachedToCluster = useMemo(() => !!menu.menuScrapingClusterId, [
    menu,
  ])

  const menuFormatOptions = useMemo(
    () =>
      isAttachedToCluster
        ? values(omit(MENU_FORMATS, [HTML, PDF]))
        : values(omit(MENU_FORMATS, [PDF])),
    [isAttachedToCluster],
  )

  const notScrapableReasonOptions = useMemo(
    () => values(MENU_NOT_SCRAPEABLE_REASONS),
    [],
  )

  const handleOpenScrapingDebugModal = useCallback(hash => {
    setSelectedItemHash(hash)
    setIsScrapingDebugModalOpen(true)
  }, [])

  const handleCloseScrapingDebugModal = useCallback(() => {
    setSelectedItemHash(null)
    setIsScrapingDebugModalOpen(false)
  }, [])

  const onSave = useCallback(async () => {
    setValue('task.isDone', false)
    const formValues = getValues()

    const { isValid, errors } = await withSchemaValidator(
      formValues,
      saveMenuScrapingTaskSchema,
    )

    if (isValid) {
      clearErrors()
      handleSubmit(formValues => onSubmit(formValues))()
    } else {
      Object.keys(errors).forEach(field => {
        setError(field, { type: 'manual', message: errors[field] })
      })
    }
  }, [setValue, getValues, handleSubmit, onSubmit, setError, clearErrors])

  const onDone = useCallback(async () => {
    setValue('task.isDone', true)
    const formValues = getValues()

    const { isValid, errors } = await withSchemaValidator(
      formValues,
      resolveMenuScrapingTaskSchema,
    )

    if (isValid) {
      clearErrors()
      handleSubmit(formValues => onSubmit(formValues))()
    } else {
      Object.keys(errors).forEach(field => {
        setError(field, { type: 'manual', message: errors[field] })
      })
    }
  }, [setValue, getValues, handleSubmit, onSubmit, setError, clearErrors])

  const handleSubmitWithScrapeInDebugMode = useCallback(async () => {
    setValue('task.isDone', true)
    const formValues = getValues()

    const { isValid, errors } = await withSchemaValidator(
      formValues,
      resolveMenuScrapingTaskSchema,
    )

    if (isValid) {
      clearErrors()
      handleSubmit(formValues =>
        onSubmit({ ...formValues, isResolveWithDebugModeScraping: true }),
      )()
    } else {
      Object.keys(errors).forEach(field => {
        setError(field, { type: 'manual', message: errors[field] })
      })
    }
  }, [setValue, getValues, handleSubmit, onSubmit, setError, clearErrors])

  const canReopenTask = useCanUserReopenMenuScrapingTask(
    loggedInUser,
    watch('task'),
  )

  const menuAnnotations = useMemo(() => {
    if (
      task.menu.scrapingSummary &&
      task.menu.scrapingSummary.menuAnnotations
    ) {
      return join(
        map(task.menu.scrapingSummary.menuAnnotations, 'fullMenu'),
        ', ',
      )
    }
    return ''
  }, [task])

  const breadcrumbItems = useMemo(
    () => getBreadcrumbItemsForMenuScrapingTaskPage(task),
    [task],
  )

  return (
    <>
      {(isAssigningTask ||
        isUnassigningTask ||
        isMarkingTaskAsAwaiting ||
        isUnmarkingTaskAsAwaiting ||
        isReopeningTask ||
        isSavingTask ||
        isResolvingTask) && <Spinner size="large" />}

      <Breadcrumb currentTaskType={MENU_SCRAPING} items={breadcrumbItems} />

      <PageHeader sticky>
        <PageTitle>
          {pageTitle}
          <HistoryDrawer entityType={MENU_SCRAPING_TASK} entityId={task.id} />
          {isScrapeable && (
            <ScrapingVerificationThreeStateSwitch
              task={watch('task')}
              menu={menu}
              loggedInUser={loggedInUser}
              onChange={onVerifiedChanged}
              loading={isMarkingTaskAsVerified || isUnmarkingTaskAsVerified}
            />
          )}
        </PageTitle>

        <PageActions>
          <TaskActionSection
            task={watch('task')}
            permissions={MENU_SCRAPING_TASKS_PERMISSIONS}
            onAssignTask={onAssignTask}
            onUnassignTask={onUnassignTask}
            onSave={isAdmin || isTeamLeader ? onSave : undefined}
            isSaveDisabled={isSaveButtonDisabled}
            onDone={onDone}
            onDoneWithDebugModeScraping={handleSubmitWithScrapeInDebugMode}
            onMarkTaskAsAwaiting={onMarkTaskAsAwaiting}
            onUnmarkTaskAsAwaiting={onUnmarkTaskAsAwaiting}
            onReopenTask={onReopenTask}
            cancelUrl="/tasks/menu-scraping"
            canReopenTask={canReopenTask}
            menu={watch('menu')}
          />
        </PageActions>
      </PageHeader>

      <FormProvider {...formMethods}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Collapse
            defaultActiveKey={[
              'menu-section',
              'task-section',
              'scraping-service-section',
            ]}
          >
            <Collapse.Panel
              header="Menu"
              key="menu-section"
              style={has(errors, 'menu') ? { border: '1px solid red' } : {}}
            >
              <Row gutter={24}>
                <Col span={3}>
                  <FormInternalLink
                    transform={() => `/menus/${task.menu.id}`}
                    name="menu.id"
                    label="Menu ID"
                  />
                </Col>
                <Col span={4}>
                  <FormText name="menu.brand.name" label="Brand" />
                </Col>
                {!isEmpty(task.menu.locations) && (
                  <Col span={4}>
                    <LocationsList name="menu.locations" label="Location(s)" />
                  </Col>
                )}
                <Col span={5}>
                  <FormLink name="menu.url" label="Menu website" />
                </Col>
                <Col span={4}>
                  <FormText name="menu.status" label="Status" />
                </Col>
                <Col span={4}>
                  <FormDate name="menu.modifiedDate" label="Updated at" />
                </Col>
              </Row>
              <Row gutter={[24, 16]}>
                <Col span={3}>
                  <Select
                    style={{ width: '80%' }}
                    label="Format"
                    name="menu.format"
                    options={menuFormatOptions}
                    onSelectChange={onBrandMenuFormatChange}
                  />
                </Col>

                <VisibilityContainer
                  isHidden={watch('menu.format') !== HTML_NOT_SCRAPEABLE}
                >
                  <Col span={5}>
                    <Select
                      style={{ width: '100%' }}
                      name="menu.notScrapeableReason"
                      label="Not scrapeable reason"
                      options={notScrapableReasonOptions}
                    />
                  </Col>
                </VisibilityContainer>
              </Row>

              <Row gutter={[24, 16]}>
                <VisibilityContainer
                  isHidden={watch('menu.format') !== HTML_NOT_SCRAPEABLE}
                >
                  <Col span={8}>
                    <TextArea
                      label="Not scrapeable comment"
                      name="menu.notScrapeableComment"
                    />
                  </Col>
                </VisibilityContainer>
              </Row>

              <Row gutter={[24, 16]}>
                {isScrapeable && (
                  <>
                    <Col span={3}>
                      <Upload
                        disabled={isAttachedToCluster}
                        label="Scraping instructions"
                        name="menu.scrapingInstructions"
                        accept=".json"
                      />
                    </Col>

                    <Col span={4}>
                      <AntForm.Item label="Scraping responses">
                        <HtmlMenuScrapingHistoryDrawer
                          menuId={task.menu.id}
                          buttonName="View"
                          buttonSize="default"
                        />
                      </AntForm.Item>
                    </Col>

                    <VisibilityContainer
                      isHidden={!task.menu.isAwaitingScraping}
                    >
                      <Col span={5}>
                        <Form.Item label="Scraping status">
                          <Badge status="processing" text="In progress" />
                        </Form.Item>
                      </Col>
                    </VisibilityContainer>

                    <Col span={3}>
                      <AntForm.Item label="Scraping issue">
                        <Button
                          disabled={isAttachedToCluster}
                          onClick={() =>
                            setIsScrapingIssueCommentModalOpen(true)
                          }
                          style={{
                            color: scrapingIssueButtonColor,
                            borderColor: scrapingIssueButtonColor,
                          }}
                        >
                          <Icon type="form" />
                        </Button>
                      </AntForm.Item>
                    </Col>
                  </>
                )}
              </Row>
              <Row gutter={[24, 16]}>
                {isAttachedToCluster && (
                  <Col span={3}>
                    <FormInternalLink
                      name="menu.menuScrapingClusterId"
                      label="Scraping cluster ID"
                      transform={id =>
                        `/menu-scraping-templates/clusters/${id}`
                      }
                    />
                  </Col>
                )}
                <Col span={3}>
                  <FormInternalLink
                    name="menu.dishReviewTask.id"
                    label="Dish review task ID"
                    transform={id => `/tasks/dish-review/${id}`}
                  />
                </Col>
                <Col span={4}>
                  <FormDate
                    name="menu.scrapingRejectedDate"
                    label="Scraping rejected at"
                  />
                </Col>
                <Col span={4}>
                  <FormText
                    name="menu.scrapingRejectedBy.name"
                    label="Scraping rejected by"
                  />
                </Col>
                <VisibilityContainer
                  isHidden={task.menu.status !== AUTO_REJECTED}
                >
                  <Col span={14}>
                    <FormText
                      name="menu.scrapingRejectedReason"
                      label="Scraping rejected reason"
                    />
                  </Col>
                </VisibilityContainer>
                <VisibilityContainer
                  isHidden={task.menu.status === AUTO_REJECTED}
                >
                  <Col span={12}>
                    <FormText
                      name="menu.scrapingRejectedComment"
                      label="Scraping rejected comment"
                      ellipsis={false}
                      style={{ whiteSpace: 'break-spaces' }}
                    />
                  </Col>
                </VisibilityContainer>
              </Row>
            </Collapse.Panel>
            <Collapse.Panel header="Task" key="task-section">
              <Row gutter={16}>
                <Col span={3}>
                  <AwaitingReasonField
                    valueFieldName="task.awaitingReason"
                    titleFieldName="task.awaitingReasonComment"
                    label="Awaiting reason"
                  />
                </Col>
                <Col span={3}>
                  <FormDate label="Updated at" name="task.modifiedDate" />
                </Col>
                <VisibilityContainer isHidden={taskStatus !== REOPENED}>
                  <>
                    <Col span={3}>
                      <FormText
                        label="Reopened by"
                        name="task.reopenedBy.name"
                      />
                    </Col>
                    <Col span={3}>
                      <FormDate label="Reopened at" name="task.reopenedDate" />
                    </Col>
                    <Col span={4}>
                      <FormText
                        label="Reopen reason"
                        name="task.reopenReason"
                      />
                    </Col>
                  </>
                </VisibilityContainer>
                <Col span={2}>
                  <FormText
                    name="task.isRerunFlow"
                    label="Rerun flow"
                    style={{
                      textAlign: task.isRerunFlow ? 'center' : '',
                      backgroundColor: task.isRerunFlow
                        ? THEME.colors.orange
                        : '',
                    }}
                  />
                </Col>
                <Col span={3}>
                  <AntForm.Item label="Miscellaneous task">
                    <MiscTaskVerificationThreeStateSwitch
                      task={watch('task')}
                      onChange={onMiscTaskVerifiedChanged}
                      loading={
                        isMarkingMiscTaskAsVerified ||
                        isMarkingMiscTaskAsQCVerified
                      }
                    />
                  </AntForm.Item>
                </Col>
              </Row>
            </Collapse.Panel>

            {task.menu.scrapingSummary && (
              <Collapse.Panel
                header="Scraping service information"
                key="scraping-service-section"
              >
                <Row>
                  {task.menu.scrapingSummary.scrapingReport?.fileKey && (
                    <Col span={3}>
                      <AntForm.Item label="Scraping report">
                        <Button
                          margin="no small no no"
                          loading={loading}
                          onClick={() => getScrapingReportUrl()}
                        >
                          Download
                          <Icon type="download" />
                        </Button>
                      </AntForm.Item>
                    </Col>
                  )}
                  <Col span={3}>
                    <AntForm.Item label="Scraping details">
                      <Button
                        margin="no small no medium"
                        icon="eye"
                        disabled={!menu.isHtmlMenuScrapedInDebugMode}
                        onClick={() => handleOpenScrapingDebugModal(menu.id)}
                      />
                    </AntForm.Item>
                  </Col>
                  <Col span={4}>
                    <FormText
                      copyable={{
                        text: menuAnnotations,
                      }}
                      name="menu.scrapingSummary.menuAnnotations"
                      label="Menu annotations"
                    />
                  </Col>

                  {!isMenuScrapedSuccessfully(
                    task.menu.scrapingSummary.status,
                  ) && (
                    <Col span={3}>
                      <FormText
                        name="menu.scrapingSummary.status"
                        label="Error code"
                      />
                    </Col>
                  )}

                  <Col span={11}>
                    <AntForm.Item
                      label="Messages"
                      style={{ maxHeight: '230px', overflowY: 'scroll' }}
                    >
                      {isEmpty(task.menu.scrapingSummary.messages) ? (
                        EMPTY_VALUE
                      ) : (
                        <List
                          style={{ marginBottom: 15 }}
                          dataSource={task.menu.scrapingSummary.messages || []}
                          renderItem={message => (
                            <List.Item>{message.text}</List.Item>
                          )}
                        />
                      )}
                    </AntForm.Item>
                  </Col>
                </Row>
              </Collapse.Panel>
            )}
          </Collapse>

          {isScrapeable && !isAttachedToCluster && (
            <Panel>
              <Typography.Title level={4} style={{ margin: '5px' }}>
                Scraping instructions
              </Typography.Title>

              {newScrapingInstructions === task.menu.scrapingInstructions &&
              task.menu.scrapingInstructionsVersionNumber ? (
                <Typography.Text strong style={{ margin: '5px' }}>
                  {`(V.${task.menu.scrapingInstructionsVersionNumber})`}
                </Typography.Text>
              ) : (
                !isNil(newScrapingInstructions) && (
                  <Typography.Text strong style={{ margin: '5px' }}>
                    New version (not uploaded)
                  </Typography.Text>
                )
              )}

              {task.menu.scrapingInstructions && (
                <>
                  <Button
                    size="small"
                    onClick={() =>
                      fileDownload(
                        task.menu.scrapingInstructions,
                        `${task.menu.id}-menu.json`,
                      )
                    }
                  >
                    Download
                    <Icon type="download" />
                  </Button>
                  <Button
                    size="small"
                    style={{ marginLeft: '5px' }}
                    onClick={() =>
                      copyToClipboard(task.menu.scrapingInstructions)
                    }
                  >
                    Copy
                    <Icon type="copy" />
                  </Button>
                </>
              )}

              {task.menu.scrapingInstructionsVersionNumber > 1 && (
                <ScrapingInstructionsVersionsDrawer menu={task.menu} />
              )}

              {!isNil(task.menu.scrapingInstructions) &&
                newScrapingInstructions !== task.menu.scrapingInstructions && (
                  <Button
                    size="small"
                    style={{ margin: '5px' }}
                    onClick={() =>
                      setScrapingInstructionsDiffModalSettings({
                        isOpen: true,
                        currentScrapingInstructions: {
                          instructions: newScrapingInstructions,
                          version: 'New',
                        },
                        otherScrapingInstructions: {
                          instructions: task.menu.scrapingInstructions,
                          version: task.menu.scrapingInstructionsVersionNumber,
                        },
                      })
                    }
                  >
                    Compare with previous
                  </Button>
                )}
              {newScrapingInstructions ? (
                <ReactJson
                  src={JSON.parse(newScrapingInstructions)}
                  name={false}
                  enableClipboard={false}
                  displayDataTypes={false}
                />
              ) : (
                <Empty
                  image={Empty.PRESENTED_IMAGE_SIMPLE}
                  description="No scraping instructions set"
                />
              )}
            </Panel>
          )}

          <HiddenInputs
            names={[
              'task.id',
              'task.assignedTo.id',
              'task.assignedTo.name',
              'task.status',
              'task.awaitingReason',
              'task.awaitingReasonComment',
              'task.verifiedBy.id',
              'task.verifiedBy.name',
              'task.verifiedDate',
              'task.resolvedBy.id',
              'task.resolvedBy.name',
              'task.reopenedDate',
              'task.reopenedBy.id',
              'task.reopenedBy.name',
              'task.reopenReason',
              'task.miscTaskVerifiedBy.name',
              'task.miscTaskVerifiedDate',
              'task.miscTaskVerifiedComment',
              'task.miscTaskQCVerifiedBy.name',
              'task.miscTaskQCVerifiedDate',
              'task.miscTaskQCVerifiedComment',
            ]}
          />
          <HiddenCheckboxes
            names={[
              'task.isAssigned',
              'task.isAwaiting',
              'task.isDone',
              'task.isVerifiedInDebugMode',
              'menu.isHtmlMenuScrapedInDebugMode',
            ]}
          />
        </form>
      </FormProvider>

      <ScrapingInstructionsDiffModal
        isModalOpen={scrapingInstructionsDiffModalSettings.isOpen}
        scrapingInstructions={
          scrapingInstructionsDiffModalSettings.currentScrapingInstructions
        }
        otherScrapingInstructions={
          scrapingInstructionsDiffModalSettings.otherScrapingInstructions
        }
        onCancel={() =>
          setScrapingInstructionsDiffModalSettings({
            ...scrapingInstructionsDiffModalSettings,
            isOpen: false,
            otherScrapingInstructions: undefined,
          })
        }
      />

      <MenuScrapingIssueCommentModal
        menu={menu}
        isModalOpen={isScrapingIssueCommentModalOpen}
        setIsModalOpen={setIsScrapingIssueCommentModalOpen}
        onCommentChanged={onCommentChanged}
        disabled={!isTaskAssignedToLoggedInUser}
      />

      <HtmlMenuScrapingDebugModal
        menu={menu}
        itemHash={selectedItemHash}
        isModalOpen={isScrapingDebugModalOpen}
        closeModal={handleCloseScrapingDebugModal}
      />

      <MarkMiscTaskAsVerifiedModal
        isModalOpen={isMarkMiscTaskAsVerifiedModalOpen}
        isQCVerified={isQCVerified}
        onSubmit={handleMarkMiscTaskAsVerified}
        closeModal={() => setIsMarkMiscTaskAsVerifiedModalOpen(false)}
      />
    </>
  )
}

HtmlMenuScrapingTaskForm.propTypes = {
  task: PropTypes.object.isRequired,
  isSavingTask: PropTypes.bool.isRequired,
  isResolvingTask: PropTypes.bool.isRequired,
  onSubmit: PropTypes.func.isRequired,
}

export default HtmlMenuScrapingTaskForm
