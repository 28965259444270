import { isEmpty, isNaN } from 'lodash-es'
import * as Yup from 'yup'

import { menuIdsAsCsv } from '../../../../common/schemas'

import {
  APPROVED,
  AUTO_APPROVED,
  MAX_MENUS_PER_RERUN_TIME_SLOT,
  SCRAPING_RERUN_ERROR,
  SCRAPING_RERUN_SIGNIFICANT_CHANGES,
} from '../../../../menus/constants'

import { validateMenuIdsOrMenuStatusesAndScrapedBeforeDate } from './validators'

const startHtmlMenuScrapingSessionSchema = Yup.object()
  .shape({
    timeSlotCount: Yup.number()
      .nullable()
      .transform(value => (isNaN(value) ? null : value))
      .required(`Rerun time slot count is required`),
    menuCount: Yup.mixed().when(['menuIds', 'includeAllAvailableMenus'], {
      is: (menuIds, includeAllAvailableMenus) =>
        isEmpty(menuIds) && !includeAllAvailableMenus,
      then: Yup.number()
        .nullable()
        .transform(value => (isNaN(value) ? null : value))
        .required(`Rerun menu count is required`)
        .when('timeSlotCount', (timeSlotCount, schema) => {
          if (timeSlotCount) {
            return schema.max(
              timeSlotCount * MAX_MENUS_PER_RERUN_TIME_SLOT,
              `Max ${MAX_MENUS_PER_RERUN_TIME_SLOT} per time slot`,
            )
          }
          return schema
        }),
    }),
    includeAllAvailableMenus: Yup.boolean(),
    ignoreWorkingHours: Yup.boolean(),
    menuStatuses: Yup.array().of(
      Yup.string().oneOf([
        APPROVED,
        AUTO_APPROVED,
        SCRAPING_RERUN_ERROR,
        SCRAPING_RERUN_SIGNIFICANT_CHANGES,
      ]),
    ),
    scrapedBeforeDate: Yup.date().nullable(),
    menuIds: menuIdsAsCsv,
  })
  .test(
    'validateMenuIdsOrMenuStatusesAndScrapedBeforeDate',
    'menuIds or both  menuStatuses and scrapedBeforeDate are required',
    validateMenuIdsOrMenuStatusesAndScrapedBeforeDate,
  )

export default startHtmlMenuScrapingSessionSchema
