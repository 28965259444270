const rejectMenuScrapingFormInitialValues = (
  id,
  { scrapedById, assignedToId, scrapingRejectedComment },
) => ({
  id,
  scrapingRejectedComment,
  assignedToUserId: assignedToId || scrapedById,
})

export default rejectMenuScrapingFormInitialValues
