import { gql } from 'apollo-boost'

const HTML_MENU_SCRAPING_RESPONSE = gql`
  query htmlMenuScrapingResponse($htmlMenuScrapingJobId: ID!) {
    htmlMenuScrapingResponse(htmlMenuScrapingJobId: $htmlMenuScrapingJobId) {
      id
      menuId
      data
    }
  }
`

export default HTML_MENU_SCRAPING_RESPONSE
