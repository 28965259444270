import { mapKeys } from 'lodash-es'

export const CRAWLING_ERROR = 'CRAWLING_ERROR'
export const UNPROCESSABLE_CRAWLING_RESPONSE = 'UNPROCESSABLE_CRAWLING_RESPONSE'

export const MENU_CRAWLING_REJECTION_REASONS = mapKeys(
  [
    { name: 'Crawling error', value: CRAWLING_ERROR },
    {
      name: 'Unprocessable crawling response',
      value: UNPROCESSABLE_CRAWLING_RESPONSE,
    },
  ],
  'value',
)
