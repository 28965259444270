import React, { useEffect, useMemo } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers'
import { Col, Row } from 'antd'
import { filter, includes, values } from 'lodash-es'
import moment from 'moment-timezone'
import PropTypes from 'prop-types'

import {
  Checkbox,
  DatePicker,
  Input,
  NumberInput,
  Select,
} from '../../../core/components'
import { Button, Panel } from '../../../core/components/styled'

import { PageHeader, PageTitle } from '../../../common/components/styled'

import {
  APPROVED,
  AUTO_APPROVED,
  MENU_STATUSES,
  SCRAPING_RERUN_ERROR,
} from '../../../menus/constants'

import { startHtmlMenuScrapingSessionSchema } from './schemas'

const allowedMenuStatuses = [APPROVED, AUTO_APPROVED, SCRAPING_RERUN_ERROR]

const DURATION_FOR_BIG_SESSIONS_IN_DAYS = 5
const TIME_SLOTS_PER_DAY = 96

const StartHtmlMenuScrapingSessionForm = ({
  isStartingHtmlMenuScrapingSession,
  onSubmit,
}) => {
  const formMethods = useForm({
    defaultValues: {
      timeSlotCount: DURATION_FOR_BIG_SESSIONS_IN_DAYS * TIME_SLOTS_PER_DAY,
      menuCount: 10,
      ignoreWorkingHours: false,
      includeAllAvailableMenus: true,
      menuStatuses: allowedMenuStatuses,
      scrapedBeforeDate: moment()
        .subtract(1, 'weeks')
        .startOf('day'),
    },
    resolver: yupResolver(startHtmlMenuScrapingSessionSchema),
    mode: 'onChange',
  })

  const { handleSubmit, setValue, watch, trigger } = formMethods

  const menuIds = watch('menuIds')
  const includeAllAvailableMenus = watch('includeAllAvailableMenus')
  const menuStatuses = watch('menuStatuses')
  const scrapedBefore = watch('scrapedBeforeDate')

  // We need this for validateMenuIdsOrMenuStatusOrScrapedBefore validation to work properly
  useEffect(() => {
    trigger()
  }, [menuIds, menuStatuses, scrapedBefore, trigger])

  useEffect(() => {
    if (menuIds) {
      setValue('menuCount', undefined)
      setValue('menuStatuses', undefined)
      setValue('scrapedBeforeDate', undefined)
      setValue('includeAllAvailableMenus', false)
    } else {
      setValue('menuCount', 10)
      setValue('menuStatuses', allowedMenuStatuses)
    }
    trigger()
  }, [menuIds, setValue, trigger])

  useEffect(() => {
    if (includeAllAvailableMenus) {
      setValue('menuCount', undefined)
    } else {
      setValue('menuCount', 10)
    }
    trigger()
  }, [includeAllAvailableMenus, setValue, trigger])

  const menuStatusOptions = useMemo(
    () =>
      filter(values(MENU_STATUSES), ({ value }) =>
        includes(allowedMenuStatuses, value),
      ),
    [],
  )

  return (
    <FormProvider {...formMethods}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <PageHeader sticky>
          <PageTitle>Menu Scraping Rerun</PageTitle>
        </PageHeader>
        <Panel withBorderBottom>
          <Row type="flex" align="bottom" justify="space-between">
            <Col>
              <NumberInput
                label="Time slot count"
                placeholder="Time slot count"
                name="timeSlotCount"
                min={1}
                max={672}
                infoMessage="The slot duration is 15 min"
              />
            </Col>
            <Col>
              <NumberInput
                label="Menu count"
                placeholder="Menu count"
                name="menuCount"
                min={1}
                max={30000}
                infoMessage="Ensure that each time slot contains no more than 50 menus."
                disabled={!!menuIds || includeAllAvailableMenus}
              />
            </Col>
            <Col>
              <Checkbox
                label="Include all available menus"
                name="includeAllAvailableMenus"
                disabled={!!menuIds}
              />
            </Col>
            <Col>
              <Checkbox label="Ignore WH" name="ignoreWorkingHours" />
            </Col>
            <Col>
              <Panel style={{ width: '460px' }}>
                <Select
                  name="menuStatuses"
                  label="Menu status"
                  placeholder="Select menu status"
                  options={menuStatusOptions}
                  mode="multiple"
                  allowClear
                  disabled={!!menuIds}
                  maxTagCount={3}
                />
              </Panel>
            </Col>
            <Col>
              <DatePicker
                name="scrapedBeforeDate"
                label="Scraped before"
                placeholder="Scraped before date"
                disabled={!!menuIds}
              />
            </Col>
            <Col>
              <Input
                name="menuIds"
                label="Menu ids"
                placeholder="Comma separated menu ids"
              />
            </Col>
            <Col>
              <Button
                style={{ marginBottom: '24px' }}
                type="primary"
                htmlType="submit"
                loading={isStartingHtmlMenuScrapingSession}
              >
                Start scraping session
              </Button>
            </Col>
          </Row>
        </Panel>
      </form>
    </FormProvider>
  )
}

StartHtmlMenuScrapingSessionForm.propTypes = {
  isStartingHtmlMenuScrapingSession: PropTypes.bool.isRequired,
  onSubmit: PropTypes.func.isRequired,
}

export default StartHtmlMenuScrapingSessionForm
