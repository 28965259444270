import React from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers'
import { Row } from 'antd'
import PropTypes from 'prop-types'

import { HiddenInputs, TextArea } from '../../../core/components'
import { Button } from '../../../core/components/styled'

import { rejectMenuCrawlingSchema } from './schemas'

const RejectPdfMenuCrawlingForm = ({
  taskId,
  crawlingRejectedComment,
  closeModal,
  isRejectingMenu,
  onSubmit,
}) => {
  const formMethods = useForm({
    defaultValues: {
      id: taskId,
      crawlingRejectedComment,
    },
    resolver: yupResolver(rejectMenuCrawlingSchema),
  })

  return (
    <FormProvider {...formMethods}>
      <form onSubmit={formMethods.handleSubmit(onSubmit)}>
        <Row>
          <TextArea name="crawlingRejectedComment" label="Comment" />
        </Row>
        <Row type="flex" justify="end">
          <Button onClick={closeModal} margin="small small no no">
            Cancel
          </Button>
          <Button
            type="primary"
            htmlType="submit"
            loading={isRejectingMenu}
            margin="small no no no"
          >
            Save
          </Button>
        </Row>

        <HiddenInputs names={['id']} />
      </form>
    </FormProvider>
  )
}

RejectPdfMenuCrawlingForm.propTypes = {
  taskId: PropTypes.string,
  crawlingRejectedComment: PropTypes.string,
  closeModal: PropTypes.func.isRequired,
  isRejectingMenu: PropTypes.bool.isRequired,
  onSubmit: PropTypes.func.isRequired,
}

RejectPdfMenuCrawlingForm.defaultProps = {
  taskId: undefined,
  crawlingRejectedComment: undefined,
}

export default RejectPdfMenuCrawlingForm
