import React, { useCallback, useMemo } from 'react'
import { TreeSelect } from 'antd'
import { map, mapKeys } from 'lodash-es'
import PropTypes from 'prop-types'

import { SCRAPED_ITEM_SELECTION_SOURCES } from '../../../constants'
import { getScrapedItemsHashCodesToExpand } from '../../../helpers'
import { transformScrapedItemsToTree } from '../../../transformers'

const { TreeNode } = TreeSelect

const ScrapingDebugTreeSelect = ({
  menuId,
  scrapedItems,
  selectedScrapedItem,
  handleSelectedHashChange,
  getTreeSelectNodeTitle,
}) => {
  const treeData = useMemo(
    () => transformScrapedItemsToTree(scrapedItems, menuId),
    [scrapedItems, menuId],
  )

  const scrapedItemsById = useMemo(() => mapKeys(scrapedItems, 'id'), [
    scrapedItems,
  ])

  const handleTreeSelectionChange = useCallback(
    (value, node) => {
      handleSelectedHashChange(
        node.props.id,
        SCRAPED_ITEM_SELECTION_SOURCES.SCRAPING_DEBUG_TREE_SELECT,
      )
    },
    [handleSelectedHashChange],
  )

  const renderTreeNodes = useCallback(
    treeNodes =>
      map(treeNodes, node => (
        <TreeNode
          key={node.id}
          value={node.value}
          id={node.id}
          searchValue={node.title}
          title={getTreeSelectNodeTitle(node, scrapedItemsById[node.id])}
        >
          {renderTreeNodes(node.children)}
        </TreeNode>
      )),
    [scrapedItemsById, getTreeSelectNodeTitle],
  )

  const renderedTreeNodes = useMemo(() => renderTreeNodes(treeData), [
    treeData,
    renderTreeNodes,
  ])

  return (
    <TreeSelect
      showSearch
      style={{ width: '30%' }}
      value={selectedScrapedItem?.id}
      dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
      placeholder="Please select"
      allowClear
      treeDefaultExpandedKeys={getScrapedItemsHashCodesToExpand(scrapedItems)}
      onSelect={handleTreeSelectionChange}
      treeNodeFilterProp="searchValue"
    >
      {renderedTreeNodes}
    </TreeSelect>
  )
}

ScrapingDebugTreeSelect.propTypes = {
  menuId: PropTypes.string.isRequired,
  scrapedItems: PropTypes.array,
  selectedScrapedItem: PropTypes.object,
  handleSelectedHashChange: PropTypes.func.isRequired,
  getTreeSelectNodeTitle: PropTypes.func.isRequired,
}

ScrapingDebugTreeSelect.defaultProps = {
  scrapedItems: undefined,
  selectedScrapedItem: undefined,
}

export default ScrapingDebugTreeSelect
