import { gql } from 'apollo-boost'

const RESOLVE_MENU_SCRAPING_TASK = gql`
  mutation resolveMenuScrapingTask(
    $id: ID!
    $menuData: SaveMenuScrapingTaskMenuInput!
    $menuPdfFilesData: [MenuScrapingTaskMenuPdfFileInput!]
    $isDebugModeScraping: Boolean!
  ) {
    resolveMenuScrapingTask(
      id: $id
      menuData: $menuData
      menuPdfFilesData: $menuPdfFilesData
      isDebugModeScraping: $isDebugModeScraping
    ) {
      id
    }
  }
`

export default RESOLVE_MENU_SCRAPING_TASK
