import React, { useCallback, useMemo } from 'react'
import { useHotkeys } from 'react-hotkeys-hook'
import { Pagination } from 'antd'
import PropTypes from 'prop-types'

import { THEME } from '../../../../../core/constants'

import { SCRAPING_DEBUG_MODAL_HOTKEYS } from '../../../../constants'
import {
  getFirstScreenshotIndexForAllScrapedItems,
  getPrevAndNextItemByScrapedItemHash,
} from '../../../../helpers/html-menus'

const ScrapingDebugGalleryPagination = ({
  currentPageNo,
  currentScreenshot,
  numberOfScreenshots,
  firstScreenshotIndexByScrapedItemHash,
  handlePageChange,
}) => {
  const prevAndNextItemByScrapedItemHash = useMemo(
    () =>
      getPrevAndNextItemByScrapedItemHash(
        firstScreenshotIndexByScrapedItemHash,
      ),
    [firstScreenshotIndexByScrapedItemHash],
  )

  const firstScreenshotPageForAllScrapedItems = useMemo(
    () =>
      getFirstScreenshotIndexForAllScrapedItems(
        firstScreenshotIndexByScrapedItemHash,
      ),
    [firstScreenshotIndexByScrapedItemHash],
  )

  const renderButton = useCallback(
    (page, type, originalElement) => {
      if (type !== 'page') {
        return originalElement
      }

      const backgroundColor = firstScreenshotPageForAllScrapedItems.has(
        page - 1,
      )
        ? THEME.colors.lightBlue
        : THEME.colors.white

      if (currentPageNo === page) {
        return (
          <div
            style={{
              fontWeight: 'bold',
              color: THEME.colors.black,
              backgroundColor,
              borderRadius: '4px',
            }}
          >
            {page}
          </div>
        )
      }

      return (
        <div
          style={{
            color: THEME.colors.darkGrey,
            backgroundColor,
            borderRadius: '4px',
          }}
        >
          {page}
        </div>
      )
    },
    [currentPageNo, firstScreenshotPageForAllScrapedItems],
  )

  useHotkeys(
    SCRAPING_DEBUG_MODAL_HOTKEYS.LEFT.hotkey,
    event => {
      event.preventDefault()

      if (currentPageNo === 1) {
        return
      }

      const prevPage = currentPageNo - 1
      handlePageChange(prevPage)
    },
    [currentPageNo, handlePageChange],
  )

  useHotkeys(
    SCRAPING_DEBUG_MODAL_HOTKEYS.RIGHT.hotkey,
    event => {
      event.preventDefault()

      if (currentPageNo === numberOfScreenshots) {
        return
      }

      const nextPage = currentPageNo + 1
      handlePageChange(nextPage)
    },
    [currentPageNo, handlePageChange],
  )

  useHotkeys(
    SCRAPING_DEBUG_MODAL_HOTKEYS.UP.hotkey,
    event => {
      event.preventDefault()

      const prevScrapedItemHash = prevAndNextItemByScrapedItemHash.get(
        currentScreenshot.scrapedItemHash,
      ).prev

      if (!prevScrapedItemHash) {
        return
      }

      const scrapedItemFirstScreenshotIndex = firstScreenshotIndexByScrapedItemHash.get(
        prevScrapedItemHash,
      )

      handlePageChange(scrapedItemFirstScreenshotIndex + 1)
    },
    [currentPageNo, currentScreenshot, handlePageChange],
  )

  useHotkeys(
    SCRAPING_DEBUG_MODAL_HOTKEYS.DOWN.hotkey,
    event => {
      event.preventDefault()

      const nextScrapedItemHash = prevAndNextItemByScrapedItemHash.get(
        currentScreenshot.scrapedItemHash,
      ).next

      if (!nextScrapedItemHash) {
        return
      }

      const scrapedItemFirstScreenshotIndex = firstScreenshotIndexByScrapedItemHash.get(
        nextScrapedItemHash,
      )

      handlePageChange(scrapedItemFirstScreenshotIndex + 1)
    },
    [currentPageNo, currentScreenshot, handlePageChange],
  )

  return (
    <Pagination
      defaultCurrent={1}
      defaultPageSize={1}
      current={currentPageNo}
      total={numberOfScreenshots}
      onChange={handlePageChange}
      itemRender={renderButton}
    />
  )
}

ScrapingDebugGalleryPagination.propTypes = {
  currentPageNo: PropTypes.number.isRequired,
  currentScreenshot: PropTypes.object,
  numberOfScreenshots: PropTypes.number.isRequired,
  firstScreenshotIndexByScrapedItemHash: PropTypes.object,
  handlePageChange: PropTypes.func.isRequired,
}

ScrapingDebugGalleryPagination.defaultProps = {
  currentScreenshot: undefined,
  firstScreenshotIndexByScrapedItemHash: new Map(),
}

export default ScrapingDebugGalleryPagination
