import { gql } from 'apollo-boost'

const RESOLVE_DISH_REVIEW_TASK_BY_REJECT_PDF_MENU = gql`
  mutation resolveDishReviewTaskByRejectPdfMenu(
    $id: ID!
    $scrapingRejectedComment: String!
  ) {
    resolveDishReviewTaskByRejectPdfMenu(
      id: $id
      scrapingRejectedComment: $scrapingRejectedComment
    ) {
      id
    }
  }
`

export default RESOLVE_DISH_REVIEW_TASK_BY_REJECT_PDF_MENU
