import { map, pick } from 'lodash-es'

const { PDF } = require('../../../menus/constants')

const transformMenu = menu =>
  pick(
    menu,
    'id',
    'format',
    'scrapingInstructions',
    'notScrapeableReason',
    'notScrapeableComment',
  )

const transformPdfFiles = pdfFiles =>
  map(pdfFiles, pdfFile => pick(pdfFile, 'id', 'isSelected', 'menuCategory'))

const transformToMenuScrapingTaskInput = values => {
  const { task, isResolveWithDebugModeScraping = false } = values

  const menuData = transformMenu(values.menu)
  const menuPdfFilesData =
    menuData.format === PDF ? transformPdfFiles(values.menuPdfFiles) : []

  return {
    id: task.id,
    menuData,
    menuPdfFilesData,
    isDebugModeScraping: isResolveWithDebugModeScraping,
  }
}

export default transformToMenuScrapingTaskInput
