import React from 'react'
import { Row, Typography } from 'antd'
import { get, map } from 'lodash-es'
import PropTypes from 'prop-types'

import { THEME } from '../../../../../core/constants'

import { getAnalysisSectionTitle } from '../../../../helpers'
import { PanelSection } from '../../../styled'

const styles = {
  Error: {
    backgroundColor: THEME.colors.red,
    color: THEME.colors.white,
    padding: '0 2px 0 2px',
  },
  Warning: {
    backgroundColor: THEME.colors.yellow,
    color: THEME.colors.black,
    padding: '0 2px 0 2px',
  },
  Information: {
    color: THEME.colors.black,
    padding: '0 2px 0 2px',
  },
}

const ScrapingAnalysisSection = ({ type, messages }) => (
  <PanelSection>
    <Typography.Text strong style={{ lineHeight: '32px' }}>
      {getAnalysisSectionTitle(type)}
    </Typography.Text>
    {map(messages, (item, index) => (
      <Row key={index}>
        <Typography.Text style={get(styles, item.severity)}>
          {item.text}
        </Typography.Text>
      </Row>
    ))}
  </PanelSection>
)

ScrapingAnalysisSection.propTypes = {
  type: PropTypes.string.isRequired,
  messages: PropTypes.array.isRequired,
}

export default ScrapingAnalysisSection
