import React, { useMemo } from 'react'
import { Table } from 'antd'
import { map } from 'lodash-es'
import PropTypes from 'prop-types'

import {
  HiddenInputs,
  Input,
  PopoverReactJson,
} from '../../../../core/components'
import { Button } from '../../../../core/components/styled'

import { LondonDateTime } from '../../../../common/components'
import { yesNoFilter } from '../../../../common/helpers'

import {
  getTableRowStyleForMenuItem,
  isHtmlMenuScrapedInDebugMode,
  onYesNoFilter,
} from '../../../helpers'

const DishesTable = ({
  menu,
  dishes,
  selectedDescriptionFilter,
  selectedCaloriesFilter,
  selectedDishDietsFilter,
  selectedDishAddonsFilter,
  selectedDishMiscInfosFilter,
  selectedDishAllergensFilter,
  selectedDishIngredientsFilter,
  selectedDishNutritionsFilter,
  menuTitlesForFilter,
  selectedMenuTitleIds,
  showLastFullMenu,
  page,
  pageSize,
  onTableChange,
  search,
  onSearch,
  onShowScrapingDetailsButtonClick,
}) => {
  const isScrapingDetailsDataAvailable = useMemo(() => {
    return (
      menu.format === 'PDF' ||
      (isHtmlMenuScrapedInDebugMode(menu) && showLastFullMenu)
    )
  }, [menu, showLastFullMenu])

  const tableColumns = useMemo(
    () => [
      {
        title: () => (
          <Input
            name="name"
            label="Dish"
            placeholder="Search keyword"
            value={search}
            onChange={onSearch}
          />
        ),
        dataIndex: 'name',
        fixed: 'left',
        width: '230px',
      },
      {
        title: 'Price',
        dataIndex: 'price',
        width: '70px',
      },
      {
        title: 'Menu Title',
        filters: menuTitlesForFilter,
        filteredValue: selectedMenuTitleIds || null,
        onFilter: (menuTitleId, dish) => menuTitleId === dish.menuTitleId,
        dataIndex: 'menuTitlePath',
        width: '250px',
      },
      {
        title: 'Description',
        filters: yesNoFilter,
        filteredValue: selectedDescriptionFilter || null,
        onFilter: onYesNoFilter('description'),
        dataIndex: 'description',
        width: '400px',
      },
      {
        title: 'Scraping Details',
        width: '80px',
        align: 'center',
        dataIndex: 'hash',
        render: (hash, dish) => (
          <Button
            size="small"
            icon="eye"
            disabled={dish.isDeleted || !isScrapingDetailsDataAvailable}
            onClick={() => onShowScrapingDetailsButtonClick(hash)}
          />
        ),
      },
      {
        title: 'Calories',
        filters: yesNoFilter,
        filteredValue: selectedCaloriesFilter || null,
        onFilter: onYesNoFilter('calories'),
        dataIndex: 'calories',
      },
      {
        title: 'Dish Diet',
        filters: yesNoFilter,
        filteredValue: selectedDishDietsFilter || null,
        onFilter: onYesNoFilter('diets'),
        dataIndex: 'diets',
        render: diets =>
          map(diets, (diet, index) => (
            <PopoverReactJson key={index} source={diet} />
          )),
      },
      {
        title: 'Choice Information',
        filters: yesNoFilter,
        filteredValue: selectedDishAddonsFilter || null,
        onFilter: onYesNoFilter('addons'),
        dataIndex: 'addons',
        render: addons =>
          map(addons, (addon, index) => (
            <PopoverReactJson key={index} source={addon} />
          )),
      },
      {
        title: 'Additional Information',
        filters: yesNoFilter,
        filteredValue: selectedDishMiscInfosFilter || null,
        onFilter: onYesNoFilter('miscInfo'),
        dataIndex: 'miscInfo',
        render: miscInfos =>
          map(miscInfos, (miscInfo, index) => (
            <PopoverReactJson key={index} source={miscInfo} />
          )),
      },
      {
        title: 'Allergens Information',
        filters: yesNoFilter,
        filteredValue: selectedDishAllergensFilter || null,
        onFilter: onYesNoFilter('allergens'),
        dataIndex: 'allergens',
        render: allergens =>
          map(allergens, (allergen, index) => (
            <PopoverReactJson key={index} source={allergen} />
          )),
      },
      {
        title: 'Ingredients',
        filters: yesNoFilter,
        filteredValue: selectedDishIngredientsFilter || null,
        onFilter: onYesNoFilter('ingredients'),
        dataIndex: 'ingredients',
      },
      {
        title: 'Nutritions Information',
        filters: yesNoFilter,
        filteredValue: selectedDishNutritionsFilter || null,
        onFilter: onYesNoFilter('nutritions'),
        dataIndex: 'nutritions',
        render: nutritions =>
          map(nutritions, (nutrition, index) => (
            <PopoverReactJson key={index} source={nutrition} />
          )),
      },
      {
        title: 'Added At',
        dataIndex: 'createdDate',
        width: '140px',
        render: date => <LondonDateTime date={date} />,
      },
      {
        title: 'Updated At',
        dataIndex: 'modifiedDate',
        width: '140px',
        render: date => <LondonDateTime date={date} />,
      },
      {
        width: '0px',
        render: (row, dish, index) => (
          <HiddenInputs names={[`dishes.${index}.id`]} />
        ),
      },
    ],
    [
      menuTitlesForFilter,
      selectedMenuTitleIds,
      selectedDescriptionFilter,
      selectedCaloriesFilter,
      selectedDishDietsFilter,
      selectedDishAddonsFilter,
      selectedDishMiscInfosFilter,
      selectedDishAllergensFilter,
      selectedDishIngredientsFilter,
      selectedDishNutritionsFilter,
      search,
      onSearch,
      isScrapingDetailsDataAvailable,
      onShowScrapingDetailsButtonClick,
    ],
  )

  const paginationConfig = useMemo(
    () => ({
      showSizeChanger: true,
      current: page,
      pageSize,
    }),
    [page, pageSize],
  )

  const scrollConfig = useMemo(
    () => ({
      x: 3000,
    }),
    [],
  )

  return (
    <Table
      rowKey="id"
      scroll={scrollConfig}
      columns={tableColumns}
      dataSource={dishes}
      rowClassName={getTableRowStyleForMenuItem}
      pagination={paginationConfig}
      onChange={onTableChange}
    />
  )
}

DishesTable.propTypes = {
  menu: PropTypes.object.isRequired,
  dishes: PropTypes.array.isRequired,
  search: PropTypes.string.isRequired,
  onSearch: PropTypes.func.isRequired,
  selectedDescriptionFilter: PropTypes.bool,
  selectedCaloriesFilter: PropTypes.bool,
  selectedDishDietsFilter: PropTypes.bool,
  selectedDishAddonsFilter: PropTypes.bool,
  selectedDishMiscInfosFilter: PropTypes.bool,
  selectedDishAllergensFilter: PropTypes.bool,
  selectedDishIngredientsFilter: PropTypes.bool,
  selectedDishNutritionsFilter: PropTypes.bool,
  menuTitlesForFilter: PropTypes.array.isRequired,
  selectedMenuTitleIds: PropTypes.array.isRequired,
  showLastFullMenu: PropTypes.bool.isRequired,
  page: PropTypes.number.isRequired,
  pageSize: PropTypes.number.isRequired,
  onTableChange: PropTypes.func,
  onShowScrapingDetailsButtonClick: PropTypes.func,
}

DishesTable.defaultProps = {
  onTableChange: undefined,
  onShowScrapingDetailsButtonClick: undefined,
  selectedDescriptionFilter: null,
  selectedCaloriesFilter: null,
  selectedDishDietsFilter: null,
  selectedDishAddonsFilter: null,
  selectedDishMiscInfosFilter: null,
  selectedDishAllergensFilter: null,
  selectedDishIngredientsFilter: null,
  selectedDishNutritionsFilter: null,
}

export default DishesTable
