import React, { useCallback } from 'react'
import { useHistory } from 'react-router-dom'
import { useMutation } from '@apollo/react-hooks'
import { Modal } from 'antd'
import PropTypes from 'prop-types'

import {
  showErrorNotification,
  showSuccessNotification,
} from '../../../../common/helpers'
import { getCachedFilterForPage } from '../../../../common/services'

import { RESOLVE_MENU_SCRAPING_TASK_BY_REJECT_PDF_MENU } from '../../../graphql/mutations'
import { RejectPdfMenuCrawlingForm } from '../../forms'

const onSubmit = (variables, setCrawlingRejectedComment) =>
  setCrawlingRejectedComment({ variables })

const RejectPdfMenuCrawlingModal = ({
  taskId,
  menu,
  isModalOpen,
  setIsModalOpen,
}) => {
  const history = useHistory()

  const [
    resolveMenuScrapingTaskByRejectPdfMenu,
    { loading: isRejectingMenu },
  ] = useMutation(RESOLVE_MENU_SCRAPING_TASK_BY_REJECT_PDF_MENU, {
    onCompleted() {
      showSuccessNotification({
        message: 'Reject menu',
        description:
          'Menu crawling rejection comment has been successfully set and menu scraping task was resolved by rejecting menu',
      })
      setIsModalOpen(false)
      history.push(getCachedFilterForPage('/tasks/menu-scraping'))
    },

    onError({ message }) {
      showErrorNotification({
        message: 'Menu crawling rejection comment.',
        description: message,
      })
    },
  })

  const handleSubmit = useCallback(
    menu => onSubmit(menu, resolveMenuScrapingTaskByRejectPdfMenu),
    [resolveMenuScrapingTaskByRejectPdfMenu],
  )

  return (
    <Modal
      title="Reject crawling"
      visible={isModalOpen}
      onCancel={() => setIsModalOpen(false)}
      footer={null}
    >
      <RejectPdfMenuCrawlingForm
        taskId={taskId}
        crawlingRejectedComment={menu.crawlingRejectedComment}
        closeModal={() => setIsModalOpen(false)}
        isRejectingMenu={isRejectingMenu}
        onSubmit={handleSubmit}
      />
    </Modal>
  )
}

RejectPdfMenuCrawlingModal.propTypes = {
  taskId: PropTypes.string.isRequired,
  menu: PropTypes.object.isRequired,
  isModalOpen: PropTypes.bool.isRequired,
  setIsModalOpen: PropTypes.func.isRequired,
}

export default RejectPdfMenuCrawlingModal
