import React from 'react'
import { Row, Typography } from 'antd'
import PropTypes from 'prop-types'

import { THEME } from '../../../../../core/constants'

const styles = {
  Error: {
    backgroundColor: THEME.colors.red,
    color: THEME.colors.white,
    padding: '0 2px 0 2px',
  },
  Warning: {
    backgroundColor: THEME.colors.yellow,
    color: THEME.colors.black,
    padding: '0 2px 0 2px',
  },
  Information: {
    backgroundColor: THEME.colors.lightBlue,
    color: THEME.colors.black,
    padding: '0 2px 0 2px',
  },
  Default: {},
}

const ScrapingAnalysis = ({ scrapingJob }) => {
  return (
    <>
      <Typography.Text strong style={{ lineHeight: '32px' }}>
        Summary
      </Typography.Text>
      <Row>
        <Typography.Text>
          {scrapingJob.scrapedMenuTitleCount || 0} menu titles scraped.
        </Typography.Text>
      </Row>
      <Row>
        <Typography.Text>
          {scrapingJob.scrapedDishCount || 0} dishes scraped.
        </Typography.Text>
      </Row>
      <Row>
        <Typography.Text>
          {scrapingJob.highDiscrepancyCount +
            scrapingJob.mediumDiscrepancyCount +
            scrapingJob.lowDiscrepancyCount}{' '}
          discrepancies identified.
        </Typography.Text>
      </Row>
      <Row>
        <Typography.Text
          style={
            scrapingJob.highDiscrepancyCount ? styles.Error : styles.Default
          }
        >
          {scrapingJob.highDiscrepancyCount || 0} high-impact discrepancies
          identified as hallucinations.
        </Typography.Text>
      </Row>
      <Row>
        <Typography.Text
          style={
            scrapingJob.mediumDiscrepancyCount || 0
              ? styles.Warning
              : styles.Default
          }
        >
          {scrapingJob.mediumDiscrepancyCount || 0} medium-impact discrepancies
          identified as hallucinations.
        </Typography.Text>
      </Row>
      <Row>
        <Typography.Text
          style={
            scrapingJob.lowDiscrepancyCount
              ? styles.Information
              : styles.Default
          }
        >
          {scrapingJob.lowDiscrepancyCount || 0} other discrepancies.
        </Typography.Text>
      </Row>
    </>
  )
}

ScrapingAnalysis.propTypes = {
  scrapingJob: PropTypes.object,
}

ScrapingAnalysis.defaultProps = {
  scrapingJob: undefined,
}

export default ScrapingAnalysis
