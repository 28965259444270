import React from 'react'
import { Route, Switch } from 'react-router'

import HtmlMenuScrapingSessionsPage from './pages/html-menu-scraping-sessions-page'

const HtmlMenuScrapingSessionsRouter = () => (
  <Switch>
    <Route
      path="/menu-scraping-reruns"
      component={HtmlMenuScrapingSessionsPage}
    />
  </Switch>
)

export default HtmlMenuScrapingSessionsRouter
