import React, { useCallback } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { Form, Select } from 'antd'
import { map, size } from 'lodash-es'
import PropTypes from 'prop-types'

import {
  createUrl,
  getQueryParams,
} from '../../core/utils/services/queryParams'

import { DEFAULT_PAGE, HTML_TEMPLATE, MENU_SCRAPING_TYPES } from '../constants'

const MenuScrapingTypeFilter = ({ mode }) => {
  const history = useHistory()
  const { pathname, search } = useLocation()

  const { menuScrapingTypes } = getQueryParams(search)

  const handleChange = useCallback(
    menuScrapingTypes =>
      history.push(
        createUrl(pathname, search, {
          menuScrapingTypes,
          page: DEFAULT_PAGE,
        }),
      ),
    [history, pathname, search],
  )

  const getMaxTagPlaceholder = useCallback(
    omittedValues => `+ ${size(omittedValues)} more`,
    [],
  )

  return (
    <Form.Item label="Scraping type">
      <Select
        style={{ width: '100%' }}
        allowClear
        value={menuScrapingTypes}
        mode={mode}
        onChange={handleChange}
        dropdownStyle={{ minWidth: 'fit-content' }}
        maxTagCount={1}
        maxTagPlaceholder={getMaxTagPlaceholder}
      >
        {map(
          MENU_SCRAPING_TYPES,
          ({ name, value }) =>
            value !== HTML_TEMPLATE && (
              <Select.Option key={value} value={value}>
                {name}
              </Select.Option>
            ),
        )}
      </Select>
    </Form.Item>
  )
}

MenuScrapingTypeFilter.propTypes = {
  mode: PropTypes.string,
}

MenuScrapingTypeFilter.defaultProps = {
  mode: 'default',
}

export default MenuScrapingTypeFilter
