import React, { useCallback } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { Form, Select } from 'antd'
import { map, size } from 'lodash-es'
import PropTypes from 'prop-types'

import {
  createUrl,
  getQueryParams,
} from '../../core/utils/services/queryParams'

import { DEFAULT_PAGE, SCRAPING_STATUSES } from '../constants'

const ScrapingStatusFilter = ({ mode, disabled }) => {
  const history = useHistory()
  const { pathname, search } = useLocation()

  const { scrapingStatuses } = getQueryParams(search)

  const handleChange = useCallback(
    scrapingStatuses =>
      history.push(
        createUrl(pathname, search, {
          scrapingStatuses,
          page: DEFAULT_PAGE,
        }),
      ),
    [history, pathname, search],
  )

  const getMaxTagPlaceholder = useCallback(
    omittedValues => `+ ${size(omittedValues)} more`,
    [],
  )

  return (
    <Form.Item label="Scraping status">
      <Select
        style={{ width: '100%' }}
        allowClear
        value={scrapingStatuses}
        mode={mode}
        onChange={handleChange}
        dropdownStyle={{ minWidth: 'fit-content' }}
        maxTagCount={1}
        maxTagPlaceholder={getMaxTagPlaceholder}
        disabled={disabled}
      >
        {map(SCRAPING_STATUSES, ({ name, value }) => (
          <Select.Option key={value} value={value}>
            {name}
          </Select.Option>
        ))}
      </Select>
    </Form.Item>
  )
}

ScrapingStatusFilter.propTypes = {
  mode: PropTypes.string,
  disabled: PropTypes.bool,
}

ScrapingStatusFilter.defaultProps = {
  mode: 'default',
  disabled: false,
}

export default ScrapingStatusFilter
