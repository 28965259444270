import React, { useCallback } from 'react'
import { useHotkeys } from 'react-hotkeys-hook'
import { Pagination } from 'antd'
import PropTypes from 'prop-types'

import { THEME } from '../../../../../core/constants'

import { SCRAPING_DEBUG_MODAL_HOTKEYS } from '../../../../constants'

const ScrapingDebugGalleryPagination = ({
  currentPageNo,
  numberOfScreenshots,
  handlePageChange,
}) => {
  const renderButton = useCallback(
    (page, type, originalElement) => {
      if (type !== 'page') {
        return originalElement
      }

      if (currentPageNo === page) {
        return (
          <div
            style={{
              fontWeight: 'bold',
              color: THEME.colors.black,
              backgroundColor: THEME.colors.lightBlue,
              borderRadius: '4px',
            }}
          >
            {page}
          </div>
        )
      }

      return (
        <div
          style={{
            color: THEME.colors.darkGrey,
            backgroundColor: THEME.colors.white,
            borderRadius: '4px',
          }}
        >
          {page}
        </div>
      )
    },
    [currentPageNo],
  )

  useHotkeys(
    SCRAPING_DEBUG_MODAL_HOTKEYS.LEFT.hotkey,
    event => {
      event.preventDefault()

      if (currentPageNo === 1) {
        return
      }

      const prevPage = currentPageNo - 1
      handlePageChange(prevPage)
    },
    [currentPageNo, handlePageChange],
  )

  useHotkeys(
    SCRAPING_DEBUG_MODAL_HOTKEYS.RIGHT.hotkey,
    event => {
      event.preventDefault()

      if (currentPageNo === numberOfScreenshots) {
        return
      }

      const nextPage = currentPageNo + 1
      handlePageChange(nextPage)
    },
    [currentPageNo, handlePageChange],
  )

  return (
    <Pagination
      defaultCurrent={1}
      defaultPageSize={1}
      current={currentPageNo}
      total={numberOfScreenshots}
      onChange={handlePageChange}
      itemRender={renderButton}
    />
  )
}

ScrapingDebugGalleryPagination.propTypes = {
  currentPageNo: PropTypes.number.isRequired,
  numberOfScreenshots: PropTypes.number.isRequired,
  handlePageChange: PropTypes.func.isRequired,
}

export default ScrapingDebugGalleryPagination
