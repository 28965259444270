import React, { useCallback, useMemo, useState } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { Row } from 'antd'
import arrayToTree from 'array-to-tree'
import { filter, forEach, get, includes, lowerCase, map } from 'lodash-es'
import PropTypes from 'prop-types'

import { createUrl } from '../../../../../core/utils/services/queryParams'

import { SCRAPED_ITEM_PLACEHOLDER_TITLES } from '../../../../constants'
import DishesTable from '../dishes-table'
import MenuTitlesTable from '../menu-titles-table'

const PdfMenuTitlesAndDishesTables = ({
  menu,
  menuTitles = [],
  dishes = [],
  isExpanded,
  page,
  pageSize,
  onShowScrapingDetailsButtonClick,
}) => {
  const history = useHistory()
  const { search, pathname } = useLocation()

  const [selectedMenuTitleIds, setSelectedMenuTitleIds] = useState([])
  const [searchByDishName, setSearchByDishName] = useState('')
  const [selectedDescriptionFilter, setSelectedDescriptionFilter] = useState()
  const [selectedCaloriesFilter, setSelectedCaloriesFilter] = useState()
  const [selectedDishDietsFilter, setSelectedDishDietsFilter] = useState()
  const [selectedDishAddonsFilter, setSelectedDishAddonsFilter] = useState()

  const [
    selectedDishMiscInfosFilter,
    setSelectedDishMiscInfosFilter,
  ] = useState()

  const [
    selectedDishAllergensFilter,
    setSelectedDishAllergensFilter,
  ] = useState()

  const [
    selectedDishIngredientsFilter,
    setSelectedDishIngredientsFilter,
  ] = useState()

  const [
    selectedDishNutritionsFilter,
    setSelectedDishNutritionsFilter,
  ] = useState()

  const menuTitlesForFilter = useMemo(
    () =>
      arrayToTree(
        map(menu.menuTitles, ({ id, title, parentId }) => ({
          text: title || SCRAPED_ITEM_PLACEHOLDER_TITLES.EMPTY_MENU_TITLE,
          value: id,
          id,
          parentId,
        })),
        {
          parentProperty: 'parentId',
        },
      ),
    [menu.menuTitles],
  )

  const handleMenuTitleRowClick = useCallback(
    menuTitle => {
      const collectIds = item => {
        let ids = [get(item, 'id')]
        forEach(get(item, 'children'), child => {
          ids = ids.concat(collectIds(child))
        })
        return ids
      }

      const newSelectedMenuTitleIds = collectIds(menuTitle)
      setSelectedMenuTitleIds(newSelectedMenuTitleIds)
    },
    [setSelectedMenuTitleIds],
  )

  const handleDishTableChange = useCallback(
    (pagination, filters) => {
      const { current, pageSize } = pagination
      history.push(createUrl(pathname, search, { page: current, pageSize }))

      setSelectedMenuTitleIds(filters.menuTitlePath)
      setSelectedDescriptionFilter(filters.description)
      setSelectedCaloriesFilter(filters.calories)
      setSelectedDishDietsFilter(filters.diets)
      setSelectedDishAddonsFilter(filters.addons)
      setSelectedDishMiscInfosFilter(filters.miscInfo)
      setSelectedDishAllergensFilter(filters.allergens)
      setSelectedDishIngredientsFilter(filters.ingredients)
      setSelectedDishNutritionsFilter(filters.nutritions)
    },

    [history, pathname, search],
  )

  const onSearchByDishName = useCallback(
    ({ target: { value } }) => setSearchByDishName(value),
    [setSearchByDishName],
  )

  const filteredDishes = useMemo(() => {
    const lowercaseSearch = lowerCase(searchByDishName)
    return filter(dishes, dish =>
      includes(lowerCase(dish.name), lowercaseSearch),
    )
  }, [dishes, searchByDishName])

  return (
    <div style={{ marginTop: '20px' }}>
      <Row>
        <MenuTitlesTable
          menu={menu}
          menuTitlesToShow={menuTitles}
          lastScrapedMenuTitles={menuTitles}
          isExpanded={isExpanded}
          showLastFullMenu
          onRowClick={handleMenuTitleRowClick}
          onShowScrapingDetailsButtonClick={onShowScrapingDetailsButtonClick}
        />

        <DishesTable
          menu={menu}
          dishes={filteredDishes}
          selectedCaloriesFilter={selectedCaloriesFilter}
          selectedDishDietsFilter={selectedDishDietsFilter}
          selectedDishAddonsFilter={selectedDishAddonsFilter}
          selectedDishMiscInfosFilter={selectedDishMiscInfosFilter}
          selectedDishAllergensFilter={selectedDishAllergensFilter}
          selectedDishIngredientsFilter={selectedDishIngredientsFilter}
          selectedDishNutritionsFilter={selectedDishNutritionsFilter}
          selectedDescriptionFilter={selectedDescriptionFilter}
          search={searchByDishName}
          onSearch={onSearchByDishName}
          menuTitlesForFilter={menuTitlesForFilter}
          selectedMenuTitleIds={selectedMenuTitleIds}
          showLastFullMenu
          page={page}
          pageSize={pageSize}
          onTableChange={handleDishTableChange}
          onShowScrapingDetailsButtonClick={onShowScrapingDetailsButtonClick}
        />
      </Row>
    </div>
  )
}

PdfMenuTitlesAndDishesTables.propTypes = {
  menu: PropTypes.object.isRequired,
  menuTitles: PropTypes.array.isRequired,
  dishes: PropTypes.array.isRequired,
  isExpanded: PropTypes.bool.isRequired,
  page: PropTypes.number.isRequired,
  pageSize: PropTypes.number.isRequired,
  onShowScrapingDetailsButtonClick: PropTypes.func,
}

PdfMenuTitlesAndDishesTables.defaultProps = {
  onShowScrapingDetailsButtonClick: undefined,
}

export default PdfMenuTitlesAndDishesTables
