import * as Yup from 'yup'

import { HTML_NOT_SCRAPEABLE, PDF } from '../../../../menus/constants'

const saveMenuScrapingTaskSchema = Yup.object().shape({
  menu: Yup.object().shape({
    format: Yup.string().required('Format is required'),

    notScrapeableReason: Yup.string()
      .when('format', {
        is: format => format === HTML_NOT_SCRAPEABLE,
        then: Yup.string().required('Not scrapeable reason is required'),
      })
      .nullable(),
  }),
  menuPdfFiles: Yup.array().when('menu.format', {
    is: PDF,
    then: Yup.array().of(
      Yup.object().shape({
        menuCategory: Yup.string().required('Menu category is required'),
      }),
    ),
  }),
})

export default saveMenuScrapingTaskSchema
