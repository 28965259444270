import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { useMutation } from '@apollo/react-hooks'
import { yupResolver } from '@hookform/resolvers'
import { Col, Modal, Row } from 'antd'
import { join, map, values } from 'lodash-es'
import PropTypes from 'prop-types'

import { Checkbox, Input, NumberInput, Select } from '../../../core/components'
import { Button, Panel } from '../../../core/components/styled'

import { ADMIN } from '../../../common/constants'
import {
  showErrorNotification,
  showSuccessNotification,
} from '../../../common/helpers'
import { useIsLoggedInUser, useUserHasRoles } from '../../../common/hooks'
import { formatDate } from '../../../common/services/formatter'

import {
  COMPLEX,
  CORE,
  LEAD,
  MENU_SCRAPING_CLUSTER_ROLES,
} from '../../../menus/constants/menu-scraping-cluster-roles'
import { START_HTML_TEMPLATE_VALIDATION_SESSION_FOR_CLUSTER } from '../../graphql/mutations'
import { transformToStartTemplateValidationSessionForClusterInput } from '../../graphql/transformers'

import { startTemplateValidationSessionForClusterSchema } from './schemas'

const StartTemplateValidationSessionForClusterForm = ({
  menuScrapingCluster,
  onStartMenuScrapingSessionForCluster,
}) => {
  const [isStartingScrapingSession, setIsStartingScrapingSession] = useState(
    false,
  )
  const [lastUsedVariables, setLastUsedVariables] = useState(null)

  const isLoggedInUser = useIsLoggedInUser(menuScrapingCluster.assignedTo)
  const isAdmin = useUserHasRoles(ADMIN)

  const showConfirmationForNoMenusAvailable = () => {
    Modal.info({
      title: 'Information',
      content: (
        <div>
          <p>
            There are no menus available for the current session configuration.
          </p>
          <p>Please review your configuration and try again.</p>
        </div>
      ),
      width: 600,
      onOk() {},
    })
  }

  const showConfirmationForMinTimeSlotCount = (
    data,
    startSessionAfterConfirmation,
  ) => {
    Modal.confirm({
      title: 'Confirmation',
      content: (
        <div>
          <p style={{ marginBottom: '4px' }}>
            The minimum number of time slots is {data.minTimeSlotCount}.
          </p>
          <p style={{ marginBottom: '20px' }}>
            The session is estimated to complete around{' '}
            {formatDate(data.estimatedCompletionTime)}.
          </p>
          <p>Are you sure you want to start the session?</p>
        </div>
      ),
      width: 700,
      onOk: () => startSessionAfterConfirmation(),
    })
  }

  const showConfirmationForUnavailableMenusByRoles = (
    data,
    lastUsedVariables,
    startSessionAfterConfirmation,
  ) => {
    const { menuRoles } = lastUsedVariables.data

    const getMenuList = role =>
      data.unavailableMenuIdsByRoles[role]
        ? `${join(data.unavailableMenuIdsByRoles[role], ', ')}.`
        : 'None.'

    Modal.confirm({
      title: 'Confirmation',
      content: (
        <div>
          <p style={{ marginBottom: '20px' }}>
            Menus not available during session duration:
          </p>
          <ul style={{ marginBottom: '20px' }}>
            <li>
              For the selected roles the following menu ids:
              <ul>
                {map(menuRoles, role => (
                  <li key={role}>
                    {MENU_SCRAPING_CLUSTER_ROLES[role].name}:{' '}
                    {getMenuList(role)}
                  </li>
                ))}
              </ul>
            </li>
            <li>
              For random selection: {data.unavailableRandomlySelectedMenuCount}{' '}
              menus.
            </li>
          </ul>
          <p>Do you want to start the session only for the available menus?</p>
        </div>
      ),
      width: 700,
      onOk: () => startSessionAfterConfirmation(),
    })
  }

  const showConfirmationForUnavailableMenusByMenuIds = (
    data,
    startSessionAfterConfirmation,
  ) => {
    const unavailableMenuIds = data.unavailableMenuIds || []

    Modal.confirm({
      title: 'Confirmation',
      content: (
        <div>
          <div>
            The following menus are not available during the session duration:
          </div>
          <div style={{ marginBottom: '20px' }}>
            {join(unavailableMenuIds, ', ')}
          </div>
          <p>Do you want to start the session only for the available menus?</p>
        </div>
      ),
      width: 700,
      onOk: () => startSessionAfterConfirmation(),
    })
  }

  const [startMenuScrapingSessionForCluster] = useMutation(
    START_HTML_TEMPLATE_VALIDATION_SESSION_FOR_CLUSTER,
    {
      onCompleted() {
        setIsStartingScrapingSession(false)
        onStartMenuScrapingSessionForCluster()
        showSuccessNotification({
          message: 'Menu scraping session started.',
          description: 'Menu scraping session has been successfully started.',
        })
      },

      onError(error) {
        setIsStartingScrapingSession(false)

        const startSessionAfterConfirmation = () => {
          const variables = {
            ...lastUsedVariables,
            data: {
              ...lastUsedVariables.data,
              isStartSessionConfirmed: true,
            },
          }
          startMenuScrapingSessionForCluster({ variables })
        }

        const { type, data } =
          error?.graphQLErrors?.[0]?.extensions?.exception || {}

        if (type === 'SCRAPING_SESSION_NO_MENUS_AVAILABLE') {
          showConfirmationForNoMenusAvailable()
        } else if (
          type === 'SCRAPING_SESSION_MIN_TIME_SLOT_COUNT_NOT_CONFIRMED'
        ) {
          showConfirmationForMinTimeSlotCount(
            data,
            startSessionAfterConfirmation,
          )
        } else if (
          type ===
          'SCRAPING_SESSION_NOT_ALL_MENUS_SELECTED_BY_ROLES_ARE_AVAILABLE'
        ) {
          showConfirmationForUnavailableMenusByRoles(
            data,
            lastUsedVariables,
            startSessionAfterConfirmation,
          )
        } else if (
          type === 'SCRAPING_SESSION_NOT_ALL_MENUS_SELECTED_BY_IDS_AVAILABLE'
        ) {
          showConfirmationForUnavailableMenusByMenuIds(
            data,
            startSessionAfterConfirmation,
          )
        } else {
          showErrorNotification({
            message: 'Menu scraping session start failed.',
            description: error.message,
          })
        }
      },
    },
  )

  const handleStartMenuScrapingSession = useCallback(
    values => {
      setIsStartingScrapingSession(true)

      const variables = transformToStartTemplateValidationSessionForClusterInput(
        menuScrapingCluster.id,
        values,
      )

      setLastUsedVariables(variables)
      return startMenuScrapingSessionForCluster({ variables })
    },
    [menuScrapingCluster, startMenuScrapingSessionForCluster],
  )

  const formMethods = useForm({
    defaultValues: {
      sessionTimeSlotCount: 2,
      randomlySelectedMenuCount: 0,
      ignoreWorkingHours:
        menuScrapingCluster.areWorkingHoursIrrelevant || false,
      menuRoles: [LEAD, CORE, COMPLEX],
    },
    resolver: yupResolver(startTemplateValidationSessionForClusterSchema),
    mode: 'onChange',
  })

  const { handleSubmit, setValue, watch, trigger } = formMethods

  const menuIds = watch('menuIds')
  const menuRoles = watch('menuRoles')
  const randomlySelectedMenuCount = watch('randomlySelectedMenuCount')
  const sessionTimeSlotCount = watch('sessionTimeSlotCount')
  const useMinimumTimeSlotCount = watch('useMinimumTimeSlotCount')

  // We need this for validateMenuIdsOrMenuRolesOrRandomlySelectedMenuCount validation to work properly
  useEffect(() => {
    trigger()
  }, [menuIds, menuRoles, randomlySelectedMenuCount, trigger])

  useEffect(() => {
    setValue(
      'ignoreWorkingHours',
      menuScrapingCluster.areWorkingHoursIrrelevant || false,
    )
  }, [menuScrapingCluster.areWorkingHoursIrrelevant, setValue])

  useEffect(() => {
    if (menuIds) {
      setValue('menuRoles', undefined)
      setValue('randomlySelectedMenuCount', undefined)
    } else {
      setValue('menuRoles', [LEAD, CORE, COMPLEX])
      setValue('randomlySelectedMenuCount', 0)
    }
    trigger()
  }, [menuIds, setValue, trigger])

  useEffect(() => {
    if (useMinimumTimeSlotCount) {
      setValue('sessionTimeSlotCount', undefined)
    } else {
      setValue('sessionTimeSlotCount', 2)
    }
    trigger()
  }, [setValue, useMinimumTimeSlotCount, trigger])

  const scrapingRoleOptions = useMemo(
    () => values(MENU_SCRAPING_CLUSTER_ROLES),
    [],
  )

  const handleStartScrapingSession = useCallback(
    event => {
      event.preventDefault()

      const actualSubmit = () => {
        handleSubmit(handleStartMenuScrapingSession)()
      }

      if (sessionTimeSlotCount === 0) {
        Modal.confirm({
          title: 'Are you sure?',
          content:
            'Are you sure you want to send all the menus for scraping at once (without time slots)?',
          onOk: actualSubmit,
        })
      } else {
        actualSubmit()
      }
    },
    [handleSubmit, handleStartMenuScrapingSession, sessionTimeSlotCount],
  )

  return (
    <FormProvider {...formMethods}>
      <Panel margin="no no small no">
        <form onSubmit={handleStartScrapingSession}>
          <Row type="flex" align="bottom" justify="space-between">
            <Col span={2}>
              <Checkbox
                label="Min time slot count"
                name="useMinimumTimeSlotCount"
                infoMessage="This will include maximum 50 menus per time slot"
              />
            </Col>
            <Col span={2}>
              <NumberInput
                label="Time slot count"
                placeholder="Time slot count"
                name="sessionTimeSlotCount"
                min={0}
                max={672}
                disabled={useMinimumTimeSlotCount}
                infoMessage="The slot duration is 15 min"
              />
            </Col>
            <Col span={2}>
              <Checkbox label="Ignore WH" name="ignoreWorkingHours" />
            </Col>
            <Col span={6}>
              <Select
                name="menuRoles"
                label="Menu roles"
                placeholder="Select menu roles"
                options={scrapingRoleOptions}
                mode="multiple"
                allowClear
                maxTagCount={3}
                disabled={!!menuIds}
              />
            </Col>
            <Col span={3}>
              <NumberInput
                label="Randomly selected menu count"
                placeholder="Randomly selected menu count"
                name="randomlySelectedMenuCount"
                min={0}
                max={100}
                disabled={!!menuIds}
                infoMessage="Use this only if, besides the selected menu roles, you also want to include menus selected randomly."
              />
            </Col>
            <Col span={4}>
              <Input
                name="menuIds"
                label="Menu IDs"
                placeholder="Comma separated menu IDs"
              />
            </Col>
            <Col span={2}>
              <Button
                type="primary"
                disabled={
                  (!isLoggedInUser && !isAdmin) ||
                  !menuScrapingCluster.menuScrapingTemplate
                }
                htmlType="submit"
                loading={isStartingScrapingSession}
              >
                Start session
              </Button>
            </Col>
          </Row>
        </form>
      </Panel>
    </FormProvider>
  )
}

StartTemplateValidationSessionForClusterForm.propTypes = {
  menuScrapingCluster: PropTypes.object.isRequired,
  onStartMenuScrapingSessionForCluster: PropTypes.func,
}

StartTemplateValidationSessionForClusterForm.defaultProps = {
  onStartMenuScrapingSessionForCluster: () => {},
}

export default StartTemplateValidationSessionForClusterForm
