import React, { useCallback, useEffect, useState } from 'react'
import { useQuery } from '@apollo/react-hooks'
import { Button, Drawer, Icon, List, notification, Typography } from 'antd'
import fileDownload from 'js-file-download'
import PropTypes from 'prop-types'

import {
  COMPLETED_HTML_MENU_SCRAPING_JOBS_FOR_MENU,
  HTML_MENU_SCRAPING_JOB,
} from '../../menus/graphql/queries'
import { formatDate } from '../services/formatter'

import LondonDateTime from './london-date-time'
import Spinner from './spinner'

const HtmlMenuScrapingResponse = ({
  completedDate,
  responseType,
  scrapingStatus,
}) => (
  <Typography.Text>
    At <LondonDateTime date={completedDate} /> the scraping engine returned a{' '}
    {responseType} event with
    <Typography.Text strong> {scrapingStatus}</Typography.Text>
    {' status'}
  </Typography.Text>
)

HtmlMenuScrapingResponse.propTypes = {
  completedDate: PropTypes.string.isRequired,
  responseType: PropTypes.string.isRequired,
  scrapingStatus: PropTypes.string.isRequired,
}

const HtmlMenuScrapingHistoryDrawer = ({ menuId, buttonName, buttonSize }) => {
  const [isDrawerVisible, setIsDrawerVisible] = useState(false)
  const [htmlMenuScrapingJobId, setHtmlMenuScrapingJobId] = useState()

  const {
    data: { completedHtmlMenuScrapingJobsForMenu } = {},
    loading: isLoadingCompletedMenuScrapingJobs,
    refetch: refetchCompletedHtmlMenuScrapingJobs,
  } = useQuery(COMPLETED_HTML_MENU_SCRAPING_JOBS_FOR_MENU, {
    variables: { menuId },
    fetchPolicy: 'no-cache',
  })

  const {
    data: { htmlMenuScrapingJob = {} } = {},
    loading: isLoadingHtmlMenuScrapingJob,
    refetch: refetchHtmlMenuScrapingJob,
  } = useQuery(HTML_MENU_SCRAPING_JOB, {
    skip: !htmlMenuScrapingJobId,
    variables: { id: htmlMenuScrapingJobId },
    onCompleted() {
      if (!htmlMenuScrapingJobId) return

      fileDownload(
        JSON.stringify(
          htmlMenuScrapingJob.htmlMenuScrapingResponse.data,
          null,
          2,
        ),
        `${menuId}-${formatDate(htmlMenuScrapingJob.completedDate)}.json`,
      )
    },
    onError({ message }) {
      notification.open({
        message: 'Download scraping response failed.',
        description: message,
        type: 'error',
        duration: 0,
      })
    },
    fetchPolicy: 'no-cache',
  })

  useEffect(() => {
    if (!htmlMenuScrapingJobId) return
    refetchHtmlMenuScrapingJob()
  }, [htmlMenuScrapingJobId, refetchHtmlMenuScrapingJob])

  const onButtonClick = useCallback(() => {
    refetchCompletedHtmlMenuScrapingJobs()
    setIsDrawerVisible(true)
  }, [refetchCompletedHtmlMenuScrapingJobs])

  const onClose = useCallback(() => setIsDrawerVisible(false), [])

  return (
    <>
      <Button size={buttonSize} onClick={onButtonClick}>
        {buttonName}
        <Icon type="eye" />
      </Button>
      {isLoadingCompletedMenuScrapingJobs ? (
        <Spinner />
      ) : (
        <Drawer
          title="Scraping response history"
          placement="right"
          onClose={onClose}
          visible={isDrawerVisible}
          width="50%"
        >
          <List
            size="small"
            itemLayout="horizontal"
            dataSource={completedHtmlMenuScrapingJobsForMenu}
            renderItem={completedHtmlMenuScrapingJob => (
              <List.Item>
                <HtmlMenuScrapingResponse {...completedHtmlMenuScrapingJob} />
                <Button
                  size="small"
                  loading={
                    isLoadingHtmlMenuScrapingJob &&
                    completedHtmlMenuScrapingJob.id === htmlMenuScrapingJobId
                  }
                  onClick={() =>
                    setHtmlMenuScrapingJobId(completedHtmlMenuScrapingJob.id)
                  }
                >
                  Download
                  <Icon type="download" />
                </Button>
              </List.Item>
            )}
          />
        </Drawer>
      )}
    </>
  )
}

HtmlMenuScrapingHistoryDrawer.propTypes = {
  menuId: PropTypes.string.isRequired,
  buttonName: PropTypes.string.isRequired,
  buttonSize: PropTypes.string,
}

HtmlMenuScrapingHistoryDrawer.defaultProps = {
  buttonSize: 'small',
}

export default HtmlMenuScrapingHistoryDrawer
