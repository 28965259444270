import React from 'react'
import { Collapse, List, Typography } from 'antd'
import PropTypes from 'prop-types'

const ScrapingDebugInformationMenu = ({ menu }) => (
  <Collapse
    defaultActiveKey={[0]}
    style={{
      overflow: 'auto',
    }}
  >
    <Collapse.Panel header="Scraped Data" key={0}>
      <List size="small">
        <List.Item>
          <Typography.Text>
            <strong>Type: </strong>Menu
          </Typography.Text>
        </List.Item>
        {menu.status && (
          <List.Item>
            <Typography.Text>
              <strong>Status: </strong>
              {menu.status}
            </Typography.Text>
          </List.Item>
        )}
        <List.Item>
          <Typography.Text>
            <strong>Dish Count: </strong>
            {menu.dishCount}
          </Typography.Text>
        </List.Item>
        <List.Item>
          <Typography.Text>
            <strong>Scraping Duration: </strong>
            {menu.duration}
          </Typography.Text>
        </List.Item>
      </List>
    </Collapse.Panel>
  </Collapse>
)

ScrapingDebugInformationMenu.propTypes = {
  menu: PropTypes.object.isRequired,
}

export default ScrapingDebugInformationMenu
