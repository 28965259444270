import { mapKeys } from 'lodash-es'

export const NOT_A_RESTAURANT_MENU = 'NOT_A_RESTAURANT_MENU'
export const DRINKS_ONLY_MENU = 'DRINKS_ONLY_MENU'
export const UNSUPPORTED_SET_MENU = 'UNSUPPORTED_SET_MENU'
export const UNSUPPORTED_A_LA_CARTE_AND_SET_MENU =
  'UNSUPPORTED_A_LA_CARTE_AND_SET_MENU'
export const UNSUPPORTED_HIGH_CONTENT_DENSITY_MENU =
  'UNSUPPORTED_HIGH_CONTENT_DENSITY_MENU'
export const INVALID_HIERARCHY = 'INVALID_HIERARCHY'
export const HIGH_DISCREPANCIES = 'HIGH_DISCREPANCIES'
export const MEDIUM_DISCREPANCIES = 'MEDIUM_DISCREPANCIES'
export const LOW_DISCREPANCIES = 'LOW_DISCREPANCIES'
export const NO_DISCREPANCIES = 'NO_DISCREPANCIES'
export const UNSPECIFIED_ERROR = 'UNSPECIFIED_ERROR'

export const PDF_MENU_SCRAPING_REASONS = mapKeys(
  [
    { name: 'Not a restaurant menu', value: NOT_A_RESTAURANT_MENU },
    { name: 'Drinks only menu', value: DRINKS_ONLY_MENU },
    { name: 'Unsupported set menu', value: UNSUPPORTED_SET_MENU },
    {
      name: 'Unsupported a la carte and set menu',
      value: UNSUPPORTED_A_LA_CARTE_AND_SET_MENU,
    },
    {
      name: 'Unsupported high content density menu',
      value: UNSUPPORTED_HIGH_CONTENT_DENSITY_MENU,
    },
    { name: 'Invalid hierarchy', value: INVALID_HIERARCHY },
    { name: 'High discrepancies', value: HIGH_DISCREPANCIES },
    { name: 'Medium discrepancies', value: MEDIUM_DISCREPANCIES },
    { name: 'Low discrepancies', value: LOW_DISCREPANCIES },
    { name: 'No discrepancies', value: NO_DISCREPANCIES },
    { name: 'Unspecified error', value: UNSPECIFIED_ERROR },
  ],
  'value',
)
