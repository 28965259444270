import { gql } from 'apollo-boost'

const PDF_MENU_SCRAPING_RESPONSE = gql`
  query pdfMenuScrapingResponse($pdfMenuScrapingJobId: ID!) {
    pdfMenuScrapingResponse(pdfMenuScrapingJobId: $pdfMenuScrapingJobId) {
      id
      menuId
      data
    }
  }
`

export default PDF_MENU_SCRAPING_RESPONSE
