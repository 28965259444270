import React from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers'
import { Row } from 'antd'
import PropTypes from 'prop-types'

import { HiddenInputs, TextArea } from '../../../core/components'
import { Button } from '../../../core/components/styled'

import { rejectMenuScrapingFormInitialValues } from './initial-values'
import { rejectMenuScrapingSchema } from './schemas'

const RejectPdfMenuScrapingForm = ({
  taskId,
  menuScrapingRejectedComment,
  closeModal,
  isRejectingMenu,
  onSubmit,
}) => {
  const formMethods = useForm({
    defaultValues: rejectMenuScrapingFormInitialValues(
      taskId,
      menuScrapingRejectedComment,
    ),
    resolver: yupResolver(rejectMenuScrapingSchema),
  })

  return (
    <FormProvider {...formMethods}>
      <form onSubmit={formMethods.handleSubmit(onSubmit)}>
        <Row>
          <TextArea name="scrapingRejectedComment" label="Comment" />
        </Row>
        <Row type="flex" justify="end">
          <Button onClick={closeModal} margin="small small no no">
            Cancel
          </Button>
          <Button
            type="primary"
            htmlType="submit"
            loading={isRejectingMenu}
            margin="small no no no"
          >
            Save
          </Button>
        </Row>

        <HiddenInputs names={['id']} />
      </form>
    </FormProvider>
  )
}

RejectPdfMenuScrapingForm.propTypes = {
  taskId: PropTypes.string,
  menuScrapingRejectedComment: PropTypes.object,
  closeModal: PropTypes.func.isRequired,
  isRejectingMenu: PropTypes.bool.isRequired,
  onSubmit: PropTypes.func.isRequired,
}

RejectPdfMenuScrapingForm.defaultProps = {
  taskId: undefined,
  menuScrapingRejectedComment: undefined,
}

export default RejectPdfMenuScrapingForm
