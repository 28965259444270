import React from 'react'
import { Collapse, List, Typography } from 'antd'
import PropTypes from 'prop-types'

import ScrapingDebugJsonPanel from '../scraping-debug-json-panel'

const ScrapingDebugInformationMenuTitle = ({ menuTitle }) => (
  <Collapse
    defaultActiveKey={[0, 1]}
    style={{
      overflow: 'auto',
    }}
  >
    <Collapse.Panel header="Scraped Data" key={0}>
      <List size="small">
        <List.Item>
          <Typography.Text>
            <strong>Type: </strong>Menu Title
          </Typography.Text>
        </List.Item>
        <List.Item>
          <Typography.Text>
            <strong>Title: </strong>
            {menuTitle.title}
          </Typography.Text>
        </List.Item>
        <List.Item>
          <Typography.Text>
            <strong>Description: </strong>
            {menuTitle.description}
          </Typography.Text>
        </List.Item>
        <List.Item>
          <Typography.Text>
            <strong>Diets: </strong>
            {menuTitle.diets}
          </Typography.Text>
        </List.Item>
        <List.Item>
          <Typography.Text>
            <strong>Addons: </strong>
            {menuTitle.addons}
          </Typography.Text>
        </List.Item>
        <List.Item>
          <Typography.Text>
            <strong>Misc Info: </strong>
            {menuTitle.miscInfo}
          </Typography.Text>
        </List.Item>
        <List.Item>
          <Typography.Text>
            <strong>Allergens: </strong>
            {menuTitle.allergens}
          </Typography.Text>
        </List.Item>
        <List.Item>
          <Typography.Text>
            <strong>Nutritions: </strong>
            {menuTitle.nutritions}
          </Typography.Text>
        </List.Item>
      </List>
    </Collapse.Panel>
    <ScrapingDebugJsonPanel
      header="Discrepancies"
      panelId={1}
      items={menuTitle.discrepancies}
    />
  </Collapse>
)

ScrapingDebugInformationMenuTitle.propTypes = {
  menuTitle: PropTypes.object.isRequired,
}

export default ScrapingDebugInformationMenuTitle
