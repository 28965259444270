import React from 'react'
import { Icon, Typography } from 'antd'
import { groupBy, size } from 'lodash-es'

import { THEME } from '../../../core/constants'

import { SCRAPED_ITEM_ANALYSIS_SEVERITY } from '../../constants'

const nodeStyle = {
  fontStyle: 'italic',
  padding: '5px',
  borderRadius: '2px',
}

const getTreeSelectNodeTitle = (node, scrapedItem) => {
  const analysisData =
    node.parentId === null ? scrapedItem.analysisFull : scrapedItem.analysis

  const messagesGroupedBySeverity = groupBy(analysisData, 'severity')

  const errorsCount = size(
    messagesGroupedBySeverity[SCRAPED_ITEM_ANALYSIS_SEVERITY.ERROR],
  )

  const warningsCount = size(
    messagesGroupedBySeverity[SCRAPED_ITEM_ANALYSIS_SEVERITY.WARNING],
  )

  return (
    <>
      <Typography.Text
        style={{
          ...nodeStyle,
        }}
      >
        {node.title}
      </Typography.Text>
      {errorsCount > 0 && (
        <>
          <Icon
            type="close-circle"
            theme="filled"
            style={{
              marginRight: '12px',
              width: '5px',
              heigh: '5px',
              color: THEME.colors.red,
            }}
          />
          <Typography.Text>{errorsCount}</Typography.Text>
        </>
      )}
      {warningsCount > 0 && (
        <>
          <Icon
            type="warning"
            theme="filled"
            style={{
              marginLeft: '5px',
              marginRight: '12px',
              width: '5px',
              heigh: '5px',
              color: THEME.colors.yellow,
            }}
          />
          <Typography.Text>{warningsCount}</Typography.Text>
        </>
      )}
    </>
  )
}

export default getTreeSelectNodeTitle
