import React, { useMemo } from 'react'
import { Badge, Tooltip } from 'antd'
import { isNil } from 'lodash-es'
import PropTypes from 'prop-types'

import { Button } from '../../../../core/components/styled'
import { THEME } from '../../../../core/constants'

import { formatDate } from '../../../../common/services/formatter'
import { areSameValue } from '../../../../common/utils'

import { SCRAPE_SCHEDULED } from '../../../../menus/constants'
import { RESOLVED, TASK_VERIFIED_STATUSES } from '../../../constants'

const { Group: ButtonGroup } = Button

const VERIFIED = 'Verified'
const NOT_VERIFIED = 'Not verified'

const ScrapingVerificationThreeStateSwitch = ({
  task,
  menu,
  loggedInUser,
  onChange,
  loading,
}) => {
  const isVerified = useMemo(() => !isNil(task.verifiedBy?.name), [
    task.verifiedBy,
  ])

  const isVerifiedInDefaultMode = useMemo(
    () => !isNil(task.verifiedBy?.name) && !task.isVerifiedInDebugMode,
    [task.verifiedBy, task.isVerifiedInDebugMode],
  )

  const isVerifiedInDebugMode = useMemo(
    () => !isNil(task.verifiedBy?.name) && task.isVerifiedInDebugMode,
    [task.verifiedBy, task.isVerifiedInDebugMode],
  )

  const hasUserPermission = useMemo(
    () => areSameValue(task.resolvedBy?.id, loggedInUser.id),
    [task.resolvedBy, loggedInUser.id],
  )

  const isDisabled = useMemo(() => task.status !== RESOLVED, [task.status])

  const isScrapingInProgress = useMemo(() => menu.status === SCRAPE_SCHEDULED, [
    menu.status,
  ])

  const isNotVerifiedButtonDisabled = useMemo(
    () => loading || isDisabled || !isVerified,
    [loading, isDisabled, isVerified],
  )

  const isVerifiedDefaultButtonDisabled = useMemo(
    () =>
      loading || isDisabled || isVerifiedInDefaultMode || isScrapingInProgress,
    [loading, isDisabled, isVerifiedInDefaultMode, isScrapingInProgress],
  )

  const isVerifiedDebugButtonDisabled = useMemo(
    () =>
      loading || isDisabled || isVerifiedInDebugMode || isScrapingInProgress,
    [loading, isDisabled, isVerifiedInDebugMode, isScrapingInProgress],
  )

  const verifiedInfo = isVerified
    ? `Verified by ${task.verifiedBy.name} at ${formatDate(
        task.verifiedDate,
        false,
      )}`
    : `Not verified`

  const buttonCommonStyle = useMemo(
    () => ({
      width: '140px',
      height: '22px',
      borderRadius: 0,
    }),
    [],
  )

  const buttonSelectedStyle = useMemo(
    () => ({
      backgroundColor: THEME.colors.greyBlue,
      color: THEME.colors.white,
    }),
    [],
  )

  const notVerifiedButtonStyle = useMemo(
    () => ({
      ...buttonCommonStyle,
      ...(!isVerified && buttonSelectedStyle),
    }),
    [buttonCommonStyle, buttonSelectedStyle, isVerified],
  )

  const verifiedInDefaultModeButtonStyle = useMemo(
    () => ({
      ...buttonCommonStyle,
      ...(isVerifiedInDefaultMode && buttonSelectedStyle),
    }),
    [buttonCommonStyle, buttonSelectedStyle, isVerifiedInDefaultMode],
  )

  const verifiedInDebugModeButtonStyle = useMemo(
    () => ({
      ...buttonCommonStyle,
      ...(isVerifiedInDebugMode && buttonSelectedStyle),
    }),
    [buttonCommonStyle, buttonSelectedStyle, isVerifiedInDebugMode],
  )

  return hasUserPermission ? (
    <Tooltip placement="bottom" title={verifiedInfo} trigger="hover">
      <ButtonGroup margin="no small no no">
        <Button
          type={!isVerified ? 'primary' : 'secondary'}
          style={notVerifiedButtonStyle}
          onClick={() => onChange(TASK_VERIFIED_STATUSES.NOT_VERIFIED)}
          disabled={isNotVerifiedButtonDisabled}
        >
          Not Verified
        </Button>
        <Button
          type={isVerifiedInDefaultMode ? 'primary' : 'secondary'}
          style={verifiedInDefaultModeButtonStyle}
          disabled={isVerifiedDefaultButtonDisabled}
          onClick={() =>
            onChange(TASK_VERIFIED_STATUSES.VERIFIED_IN_DEFAULT_MODE)
          }
        >
          Verified - Default
        </Button>
        {menu.isHtmlMenuScrapedInDebugMode && (
          <Button
            style={verifiedInDebugModeButtonStyle}
            disabled={isVerifiedDebugButtonDisabled}
            onClick={() =>
              onChange(TASK_VERIFIED_STATUSES.VERIFIED_IN_DEBUG_MODE)
            }
          >
            Verified - Debug
          </Button>
        )}
      </ButtonGroup>
    </Tooltip>
  ) : (
    <Tooltip placement="bottom" title={verifiedInfo} trigger="hover">
      <Badge
        count={isVerified ? VERIFIED : NOT_VERIFIED}
        style={{
          marginTop: '5px',
          backgroundColor: isVerified ? THEME.colors.green : THEME.colors.grey,
        }}
      />
    </Tooltip>
  )
}

ScrapingVerificationThreeStateSwitch.propTypes = {
  task: PropTypes.object.isRequired,
  menu: PropTypes.object.isRequired,
  loggedInUser: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
}

export default ScrapingVerificationThreeStateSwitch
