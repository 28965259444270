import React from 'react'
import { Col, Row } from 'antd'

import { Panel } from '../../../core/components/styled'

import { SearchBrandFilter, SearchMenuFilter } from '../../../common/components'
import { useDocumentTitle } from '../../../common/hooks/effects'

import { AllMenusPage } from '.'

const MenusPage = () => {
  useDocumentTitle('Menus')

  return (
    <>
      <Panel margin="small no">
        <Row gutter={16}>
          <Col span={8}>
            <SearchBrandFilter />
          </Col>
          <Col span={9}>
            <SearchMenuFilter />
          </Col>
        </Row>
      </Panel>

      <Panel margin="small no">
        <AllMenusPage />
      </Panel>
    </>
  )
}

export default MenusPage
