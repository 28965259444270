import React, { useCallback, useState } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { useLazyQuery, useMutation, useQuery } from '@apollo/react-hooks'
import { Icon, Table } from 'antd'
import { includes, without } from 'lodash-es'

import { PopoverReactJson } from '../../../core/components'
import { Button } from '../../../core/components/styled'
import {
  createUrl,
  getQueryParams,
} from '../../../core/utils/services/queryParams'

import { LondonDateTime } from '../../../common/components'
import { DEFAULT_PAGE } from '../../../common/constants'
import {
  showErrorNotification,
  showSuccessNotification,
} from '../../../common/helpers'
import { useDocumentTitle } from '../../../common/hooks/effects'

import { START_HTML_MENU_SCRAPING_SESSION } from '../../../menu-scraping-templates/graphql/mutations'
import { transformToStartHtmlMenuScrapingSessionInput } from '../../../menu-scraping-templates/graphql/transformers'
import { IN_PROGRESS, MENU_SCRAPING_SESSION_STATUSES } from '../../constants'
import { ABORT_HTML_MENU_SCRAPING_SESSION } from '../../graphql/mutations'
import {
  HTML_MENU_SCRAPING_SESSION_REPORT_URL,
  HTML_MENU_SCRAPING_SESSIONS,
} from '../../graphql/queries'
import { StartHtmlMenuScrapingSessionForm } from '../forms'

const HtmlMenuScrapingSessionsPage = () => {
  useDocumentTitle('Menu Scraping Sessions')
  const history = useHistory()
  const { search, pathname } = useLocation()
  const { page = DEFAULT_PAGE } = getQueryParams(search)
  const [htmlMenuScrapingSessionId, setHtmlMenuScrapingSessionId] = useState()

  const [abortSessionIds, setAbortSessionIds] = useState([])
  const [
    isStartingHtmlMenuScrapingSession,
    setIsStartingHtmlMenuScrapingSession,
  ] = useState(false)

  const {
    data: { htmlMenuScrapingSessions = {} } = {},
    loading: isLoadingHtmlMenuScrapingSessions,
    refetch: refetchHtmlMenuScrapingSessions,
  } = useQuery(HTML_MENU_SCRAPING_SESSIONS, {
    variables: {
      page,
    },
    fetchPolicy: 'no-cache',
  })

  const [
    fetchHtmlMenuScrapingSessionReportUrl,
    { loading: isLoadingHtmlMenuScrapingSessionReportUrl },
  ] = useLazyQuery(HTML_MENU_SCRAPING_SESSION_REPORT_URL, {
    onCompleted(data) {
      window.open(data.htmlMenuScrapingSessionReportUrl, '_blank')
    },
    onError({ message }) {
      showErrorNotification({
        message: 'Download menu scraping report failed.',
        description: message,
      })
    },
    fetchPolicy: 'no-cache',
  })

  const handleClickDownloadReport = htmlMenuScrapingSession => {
    setHtmlMenuScrapingSessionId(htmlMenuScrapingSession.id)

    fetchHtmlMenuScrapingSessionReportUrl({
      variables: { id: htmlMenuScrapingSession.id },
    })
  }

  const [abortHtmlMenuScrapingSession] = useMutation(
    ABORT_HTML_MENU_SCRAPING_SESSION,
    {
      onCompleted({ abortHtmlMenuScrapingSession: { id } }) {
        setAbortSessionIds(without(abortSessionIds, id))

        refetchHtmlMenuScrapingSessions()

        showSuccessNotification({
          message: 'Menu scraping rerun aborted.',
          description: `Menu scraping rerun ${id} has been successfully aborted.`,
        })
      },
      onError({ message }) {
        showErrorNotification({
          message: 'Menu scraping rerun abort failed.',
          description: message,
        })
      },
    },
  )

  const handleClickAbort = htmlMenuScrapingSession => {
    setAbortSessionIds([...abortSessionIds, htmlMenuScrapingSession.id])

    abortHtmlMenuScrapingSession({
      variables: { id: htmlMenuScrapingSession.id },
    })
  }

  const [startHtmlMenuScrapingSession] = useMutation(
    START_HTML_MENU_SCRAPING_SESSION,
    {
      onCompleted() {
        setIsStartingHtmlMenuScrapingSession(false)
        refetchHtmlMenuScrapingSessions()
        showSuccessNotification({
          message: 'Menu scraping rerun started.',
          description: 'Menu scraping rerun has been successfully started.',
        })
      },

      onError({ message }) {
        setIsStartingHtmlMenuScrapingSession(false)
        showErrorNotification({
          message: 'Menu scraping rerun start failed.',
          description: message,
        })
      },
    },
  )

  const handleStartHtmlMenuScrapingSession = useCallback(
    values => {
      setIsStartingHtmlMenuScrapingSession(true)
      const variables = transformToStartHtmlMenuScrapingSessionInput(values)
      return startHtmlMenuScrapingSession({ variables })
    },
    [startHtmlMenuScrapingSession],
  )

  const tableColumns = [
    {
      title: 'Id',
      width: '4%',
      dataIndex: 'id',
    },
    {
      title: 'Status',
      width: '7%',
      dataIndex: 'status',
      render: status => MENU_SCRAPING_SESSION_STATUSES[status].name,
    },
    {
      title: 'Created date',
      width: '10%',
      dataIndex: 'createdDate',
      render: date => <LondonDateTime date={date} />,
    },
    {
      title: 'Created by',
      width: '10%',
      dataIndex: 'createdBy.name',
    },
    {
      title: 'Time slot count',
      width: '10%',
      dataIndex: 'timeSlotCount',
    },
    {
      title: 'Start time',
      width: '10%',
      dataIndex: 'startTime',
      render: date => <LondonDateTime date={date} />,
    },
    {
      title: 'Estimated completion time',
      width: '10%',
      dataIndex: 'estimatedCompletionTime',
      render: date => <LondonDateTime date={date} />,
    },
    {
      title: 'Completed date',
      width: '10%',
      dataIndex: 'completedDate',
      render: date => <LondonDateTime date={date} />,
    },
    {
      title: 'Total menu count',
      width: '10%',
      dataIndex: 'totalMenuCount',
    },
    {
      title: 'Scraped menu count',
      width: '10%',
      dataIndex: 'scrapedMenuCount',
    },
    {
      title: 'Job counts',
      width: '120px',
      dataIndex: 'jobCountByScrapingStatus',
      render: jobCountByScrapingStatus => (
        <PopoverReactJson source={JSON.parse(jobCountByScrapingStatus)} />
      ),
    },
    {
      title: 'Report',
      width: '5%',
      render: htmlMenuScrapingSession => (
        <Button
          margin="no small no no"
          loading={
            isLoadingHtmlMenuScrapingSessionReportUrl &&
            htmlMenuScrapingSession.id === htmlMenuScrapingSessionId
          }
          onClick={() => handleClickDownloadReport(htmlMenuScrapingSession)}
        >
          Download
          <Icon type="file" />
        </Button>
      ),
    },
    {
      title: 'Abort',
      width: '4%',
      render: htmlMenuScrapingSession => (
        <Button
          margin="no small no no"
          disabled={htmlMenuScrapingSession.status !== IN_PROGRESS}
          loading={includes(abortSessionIds, htmlMenuScrapingSession.id)}
          onClick={() => handleClickAbort(htmlMenuScrapingSession)}
        >
          Abort
          <Icon type="stop" />
        </Button>
      ),
    },
  ]

  const handlePageChange = useCallback(
    page => history.push(createUrl(pathname, search, { page })),
    [history, search, pathname],
  )

  return (
    <>
      <StartHtmlMenuScrapingSessionForm
        isStartingHtmlMenuScrapingSession={isStartingHtmlMenuScrapingSession}
        onSubmit={handleStartHtmlMenuScrapingSession}
      />
      <Table
        loading={isLoadingHtmlMenuScrapingSessions}
        rowKey="id"
        columns={tableColumns}
        dataSource={htmlMenuScrapingSessions.htmlMenuScrapingSessions}
        pagination={{
          current: page,
          total: htmlMenuScrapingSessions.total,
          onChange: handlePageChange,
        }}
      />
    </>
  )
}

export default HtmlMenuScrapingSessionsPage
