import { gql } from 'apollo-boost'

const SET_MENU_SCRAPING_REJECTED_COMMENT = gql`
  mutation setMenuScrapingRejectedComment(
    $id: ID!
    $scrapingRejectedComment: String!
    $assignedToUserId: ID
  ) {
    setMenuScrapingRejectedComment(
      id: $id
      scrapingRejectedComment: $scrapingRejectedComment
      assignedToUserId: $assignedToUserId
    ) {
      id
    }
  }
`

export default SET_MENU_SCRAPING_REJECTED_COMMENT
