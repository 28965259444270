import { mapKeys } from 'lodash-es'

export const BRAND_GATEWAY_TASK = 'BRAND_GATEWAY_TASK'
export const BRAND_DEFINITION_TASK = 'BRAND_DEFINITION_TASK'
export const MENU_DEFINITION_TASK = 'MENU_DEFINITION_TASK'
export const MENU_SCRAPING_TASK = 'MENU_SCRAPING_TASK'
export const DISH_REVIEW_TASK = 'DISH_REVIEW_TASK'
export const USER = 'USER'
export const BRAND = 'BRAND'
export const LOCATION = 'LOCATION'
export const MENU = 'MENU'
// export const MENU_SCRAPING_RESPONSE = 'MENU_SCRAPING_RESPONSE'
export const MENU_SCRAPING_TEMPLATE = 'MENU_SCRAPING_TEMPLATE'
export const MENU_SCRAPING_CLUSTER = 'MENU_SCRAPING_CLUSTER'

export const ENTITY_TYPES = mapKeys(
  [
    { name: 'Brand gateway task', value: BRAND_GATEWAY_TASK },
    { name: 'Brand definition task', value: BRAND_DEFINITION_TASK },
    { name: 'Menu definition task', value: MENU_DEFINITION_TASK },
    { name: 'Menu scraping task', value: MENU_SCRAPING_TASK },
    { name: 'Dish review task', value: DISH_REVIEW_TASK },
    { name: 'User', value: USER },
    { name: 'Brand', value: BRAND },
    { name: 'Location', value: LOCATION },
    { name: 'Menu', value: MENU },
    // { name: 'Menu scraping response', value: MENU_SCRAPING_RESPONSE },
    { name: 'Menu scraping template', value: MENU_SCRAPING_TEMPLATE },
    { name: 'Menu scraping cluster', value: MENU_SCRAPING_CLUSTER },
  ],
  'value',
)
