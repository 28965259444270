import { gql } from 'apollo-boost'

const RESOLVE_DISH_REVIEW_TASK_BY_REJECT_HTML_MENU = gql`
  mutation resolveDishReviewTaskByRejectHtmlMenu(
    $id: ID!
    $assignedToUserId: ID
    $rejectMenuStatus: MenuStatus!
    $scrapingRejectedComment: String!
  ) {
    resolveDishReviewTaskByRejectHtmlMenu(
      id: $id
      assignedToUserId: $assignedToUserId
      rejectMenuStatus: $rejectMenuStatus
      scrapingRejectedComment: $scrapingRejectedComment
    ) {
      id
    }
  }
`

export default RESOLVE_DISH_REVIEW_TASK_BY_REJECT_HTML_MENU
