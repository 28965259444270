import { gql } from 'apollo-boost'

const MENU_SCRAPING_REJECTED_COMMENT = gql`
  query menuScrapingRejectedComment($id: ID!) {
    menuScrapingRejectedComment(id: $id) {
      scrapingRejectedComment
      scrapedById
      assignedToId
    }
  }
`

export default MENU_SCRAPING_REJECTED_COMMENT
