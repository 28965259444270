import React, { useCallback, useState } from 'react'
import { useQuery } from '@apollo/react-hooks'
import { Button, Drawer, List } from 'antd'
import PropTypes from 'prop-types'

import { RESOLVE } from '../../event/constants'
import { EVENTS } from '../../event/graphql/queries'
import {
  MENU_STATUSES,
  REJECTED_NEW,
  REJECTED_RESCRAPE,
} from '../../menus/constants'
import { DISH_REVIEW_TASK } from '../constants'

import LondonDateTime from './london-date-time'
import Spinner from './spinner'

const HistoryItem = ({ createdDate, user, data }) => (
  <List.Item>
    At <LondonDateTime date={createdDate} /> {`${user.name} : Commented : `}
    <span>{MENU_STATUSES[data.menu.status].name}</span>
    <i>{` - ${data.menu.scrapingRejectedComment ||
      data.menu.rescrapingComment}. `}</i>
    {data.menuScrapingTaskAssignedToUser && (
      <span>{`Assigned to ${data.menuScrapingTaskAssignedToUser.name}.`}</span>
    )}
  </List.Item>
)

HistoryItem.propTypes = {
  createdDate: PropTypes.string.isRequired,
  user: PropTypes.object.isRequired,
  data: PropTypes.number.isRequired,
}

const ScrapingRejectedCommentHistoryDrawer = ({ taskId }) => {
  const [isDrawerVisible, setIsDrawerVisible] = useState(false)

  const {
    data: { events } = {},
    loading: isLoading,
    refetch: refetchHistory,
  } = useQuery(EVENTS, {
    variables: {
      filters: {
        entityTypes: [DISH_REVIEW_TASK],
        menuStatuses: [REJECTED_NEW, REJECTED_RESCRAPE],
        types: [RESOLVE],
        entityIds: [taskId],
      },
    },
    fetchPolicy: 'no-cache',
  })

  const onButtonClick = useCallback(() => {
    refetchHistory()
    setIsDrawerVisible(true)
  }, [refetchHistory])

  const onClose = useCallback(() => setIsDrawerVisible(false), [])
  return (
    <>
      <Button size="default" style={{ margin: '5px' }} onClick={onButtonClick}>
        Rescraping history
      </Button>
      {isLoading ? (
        <Spinner />
      ) : (
        <Drawer
          title="Scraping rejection comment history"
          placement="right"
          onClose={onClose}
          visible={isDrawerVisible}
          width="50%"
        >
          <List
            size="small"
            itemLayout="horizontal"
            dataSource={events}
            renderItem={HistoryItem}
          />
        </Drawer>
      )}
    </>
  )
}

ScrapingRejectedCommentHistoryDrawer.propTypes = {
  taskId: PropTypes.string.isRequired,
}

export default ScrapingRejectedCommentHistoryDrawer
