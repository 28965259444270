import { filter, map } from 'lodash-es'

import { MENU_ITEM_STATUSES } from '../constants'

const prepareDishes = (dishes, status) =>
  map(dishes, dish => ({ ...JSON.parse(dish), status }))

const prepareUpdatedDishes = dishes => {
  const parsedDishes = map(dishes, dish => JSON.parse(dish))

  const withSignificantContentChanges = filter(
    parsedDishes,
    dish => dish.hasSignificantContentChanges,
  )

  const withoutSignificantContentChanges = filter(
    parsedDishes,
    dish => dish.hasContentChanges && !dish.hasSignificantContentChanges,
  )

  return [
    ...map(withSignificantContentChanges, dish => ({
      ...dish,
      status: MENU_ITEM_STATUSES.UPDATED_SIGNIFICANTLY,
    })),
    ...map(withoutSignificantContentChanges, dish => ({
      ...dish,
      status: MENU_ITEM_STATUSES.UPDATED,
    })),
  ]
}

const getHtmlMenuScrapingVersionDishesChanges = htmlMenuScrapingJob => {
  const {
    deletedDishes,
    restoredDishes,
    updatedDishes,
    createdDishes,
  } = htmlMenuScrapingJob

  return [
    ...prepareDishes(deletedDishes, MENU_ITEM_STATUSES.DELETED),
    ...prepareDishes(restoredDishes, MENU_ITEM_STATUSES.RESTORED),
    ...prepareUpdatedDishes(updatedDishes),
    ...prepareDishes(createdDishes, MENU_ITEM_STATUSES.CREATED),
  ]
}

export default getHtmlMenuScrapingVersionDishesChanges
