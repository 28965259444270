import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useQuery } from '@apollo/react-hooks'
import { Empty, Icon, Layout, Popover, Row, Typography } from 'antd'
import { isEmpty, size } from 'lodash-es'
import PropTypes from 'prop-types'

import { THEME } from '../../../../../core/constants'

import { Spinner } from '../../../../../common/components'
import { getFileNameFromPathWithoutExtension } from '../../../../../common/utils'

import { SCRAPED_ITEM_SELECTION_SOURCES } from '../../../../constants'
import { HTML_MENU_SCRAPING_SCREENSHOT_URL } from '../../../../graphql/queries'
import {
  getFirstScreenshotIndexByScrapedItemHash,
  getScrapedItemsScreenshots,
} from '../../../../helpers/html-menus'

import ScrapingDebugGalleryPagination from './scraping-debug-gallery-pagination'
import ScrapingDebugScreenshotStatsPanel from './scraping-debug-screenshot-stats-panel'

const { Footer, Content, Sider } = Layout

const ScrapingDebugGallery = ({
  menuId,
  scrapedItems,
  selectedItemHash,
  selectedItemSource,
  handleSelectedHashChange,
}) => {
  const [currentScreenshot, setCurrentScreenshot] = useState(null)
  const [currentPageNo, setCurrentPageNo] = useState(1)
  const [isDetailsPopoverOpen, setIsDetailsPopoverOpen] = useState(false)

  const {
    data: { htmlMenuScrapingScreenshotUrl } = {},
    loading: isLoadingScrapingScreenshot,
  } = useQuery(HTML_MENU_SCRAPING_SCREENSHOT_URL, {
    variables: {
      bucketName: currentScreenshot?.amazonS3File?.bucketName,
      fileKey: currentScreenshot?.amazonS3File?.fileKey,
    },
    fetchPolicy: 'cache-first', // To avoid requesting a new url each time when the user navigates back and forth.
    skip:
      isEmpty(currentScreenshot?.amazonS3File?.bucketName) ||
      isEmpty(currentScreenshot?.amazonS3File?.fileKey),
  })

  const screenshots = useMemo(
    () => getScrapedItemsScreenshots(scrapedItems, menuId),
    [menuId, scrapedItems],
  )

  const firstScreenshotIndexByScrapedItemHash = useMemo(
    () => getFirstScreenshotIndexByScrapedItemHash(screenshots),
    [screenshots],
  )

  useEffect(() => {
    if (
      selectedItemSource ===
      SCRAPED_ITEM_SELECTION_SOURCES.SCRAPING_DEBUG_GALLERY
    )
      return

    const index = firstScreenshotIndexByScrapedItemHash.get(selectedItemHash)
    setCurrentScreenshot(screenshots[index])
    setCurrentPageNo(index + 1)
  }, [
    screenshots,
    firstScreenshotIndexByScrapedItemHash,
    selectedItemHash,
    selectedItemSource,
  ])

  const imageStyles = useMemo(
    () => ({
      width: '100%',
      height: '100%',
    }),
    [],
  )

  const handlePageChange = useCallback(
    pageNo => {
      setCurrentPageNo(pageNo)

      const screenshot = screenshots[pageNo - 1]
      setCurrentScreenshot(screenshot)

      handleSelectedHashChange(
        screenshot.scrapedItemHash,
        SCRAPED_ITEM_SELECTION_SOURCES.SCRAPING_DEBUG_GALLERY,
      )
    },
    [screenshots, handleSelectedHashChange],
  )

  const renderedContent = useMemo(
    () =>
      htmlMenuScrapingScreenshotUrl ? (
        <img
          src={htmlMenuScrapingScreenshotUrl}
          alt={htmlMenuScrapingScreenshotUrl}
          style={imageStyles}
        />
      ) : (
        <Empty
          imageStyle={{ height: 200 }}
          description={
            <Typography.Text>
              No screenshot available for the selected item.
            </Typography.Text>
          }
        />
      ),
    [imageStyles, htmlMenuScrapingScreenshotUrl],
  )

  const handleDetailsPopoverVisibility = useCallback(visible => {
    setIsDetailsPopoverOpen(visible)
  }, [])

  return (
    <Layout
      style={{
        background: THEME.colors.white,
        paddingLeft: '20px',
        overflow: 'hidden',
      }}
    >
      <Content>
        {isLoadingScrapingScreenshot ? <Spinner /> : renderedContent}
      </Content>
      <Footer style={{ background: THEME.colors.white, textAlign: 'right' }}>
        <Layout
          style={{
            background: THEME.colors.white,
            type: 'flex',
            alignItems: 'flex-end',
          }}
        >
          <Sider
            width="35%"
            style={{ background: THEME.colors.white, textAlign: 'left' }}
          >
            {currentScreenshot?.amazonS3File && (
              <>
                <Row>
                  <Typography.Text strong>Screenshot Details</Typography.Text>{' '}
                  <Popover
                    content={
                      <ScrapingDebugScreenshotStatsPanel
                        screenshot={currentScreenshot}
                      />
                    }
                    title={
                      <Row
                        type="flex"
                        justify="space-between"
                        style={{ minWidth: '350px' }}
                      >
                        <Typography.Text strong>Details</Typography.Text>
                        <Icon
                          style={{ paddingTop: '5px' }}
                          type="close"
                          onClick={() => handleDetailsPopoverVisibility(false)}
                        />
                      </Row>
                    }
                    trigger="click"
                    placement="bottomLeft"
                    visible={isDetailsPopoverOpen}
                  >
                    <Icon
                      type="info-circle"
                      onClick={() => handleDetailsPopoverVisibility(true)}
                    />
                  </Popover>
                </Row>
                <Row>
                  <Typography.Text strong>
                    Screenshot Name:{' '}
                    {getFileNameFromPathWithoutExtension(
                      currentScreenshot.amazonS3File.fileKey,
                      '.png',
                    )}
                  </Typography.Text>
                </Row>
                <Row>
                  <Typography.Text strong>
                    Screenshot Time: {currentScreenshot.timestamp}
                  </Typography.Text>
                </Row>
                <Row>
                  <Typography.Text strong>
                    Selected Item Screenshot Number:{' '}
                    {currentScreenshot.screenshotNumber} of{' '}
                    {currentScreenshot.scrapedItemScreenshotsCount}
                  </Typography.Text>
                </Row>
              </>
            )}
          </Sider>
          <Sider width="65%" style={{ background: THEME.colors.white }}>
            <ScrapingDebugGalleryPagination
              currentPageNo={currentPageNo}
              currentScreenshot={currentScreenshot}
              numberOfScreenshots={size(screenshots)}
              firstScreenshotIndexByScrapedItemHash={
                firstScreenshotIndexByScrapedItemHash
              }
              handlePageChange={handlePageChange}
            />
          </Sider>
        </Layout>
      </Footer>
    </Layout>
  )
}

ScrapingDebugGallery.propTypes = {
  menuId: PropTypes.string.isRequired,
  scrapedItems: PropTypes.array,
  selectedItemHash: PropTypes.string,
  selectedItemSource: PropTypes.string,
  handleSelectedHashChange: PropTypes.func.isRequired,
}

ScrapingDebugGallery.defaultProps = {
  scrapedItems: undefined,
  selectedItemHash: undefined,
  selectedItemSource: undefined,
}

export default ScrapingDebugGallery
