import React, { useCallback } from 'react'
import { useHistory } from 'react-router-dom'
import { useMutation, useQuery } from '@apollo/react-hooks'
import { Modal } from 'antd'
import PropTypes from 'prop-types'

import { Spinner } from '../../../../common/components'
import {
  showErrorNotification,
  showSuccessNotification,
} from '../../../../common/helpers'
import { getCachedFilterForPage } from '../../../../common/services'

import { MENU_SCRAPING_REJECTED_COMMENT } from '../../../../menus/graphql/queries'
import { RESOLVE_DISH_REVIEW_TASK_BY_REJECT_PDF_MENU } from '../../../graphql/mutations'
import { RejectPdfMenuScrapingForm } from '../../forms'

const onSubmit = (variables, setScrapingRejectedComment) =>
  setScrapingRejectedComment({ variables })

const RejectPdfMenuScrapingModal = ({
  taskId,
  menuId,
  isModalOpen,
  setIsModalOpen,
}) => {
  const history = useHistory()

  const {
    data: { menuScrapingRejectedComment } = {},
    loading: isLoadingMenuScrapingRejectedComment,
  } = useQuery(MENU_SCRAPING_REJECTED_COMMENT, {
    variables: { id: menuId },
    fetchPolicy: 'no-cache',
  })

  const [
    resolveDishReviewTaskByRejectPdfMenu,
    { loading: isRejectingMenu },
  ] = useMutation(RESOLVE_DISH_REVIEW_TASK_BY_REJECT_PDF_MENU, {
    onCompleted() {
      showSuccessNotification({
        message: 'Reject menu',
        description:
          'Menu scraping rejection comment has been successfully set and dish review task was resolved by rejecting menu',
      })
      setIsModalOpen(false)
      history.push(getCachedFilterForPage('/tasks/dish-review'))
    },

    onError({ message }) {
      showErrorNotification({
        message: 'Menu scraping rejection comment.',
        description: message,
      })
    },
  })

  const handleSubmit = useCallback(
    menu => onSubmit(menu, resolveDishReviewTaskByRejectPdfMenu),
    [resolveDishReviewTaskByRejectPdfMenu],
  )

  return isLoadingMenuScrapingRejectedComment ? (
    <Spinner />
  ) : (
    <Modal
      title="Reject scraping"
      visible={isModalOpen}
      onCancel={() => setIsModalOpen(false)}
      footer={null}
    >
      <RejectPdfMenuScrapingForm
        taskId={taskId}
        menuScrapingRejectedComment={menuScrapingRejectedComment}
        closeModal={() => setIsModalOpen(false)}
        isRejectingMenu={isRejectingMenu}
        onSubmit={handleSubmit}
      />
    </Modal>
  )
}

RejectPdfMenuScrapingModal.propTypes = {
  taskId: PropTypes.string.isRequired,
  menuId: PropTypes.string.isRequired,
  isModalOpen: PropTypes.bool.isRequired,
  setIsModalOpen: PropTypes.func.isRequired,
}

export default RejectPdfMenuScrapingModal
