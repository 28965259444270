import { gql } from 'apollo-boost'

const SAVE_MENU_SCRAPING_TASK = gql`
  mutation saveMenuScrapingTask(
    $id: ID!
    $menuData: SaveMenuScrapingTaskMenuInput!
    $menuPdfFilesData: [MenuScrapingTaskMenuPdfFileInput!]
  ) {
    saveMenuScrapingTask(
      id: $id
      menuData: $menuData
      menuPdfFilesData: $menuPdfFilesData
    ) {
      id
    }
  }
`

export default SAVE_MENU_SCRAPING_TASK
