import React, { useMemo } from 'react'
import { useLocation } from 'react-router-dom'
import { Col, Row } from 'antd'
import { includes } from 'lodash-es'

import { Panel } from '../../../../core/components/styled'
import { getQueryParams } from '../../../../core/utils/services/queryParams'

import {
  MenuIsAwaitingProbingFilter,
  MenuIsAwaitingScrapingFilter,
  MenuStatusFilter,
  SearchBrandFilter,
  SearchMenuFilter,
  TaskAssignedToFilter,
  TaskAwaitingReasonFilter,
  TaskHasScrapingIssueCommentFilter,
  TaskIsAssignedFilter,
  TaskIsAwaitingFilter,
  TaskIsVerifiedFilter,
  TaskReopenReasonFilter,
  TaskStatusFilter,
} from '../../../../common/components'
import {
  ADMIN,
  DEFINER_JUNIOR_SCRAPER,
  DEFINER_MIDDLE_SCRAPER,
  DEFINER_MIDDLE_SCRAPER_REVIEWER,
  DEFINER_SENIOR_SCRAPER_REVIEWER,
  JUNIOR_SCRAPER,
  MIDDLE_SCRAPER,
  MIDDLE_SCRAPER_REVIEWER,
  QUALITY_CHECKER_MIDDLE_SCRAPER,
  QUALITY_CHECKER_MIDDLE_SCRAPER_REVIEWER,
  QUALITY_CHECKER_SENIOR_SCRAPER_REVIEWER,
  SENIOR_SCRAPER,
  SENIOR_SCRAPER_REVIEWER,
  TEAM_LEADER,
} from '../../../../common/constants'

import { MenuFormatFilter } from '../../../../menus/components/pages/sections'
import { REOPENED } from '../../../constants'

const MenuScrapingTasksFilters = () => {
  const { search } = useLocation()
  const { isAssigned, isAwaiting, statuses } = getQueryParams(search)

  const isReopenStatusSelected = useMemo(() => includes(statuses, REOPENED), [
    statuses,
  ])

  return (
    <Panel margin="small no">
      <Row gutter={16}>
        <Col span={6}>
          <SearchBrandFilter />
        </Col>
        <Col span={7}>
          <SearchMenuFilter />
        </Col>
      </Row>
      <Panel margin="small no">
        <Row gutter={[16, 16]}>
          <Col span={6}>
            <TaskStatusFilter />
          </Col>
          <Col span={5}>
            <TaskReopenReasonFilter disabled={!isReopenStatusSelected} />
          </Col>
          <Col span={2}>
            <TaskIsAssignedFilter />
          </Col>
          <Col span={3}>
            <TaskAssignedToFilter
              disabled={!isAssigned}
              roles={[
                ADMIN,
                TEAM_LEADER,
                DEFINER_JUNIOR_SCRAPER,
                DEFINER_MIDDLE_SCRAPER,
                DEFINER_MIDDLE_SCRAPER_REVIEWER,
                DEFINER_SENIOR_SCRAPER_REVIEWER,
                JUNIOR_SCRAPER,
                MIDDLE_SCRAPER,
                MIDDLE_SCRAPER_REVIEWER,
                SENIOR_SCRAPER,
                SENIOR_SCRAPER_REVIEWER,
                QUALITY_CHECKER_MIDDLE_SCRAPER,
                QUALITY_CHECKER_MIDDLE_SCRAPER_REVIEWER,
                QUALITY_CHECKER_SENIOR_SCRAPER_REVIEWER,
              ]}
            />
          </Col>

          <Col span={2}>
            <TaskIsAwaitingFilter />
          </Col>

          <Col span={3}>
            <TaskAwaitingReasonFilter disabled={!isAwaiting} />
          </Col>
        </Row>

        <Row gutter={[16, 16]}>
          <Col span={3}>
            <MenuIsAwaitingScrapingFilter />
          </Col>

          <Col span={3}>
            <MenuIsAwaitingProbingFilter />
          </Col>
        </Row>

        <Row gutter={16}>
          <Col span={6}>
            <MenuFormatFilter mode="multiple" width="100%" />
          </Col>
          <Col span={5}>
            <MenuStatusFilter mode="multiple" />
          </Col>

          <Col span={2}>
            <TaskHasScrapingIssueCommentFilter />
          </Col>

          <Col span={2}>
            <TaskIsVerifiedFilter />
          </Col>
        </Row>
      </Panel>
    </Panel>
  )
}

export default MenuScrapingTasksFilters
