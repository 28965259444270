import { mapKeys } from 'lodash-es'

export const MAIN = 'MAIN'
export const PARTIAL = 'PARTIAL'
export const KIDS = 'KIDS'
export const DRINKS = 'DRINKS'
export const TIME_LIMITED = 'TIME_LIMITED'
export const EVENTS = 'EVENTS'
export const FESTIVE = 'FESTIVE'
export const DIETARY = 'DIETARY'
export const SPECIAL = 'SPECIAL'
export const SET_MEAL = 'SET_MEAL'
export const TAKEWAY = 'TAKEWAY'
export const OTHER_LOCATION = 'OTHER_LOCATION'
export const NOISE = 'NOISE'
export const OTHERS = 'OTHERS'

export const MENU_CATEGORIES = mapKeys(
  [
    { name: 'Main', value: MAIN },
    { name: 'Partial', value: PARTIAL },
    { name: 'Kids', value: KIDS },
    { name: 'Drinks', value: DRINKS },
    { name: 'Time Limited', value: TIME_LIMITED },
    { name: 'Events', value: EVENTS },
    { name: 'Festive', value: FESTIVE },
    { name: 'Dietary', value: DIETARY },
    { name: 'Special', value: SPECIAL },
    { name: 'Set Meal', value: SET_MEAL },
    { name: 'Takeaway', value: TAKEWAY },
    { name: 'Other Location', value: OTHER_LOCATION },
    { name: 'Noise', value: NOISE },
    { name: 'Others', value: OTHERS },
  ],
  'value',
)
