import { mapKeys } from 'lodash-es'

export const HTML_MANUAL = 'HTML_MANUAL'
export const HTML_AUTOMATIC = 'HTML_AUTOMATIC'
export const HTML_TEMPLATE = 'HTML_TEMPLATE'
export const PDF = 'PDF'

export const MENU_SCRAPING_TYPES = mapKeys(
  [
    { name: 'HTML Manual', value: HTML_MANUAL },
    { name: 'HTML Automatic', value: HTML_AUTOMATIC },
    { name: 'HTML Template', value: HTML_TEMPLATE },
    { name: 'PDF', value: PDF },
  ],
  'value',
)
