import React from 'react'
import { Col, Row, Typography } from 'antd'
import PropTypes from 'prop-types'

const HtmlMenuScrapingJobStatsPanel = ({
  title,
  existingItemCount,
  scrapedItemCount,
  deletedItemCount,
  restoredItemCount,
  significantlyUpdatedItemCount,
  updatedItemCount,
  createdItemCount,
}) => {
  return (
    <>
      <Typography.Text strong>{title}</Typography.Text>
      <Row>
        <Typography.Text>Existing: {existingItemCount}</Typography.Text>
      </Row>
      <Row>
        <Typography.Text>Scraped: {scrapedItemCount}</Typography.Text>
      </Row>
      <Row>
        <Typography.Text>Deleted: {deletedItemCount}</Typography.Text>
      </Row>
      <Row>
        <Typography.Text>Restored: {restoredItemCount}</Typography.Text>
      </Row>
      <Row>
        <Typography.Text>
          Updated (significantly): {significantlyUpdatedItemCount}
        </Typography.Text>
      </Row>
      <Row>
        <Typography.Text>Updated: {updatedItemCount}</Typography.Text>
      </Row>
      <Row>
        <Typography.Text>Created: {createdItemCount}</Typography.Text>
      </Row>
    </>
  )
}

HtmlMenuScrapingJobStatsPanel.propTypes = {
  title: PropTypes.string.isRequired,
  existingItemCount: PropTypes.number.isRequired,
  scrapedItemCount: PropTypes.number.isRequired,
  deletedItemCount: PropTypes.number.isRequired,
  restoredItemCount: PropTypes.number.isRequired,
  significantlyUpdatedItemCount: PropTypes.number.isRequired,
  updatedItemCount: PropTypes.number.isRequired,
  createdItemCount: PropTypes.number.isRequired,
}

const HtmlMenuScrapingJobStats = ({ htmlMenuScrapingJob = {} }) => (
  <>
    <Col span={5}>
      <HtmlMenuScrapingJobStatsPanel
        title="Menu Titles Changes"
        existingItemCount={htmlMenuScrapingJob.existingMenuTitleCount}
        scrapedItemCount={htmlMenuScrapingJob.scrapedMenuTitleCount}
        deletedItemCount={htmlMenuScrapingJob.deletedMenuTitleCount}
        restoredItemCount={htmlMenuScrapingJob.restoredMenuTitleCount}
        significantlyUpdatedItemCount={
          htmlMenuScrapingJob.significantlyUpdatedMenuTitleCount
        }
        updatedItemCount={htmlMenuScrapingJob.updatedMenuTitleCount}
        createdItemCount={htmlMenuScrapingJob.createdMenuTitleCount}
      />
    </Col>
    <Col span={5}>
      <HtmlMenuScrapingJobStatsPanel
        title="Dishes Changes"
        existingItemCount={htmlMenuScrapingJob.existingDishCount}
        scrapedItemCount={htmlMenuScrapingJob.scrapedDishCount}
        deletedItemCount={htmlMenuScrapingJob.deletedDishCount}
        restoredItemCount={htmlMenuScrapingJob.restoredDishCount}
        significantlyUpdatedItemCount={
          htmlMenuScrapingJob.significantlyUpdatedDishCount
        }
        updatedItemCount={htmlMenuScrapingJob.updatedDishCount}
        createdItemCount={htmlMenuScrapingJob.createdDishCount}
      />
    </Col>
  </>
)

HtmlMenuScrapingJobStats.propTypes = {
  htmlMenuScrapingJob: PropTypes.object.isRequired,
}

export default HtmlMenuScrapingJobStats
