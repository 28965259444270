import React from 'react'
import { Icon, Typography } from 'antd'
import { filter, size } from 'lodash-es'

import { THEME } from '../../../core/constants'

import { PDF_MENU_SCRAPED_ITEM_DISCREPANCY_IMPACT_LEVEL } from '../../constants'

const nodeStyle = {
  fontStyle: 'italic',
  padding: '5px',
  borderRadius: '2px',
}

const getTreeSelectNodeTitle = (node, { discrepancies }) => {
  const errorCount = size(
    filter(
      discrepancies,
      ({ isHallucination, impactLevel }) =>
        isHallucination &&
        impactLevel === PDF_MENU_SCRAPED_ITEM_DISCREPANCY_IMPACT_LEVEL.HIGH,
    ),
  )

  const warningCount = size(
    filter(
      discrepancies,
      ({ isHallucination, impactLevel }) =>
        isHallucination &&
        impactLevel === PDF_MENU_SCRAPED_ITEM_DISCREPANCY_IMPACT_LEVEL.MEDIUM,
    ),
  )

  const totalCount = size(discrepancies)
  const infoCount = totalCount - errorCount - warningCount

  return (
    <>
      <Typography.Text
        style={{
          ...nodeStyle,
        }}
      >
        {node.title}
      </Typography.Text>
      {errorCount > 0 && (
        <>
          <Icon
            type="close-circle"
            theme="filled"
            style={{
              marginRight: '12px',
              width: '5px',
              heigh: '5px',
              color: THEME.colors.red,
            }}
          />
          <Typography.Text>{errorCount}</Typography.Text>
        </>
      )}
      {warningCount > 0 && (
        <>
          <Icon
            type="warning"
            theme="filled"
            style={{
              marginLeft: '5px',
              marginRight: '12px',
              width: '5px',
              heigh: '5px',
              color: THEME.colors.yellow,
            }}
          />
          <Typography.Text>{warningCount}</Typography.Text>
        </>
      )}
      {infoCount > 0 && (
        <>
          <Icon
            type="info-circle"
            theme="filled"
            style={{
              marginLeft: '5px',
              marginRight: '12px',
              width: '5px',
              heigh: '5px',
              color: THEME.colors.lightBlue,
            }}
          />
          <Typography.Text>{infoCount}</Typography.Text>
        </>
      )}
    </>
  )
}

export default getTreeSelectNodeTitle
