import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useQuery } from '@apollo/react-hooks'
import { Empty, Layout, Spin, Typography } from 'antd'
import { find, isEmpty, size } from 'lodash-es'
import PropTypes from 'prop-types'

import { THEME } from '../../../../../core/constants'

import {
  SCRAPED_ITEM_SELECTION_SOURCES,
  SCRAPED_ITEM_TYPES,
} from '../../../../constants'
import { PDF_MENU_SCRAPING_SCREENSHOT_URL } from '../../../../graphql/queries'

import ScrapingDebugGalleryPagination from './scraping-debug-gallery-pagination'

const { Footer, Content, Sider } = Layout

const ScrapingDebugGallery = ({
  scrapedItems,
  screenshots,
  selectedItemHash,
  selectedItemSource,
  handlePageChanged,
}) => {
  const [currentScreenshot, setCurrentScreenshot] = useState(null)
  const [currentPageNo, setCurrentPageNo] = useState(1)
  const [imageLoaded, setImageLoaded] = useState(false) // Nou: Stare pentru încărcarea imaginii

  const { data, loading: isLoadingScrapingScreenshot, error } = useQuery(
    PDF_MENU_SCRAPING_SCREENSHOT_URL,
    {
      variables: {
        bucketName: currentScreenshot?.bucketName,
        fileKey: currentScreenshot?.fileKey,
      },
      fetchPolicy: 'cache-first', // To avoid requesting a new url each time when the user navigates back and forth.
      skip:
        isEmpty(currentScreenshot?.bucketName) ||
        isEmpty(currentScreenshot?.fileKey),
    },
  )

  useEffect(() => {
    if (
      selectedItemSource ===
      SCRAPED_ITEM_SELECTION_SOURCES.SCRAPING_DEBUG_GALLERY
    )
      return

    const selectedItem = find(
      scrapedItems,
      item => item.id === selectedItemHash,
    )

    if (!selectedItem) return

    if (selectedItem.type === SCRAPED_ITEM_TYPES.MENU) {
      setCurrentScreenshot(screenshots[0])
      setCurrentPageNo(1)
    } else {
      setCurrentScreenshot(screenshots[selectedItem.pageNr])
      setCurrentPageNo(selectedItem.pageNr + 1)
    }
  }, [selectedItemSource, scrapedItems, screenshots, selectedItemHash])

  const imageStyles = useMemo(
    () => ({
      maxWidth: '100%',
      maxHeight: '100%',
      objectFit: 'contain',
    }),
    [],
  )

  const handlePageChange = useCallback(
    pageNo => {
      setCurrentPageNo(pageNo + 1)

      const screenshot = screenshots[pageNo]
      setCurrentScreenshot(screenshot)
      handlePageChanged(pageNo)
      setImageLoaded(false)
    },
    [screenshots, handlePageChanged],
  )

  const imageContainerStyles = useMemo(
    () => ({
      width: '100%',
      height: '86vh',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      backgroundColor: THEME.colors.lightGrey,
    }),
    [],
  )

  const renderedContent = useMemo(() => {
    if (isLoadingScrapingScreenshot) {
      return <Spin size="large" />
    }

    if (error) {
      return (
        <Typography.Text type="danger">
          Error loading screenshot.
        </Typography.Text>
      )
    }

    if (data?.pdfMenuScrapingScreenshotUrl) {
      return (
        <>
          {!imageLoaded && (
            <Spin
              size="large"
              style={{
                position: 'absolute',
              }}
            />
          )}
          <img
            src={data.pdfMenuScrapingScreenshotUrl}
            alt="Screenshot"
            style={{
              ...imageStyles,
              display: imageLoaded ? 'block' : 'none',
            }}
            onLoad={() => setImageLoaded(true)}
            onError={() => setImageLoaded(false)}
          />
        </>
      )
    }

    return (
      <Empty
        imageStyle={{ height: 200 }}
        description={
          <Typography.Text>
            No screenshot available for the selected item.
          </Typography.Text>
        }
      />
    )
  }, [isLoadingScrapingScreenshot, data, error, imageStyles, imageLoaded])

  return (
    <Layout
      style={{
        background: THEME.colors.white,
        paddingLeft: '20px',
        overflow: 'hidden',
      }}
    >
      <Content style={{ display: 'flex', justifyContent: 'center' }}>
        <div style={imageContainerStyles}>{renderedContent}</div>
      </Content>
      <Footer style={{ background: THEME.colors.white, textAlign: 'right' }}>
        <Layout
          style={{
            background: THEME.colors.white,
            display: 'flex',
            alignItems: 'flex-end',
          }}
        >
          <Sider
            width="35%"
            style={{ background: THEME.colors.white, textAlign: 'left' }}
          />
          <Sider width="65%" style={{ background: THEME.colors.white }}>
            <ScrapingDebugGalleryPagination
              currentPageNo={currentPageNo}
              currentScreenshot={currentScreenshot}
              numberOfScreenshots={size(screenshots)}
              handlePageChange={handlePageChange}
            />
          </Sider>
        </Layout>
      </Footer>
    </Layout>
  )
}

ScrapingDebugGallery.propTypes = {
  selectedItemHash: PropTypes.string,
  selectedItemSource: PropTypes.string,
  handlePageChanged: PropTypes.func.isRequired,
  scrapedItems: PropTypes.array.isRequired,
  screenshots: PropTypes.array.isRequired,
}

ScrapingDebugGallery.defaultProps = {
  selectedItemHash: undefined,
  selectedItemSource: undefined,
}

export default ScrapingDebugGallery
