import React, { useEffect, useMemo, useState } from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import { Form, Select } from 'antd'
import { map, omit, values } from 'lodash-es'
import PropTypes from 'prop-types'

import {
  HTML_NOT_SCRAPEABLE,
  MENU_FORMATS,
  PDF,
} from '../../../../menus/constants'

const MenuScrapingClusterExpectedMenuFormatDropdown = ({
  name,
  label,
  disabled,
}) => {
  const { control, getValues } = useFormContext()

  const [selectedValue, setSelectedValue] = useState(getValues(name))
  useEffect(() => {
    const newSelectedValue = getValues(name)
    setSelectedValue(newSelectedValue)
  }, [getValues, name, selectedValue])

  const options = useMemo(
    () => values(omit(MENU_FORMATS, [HTML_NOT_SCRAPEABLE, PDF])),
    [],
  )

  return (
    <Form.Item label={label}>
      <Controller
        control={control}
        name={name}
        render={({ onChange, value, ...props }) => (
          <Select
            disabled={disabled}
            allowClear
            value={selectedValue}
            onChange={value => {
              setSelectedValue(value)
              onChange(value)
            }}
            {...props}
          >
            {map(options, ({ name, value }) => (
              <Select.Option key={value} value={value}>
                {name}
              </Select.Option>
            ))}
          </Select>
        )}
      />
    </Form.Item>
  )
}

MenuScrapingClusterExpectedMenuFormatDropdown.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  disabled: PropTypes.bool.isRequired,
}

export default MenuScrapingClusterExpectedMenuFormatDropdown
