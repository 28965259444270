import { mapKeys } from 'lodash-es'

export const SUCCESS = 'SUCCESS'
export const PARTIAL_SUCCESS = 'PARTIAL_SUCCESS'
export const ERROR = 'ERROR'

export const PDF_MENU_SCRAPING_STATUSES = mapKeys(
  [
    { name: 'Success', value: SUCCESS },
    { name: 'Partial success', value: PARTIAL_SUCCESS },
    { name: 'Error', value: ERROR },
  ],
  'value',
)
