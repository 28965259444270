import { gql } from 'apollo-boost'

const ABORT_HTML_MENU_SCRAPING_SESSION = gql`
  mutation abortHtmlMenuScrapingSession($id: ID!) {
    abortHtmlMenuScrapingSession(id: $id) {
      id
      status
    }
  }
`

export default ABORT_HTML_MENU_SCRAPING_SESSION
