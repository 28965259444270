import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useQuery } from '@apollo/react-hooks'
import { Empty, Layout, Modal, Row, Typography } from 'antd'
import { find, isEmpty, map, reduce, size } from 'lodash-es'
import PropTypes from 'prop-types'

import { Spinner } from '../../../common/components'

import { SCRAPED_ITEM_TYPES } from '../../constants'
import { PDF_MENU_SCRAPING_RESPONSE } from '../../graphql/queries'
import { getDishName, getMenuTitleName } from '../../helpers'
import { getTreeSelectNodeTitle } from '../../helpers/pdf-menus'

import {
  ScrapingDebugGallery,
  ScrapingDebugInformation,
} from './sections/pdf-menus'
import { ScrapingDebugTreeSelect } from './sections'

const PdfMenuScrapingDebugModal = ({
  menu,
  itemHash,
  isModalOpen,
  closeModal,
}) => {
  const [scrapedItems, setScrapedItems] = useState([])
  const [screenshots, setScreenshots] = useState([])
  const [scrapedItemsIndexByHash, setScrapedItemsIndexByHash] = useState(
    new Map(),
  )
  const [
    scrapedItemsDiscrepanciesByHash,
    setScrapedItemsDiscrepanciesByHash,
  ] = useState(new Map())
  const [currentScrapedItem, setCurrentScrapedItem] = useState(null)
  const [selectedItemHash, setSelectedItemHash] = useState(itemHash)
  const [selectedItemSource, setSelectedItemSource] = useState(null)
  const [isScrapingResponseLoaded, setIsScrapingResponseLoaded] = useState(
    false,
  )

  const {
    data: { pdfMenuScrapingResponse } = {},
    loading: isLoading,
  } = useQuery(PDF_MENU_SCRAPING_RESPONSE, {
    variables: {
      pdfMenuScrapingJobId: menu.pdfMenuScrapingJobId,
    },
    fetchPolicy: 'no-cache',
    skip: !isModalOpen || isScrapingResponseLoaded,
  })

  useEffect(() => {
    if (!pdfMenuScrapingResponse) return

    setIsScrapingResponseLoaded(true)

    const {
      menuTree,
      correlationEvent,
      discrepancies: { hierarchyToOcr: discrepancies },
      annotatedImages,
      ...rest
    } = pdfMenuScrapingResponse.data || {}

    const menuSpecificData = {
      type: SCRAPED_ITEM_TYPES.MENU,
      id: menu.id,
      hashCode: menu.id,
      dishCount: size(menuTree.dishes),
      ...rest,
    }

    const discrepanciesByHash = reduce(
      discrepancies,
      (result, item) => ({
        ...result,
        [item.nodeId]: [...(result[item.nodeId] || []), item],
      }),
      {},
    )

    const { menuTitles = [], dishes = [] } = menuTree

    const menuTitlesWithType = map(menuTitles, menuTitle => ({
      ...menuTitle,
      type: SCRAPED_ITEM_TYPES.MENU_TITLE,
      title: getMenuTitleName(menuTitle),
      discrepancies: discrepanciesByHash[menuTitle.id] || [],
    }))

    const dishesWithType = map(dishes, dish => ({
      ...dish,
      type: SCRAPED_ITEM_TYPES.DISH,
      name: getDishName(dish),
      discrepancies: discrepanciesByHash[dish.id] || [],
    }))

    const allScrapedItems = [
      menuSpecificData,
      ...menuTitlesWithType,
      ...dishesWithType,
    ]

    setScrapedItems(allScrapedItems)
    setScreenshots(annotatedImages)

    const indexByHash = reduce(
      allScrapedItems,
      (result, item, index) => ({
        ...result,
        [item.id]: index,
      }),
      {},
    )

    setScrapedItemsIndexByHash(indexByHash)
    setScrapedItemsDiscrepanciesByHash(discrepanciesByHash)
  }, [pdfMenuScrapingResponse, menu])

  useEffect(() => setSelectedItemHash(itemHash), [itemHash])

  useEffect(() => {
    const scrapedItemIndex = scrapedItemsIndexByHash[selectedItemHash]
    setCurrentScrapedItem(scrapedItems[scrapedItemIndex])
  }, [
    scrapedItems,
    scrapedItemsIndexByHash,
    selectedItemHash,
    scrapedItemsDiscrepanciesByHash,
  ])

  const handleOnCancel = useCallback(() => {
    closeModal()
    setSelectedItemSource(null)
  }, [closeModal])

  const handleSelectedHashChange = useCallback(
    (itemHash, itemSource) => {
      setSelectedItemHash(itemHash)
      setSelectedItemSource(itemSource)
    },
    [setSelectedItemHash, setSelectedItemSource],
  )

  const handlePageChanged = useCallback(
    pageNo => {
      const selectedItem = find(
        scrapedItems,
        item => item.pageNr === pageNo - 1,
      )
      setSelectedItemHash(selectedItem?.id || menu.id)
    },
    [scrapedItems, menu],
  )

  const renderedContent = useMemo(
    () =>
      isEmpty(currentScrapedItem) ? (
        <Empty
          imageStyle={{ height: 200 }}
          description={
            <Typography.Text>
              No debug data available for this menu.
            </Typography.Text>
          }
        />
      ) : (
        <Layout>
          <ScrapingDebugInformation scrapedItem={currentScrapedItem} />
          <ScrapingDebugGallery
            scrapedItems={scrapedItems}
            screenshots={screenshots}
            selectedItemHash={selectedItemHash}
            selectedItemSource={selectedItemSource}
            handlePageChanged={handlePageChanged}
          />
        </Layout>
      ),
    [
      currentScrapedItem,
      handlePageChanged,
      scrapedItems,
      selectedItemHash,
      selectedItemSource,
      screenshots,
    ],
  )

  return (
    <Modal
      width="90vw"
      centered
      title={
        isEmpty(currentScrapedItem) ? (
          <br />
        ) : (
          <ScrapingDebugTreeSelect
            menuId={menu.id}
            scrapedItems={scrapedItems}
            selectedScrapedItem={currentScrapedItem}
            handleSelectedHashChange={handleSelectedHashChange}
            getTreeSelectNodeTitle={getTreeSelectNodeTitle}
          />
        )
      }
      visible={isModalOpen}
      onCancel={handleOnCancel}
      footer={null}
    >
      {isLoading ? (
        <Row type="flex" justify="center">
          <Spinner />
        </Row>
      ) : (
        renderedContent
      )}
    </Modal>
  )
}

PdfMenuScrapingDebugModal.propTypes = {
  menu: PropTypes.object.isRequired,
  itemHash: PropTypes.string,
  isModalOpen: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
}

PdfMenuScrapingDebugModal.defaultProps = {
  itemHash: undefined,
}

export default PdfMenuScrapingDebugModal
