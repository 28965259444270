import React, { useCallback, useMemo } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { useMutation, useQuery } from '@apollo/react-hooks'

import { ContentWithOverlay, Spinner } from '../../../common/components'
import {
  showErrorNotification,
  showSuccessNotification,
} from '../../../common/helpers'
import { useDocumentTitle } from '../../../common/hooks/effects'
import { getCachedFilterForPage } from '../../../common/services'

import { PDF } from '../../../menus/constants'
import {
  RESOLVE_MENU_SCRAPING_TASK,
  SAVE_MENU_SCRAPING_TASK,
} from '../../graphql/mutations'
import { MENU_SCRAPING_TASK } from '../../graphql/queries'
import {
  transformToMenuScrapingTask,
  transformToMenuScrapingTaskInput,
} from '../../graphql/transformers'
import { HtmlMenuScrapingTaskForm, PdfMenuScrapingTaskForm } from '../forms'

const onSubmit = (values, saveTask, resolveTask) => {
  const variables = transformToMenuScrapingTaskInput(values)

  return values.task.isDone
    ? resolveTask({
        variables,
      })
    : saveTask({
        variables,
      })
}

const getMenuScrapingTaskForm = (
  menuFormat,
  task,
  isSavingTask,
  isResolvingTask,
  handleSubmit,
) => {
  if (menuFormat === PDF) {
    return (
      <PdfMenuScrapingTaskForm
        task={task}
        isSavingTask={isSavingTask}
        isResolvingTask={isResolvingTask}
        onSubmit={handleSubmit}
      />
    )
  }

  return (
    <HtmlMenuScrapingTaskForm
      task={task}
      isSavingTask={isSavingTask}
      isResolvingTask={isResolvingTask}
      onSubmit={handleSubmit}
    />
  )
}

const MenuScrapingTaskPage = () => {
  useDocumentTitle('Tasks - Menu Scraping')
  const { taskId } = useParams()
  const history = useHistory()

  const {
    data = {},
    refetch: refetchMenuScrapingTask,
    loading: isLoadingTask,
  } = useQuery(MENU_SCRAPING_TASK, {
    variables: { id: taskId },
    fetchPolicy: 'no-cache',
  })

  const menuScrapingTask = useMemo(
    () =>
      data.menuScrapingTask &&
      transformToMenuScrapingTask(data.menuScrapingTask),
    [data.menuScrapingTask],
  )

  const [saveTask, { loading: isSavingTask }] = useMutation(
    SAVE_MENU_SCRAPING_TASK,
    {
      onCompleted() {
        refetchMenuScrapingTask()

        showSuccessNotification({
          message: 'Menu scraping task saved.',
          description: 'Task has been successfully saved.',
        })
      },

      onError({ message }) {
        showErrorNotification({
          message: 'Save menu scraping task failed.',
          description: message,
        })
      },
    },
  )

  const [resolveTask, { loading: isResolvingTask }] = useMutation(
    RESOLVE_MENU_SCRAPING_TASK,
    {
      onCompleted() {
        showSuccessNotification({
          message: 'Menu scraping task resolved.',
          description: 'Task has been successfully resolved.',
        })
        history.push(getCachedFilterForPage('/tasks/menu-scraping'))
      },

      onError({ message }) {
        showErrorNotification({
          message: 'Menu scraping task failed.',
          description: message,
        })
      },
    },
  )

  const handleSubmit = useCallback(
    task => onSubmit(task, saveTask, resolveTask),
    [saveTask, resolveTask],
  )

  if (isLoadingTask) return <Spinner />

  if (menuScrapingTask.menu.isDeleted)
    return (
      <ContentWithOverlay message="This menu is deleted">
        {getMenuScrapingTaskForm(
          menuScrapingTask.menu.format,
          menuScrapingTask,
          isSavingTask,
          isResolvingTask,
          handleSubmit,
        )}
      </ContentWithOverlay>
    )

  if (menuScrapingTask.menu.isAwaitingProbing)
    return (
      <ContentWithOverlay message="This menu is still waiting for probing">
        {getMenuScrapingTaskForm(
          menuScrapingTask.menu.format,
          menuScrapingTask,
          isSavingTask,
          isResolvingTask,
          handleSubmit,
        )}
      </ContentWithOverlay>
    )

  return getMenuScrapingTaskForm(
    menuScrapingTask.menu.format,
    menuScrapingTask,
    isSavingTask,
    isResolvingTask,
    handleSubmit,
  )
}

export default MenuScrapingTaskPage
