import React from 'react'
import { Collapse, List, Typography } from 'antd'
import PropTypes from 'prop-types'

import ScrapingDebugJsonPanel from '../scraping-debug-json-panel'

const ScrapingDebugInformationDish = ({ dish }) => (
  <Collapse
    defaultActiveKey={[0, 1]}
    style={{
      overflow: 'auto',
    }}
  >
    <Collapse.Panel header="Scraped Data" key={0}>
      <List size="small">
        <List.Item>
          <Typography.Text>
            <strong>Type: </strong>Dish
          </Typography.Text>
        </List.Item>
        <List.Item>
          <Typography.Text>
            <strong>Name: </strong>
            {dish.name}
          </Typography.Text>
        </List.Item>
        <List.Item>
          <Typography.Text>
            <strong>Price: </strong>
            {dish.price}
          </Typography.Text>
        </List.Item>
        <List.Item>
          <Typography.Text>
            <strong>Description: </strong>
            {dish.description}
          </Typography.Text>
        </List.Item>
        <List.Item>
          <Typography.Text>
            <strong>Ingredients: </strong>
            {dish.ingredients}
          </Typography.Text>
        </List.Item>
        <List.Item>
          <Typography.Text>
            <strong>Misc Info: </strong>
            {dish.miscInfo}
          </Typography.Text>
        </List.Item>
        <List.Item>
          <Typography.Text>
            <strong>Allergens: </strong>
            {dish.allergens}
          </Typography.Text>
        </List.Item>
      </List>
      <List.Item>
        <Typography.Text>
          <strong>Diets: </strong>
          {dish.diets}
        </Typography.Text>
      </List.Item>
      <List.Item>
        <Typography.Text>
          <strong>Addons: </strong>
          {dish.addons}
        </Typography.Text>
      </List.Item>
      <List.Item>
        <Typography.Text>
          <strong>Nutritions: </strong>
          {dish.nutritions}
        </Typography.Text>
      </List.Item>
    </Collapse.Panel>
    <ScrapingDebugJsonPanel
      header="Discrepancies"
      panelId={1}
      items={dish.discrepancies}
    />
  </Collapse>
)

ScrapingDebugInformationDish.propTypes = {
  dish: PropTypes.object.isRequired,
}

export default ScrapingDebugInformationDish
