import { gql } from 'apollo-boost'

const HTML_MENU_SCRAPING_SESSIONS = gql`
  query htmlMenuScrapingSessions($page: Int, $pageSize: Int) {
    htmlMenuScrapingSessions(page: $page, pageSize: $pageSize) {
      htmlMenuScrapingSessions {
        id
        status
        createdDate
        createdBy {
          id
          name
        }
        timeSlotCount
        startTime
        estimatedCompletionTime
        completedDate
        totalMenuCount
        scrapedMenuCount
        jobCountByScrapingStatus
      }
      total
    }
  }
`

export default HTML_MENU_SCRAPING_SESSIONS
