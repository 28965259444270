import { isEmpty } from 'lodash-es'

import { splitCsvToNumberArray } from '../utils'

const transformToStartHtmlMenuScrapingSessionInput = values => {
  const data = {
    ignoreWorkingHours: values.ignoreWorkingHours,
    timeSlotCount: values.timeSlotCount,
  }

  if (!isEmpty(values.menuIds)) {
    data.menuIds = splitCsvToNumberArray(values.menuIds)
  } else {
    data.menuStatuses = values.menuStatuses
    data.scrapedBeforeDate = values.scrapedBeforeDate
    data.includeAllAvailableMenus = values.includeAllAvailableMenus

    if (!data.includeAllAvailableMenus) {
      data.menuCount = values.menuCount
    }
  }

  return { data }
}

export default transformToStartHtmlMenuScrapingSessionInput
