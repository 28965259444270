import { map, reduce } from 'lodash-es'

import {
  transformScrapedItemsToTree,
  transformTreeToArray,
} from '../../transformers'

const getScrapingItemsOrderedForGallery = (scrapedItems, menuId) => {
  const treeData = transformScrapedItemsToTree(scrapedItems, menuId)
  const scrapedItemsOrdered = transformTreeToArray(treeData[0])

  const scrapedItemsById = reduce(
    scrapedItems,
    (result, item) => ({
      ...result,
      [item.id]: item,
    }),
    {},
  )

  return map(scrapedItemsOrdered, item => scrapedItemsById[item.id])
}

export default getScrapingItemsOrderedForGallery
