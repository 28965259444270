import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { useQuery } from '@apollo/react-hooks'
import { Col, Row, Tabs } from 'antd'
import arrayToTree from 'array-to-tree'
import {
  filter,
  forEach,
  get,
  includes,
  isEmpty,
  lowerCase,
  map,
} from 'lodash-es'
import PropTypes from 'prop-types'

import { createUrl } from '../../../../../core/utils/services/queryParams'

import { HTML_AUTOMATIC, SCRAPING_EVENT } from '../../../../../common/constants'

import { SCRAPED_ITEM_PLACEHOLDER_TITLES } from '../../../../constants'
import {
  COMPLETED_HTML_MENU_SCRAPING_JOBS_FOR_MENU,
  HTML_MENU_SCRAPING_JOB,
} from '../../../../graphql/queries'
import {
  attachMenuTitlePathToDishes,
  getHtmlMenuScrapingVersionDishesChanges,
  getHtmlMenuScrapingVersionMenuTitlesChanges,
} from '../../../../helpers'
import DishesTable from '../dishes-table'
import MenuTitlesTable from '../menu-titles-table'

import HtmlMenuScrapingJobStats from './html-menu-scraping-job-stats'
import MenuChangesColorLegend from './menu-changes-color-legend'
import MenuScrapingVersionsDropdown from './menu-scraping-versions-dropdown'

const { TabPane } = Tabs

const HtmlMenuTitlesAndDishesTables = ({
  menu,
  menuTitles = [],
  dishes = [],
  isExpanded,
  page,
  pageSize,
  onShowScrapingDetailsButtonClick,
}) => {
  const history = useHistory()
  const { search, pathname } = useLocation()

  const [selectedMenuTitleIds, setSelectedMenuTitleIds] = useState([])
  const [searchByDishName, setSearchByDishName] = useState('')
  const [selectedDescriptionFilter, setSelectedDescriptionFilter] = useState()
  const [selectedCaloriesFilter, setSelectedCaloriesFilter] = useState()
  const [selectedDishDietsFilter, setSelectedDishDietsFilter] = useState()
  const [selectedDishAddonsFilter, setSelectedDishAddonsFilter] = useState()
  const [
    selectedDishMiscInfosFilter,
    setSelectedDishMiscInfosFilter,
  ] = useState()
  const [
    selectedDishAllergensFilter,
    setSelectedDishAllergensFilter,
  ] = useState()
  const [
    selectedDishIngredientsFilter,
    setSelectedDishIngredientsFilter,
  ] = useState()
  const [
    selectedDishNutritionsFilter,
    setSelectedDishNutritionsFilter,
  ] = useState()

  const [menuTitlesToShow, setMenuTitlesToShow] = useState(menuTitles)
  const [dishesToShow, setDishesToShow] = useState(dishes)
  const [showFullMenu, setShowFullMenu] = useState(true)
  const [htmlMenuScrapingJob, setHtmlMenuScrapingJob] = useState()
  const [htmlMenuScrapingJobId, setHtmlMenuScrapingJobId] = useState()
  const [scrapingResponses, setScrapingResponses] = useState([])

  const {
    data: { htmlMenuScrapingJob: scrapingJob } = {},
    refetch: refetchHtmlMenuScrapingJob,
  } = useQuery(HTML_MENU_SCRAPING_JOB, {
    skip: !htmlMenuScrapingJobId,
    variables: { id: htmlMenuScrapingJobId },
    fetchPolicy: 'cache-first',
    onCompleted() {
      setHtmlMenuScrapingJob(scrapingJob)
    },
  })

  const {
    data: { completedHtmlMenuScrapingJobsForMenu: completedJobs } = {},
  } = useQuery(COMPLETED_HTML_MENU_SCRAPING_JOBS_FOR_MENU, {
    variables: { menuId: menu.id },
    fetchPolicy: 'no-cache',
    onCompleted() {
      const scrapingResponses =
        menu.scrapingType === HTML_AUTOMATIC
          ? completedJobs
          : filter(completedJobs, job => job.responseType === SCRAPING_EVENT)

      setScrapingResponses(scrapingResponses)
    },
  })

  useEffect(() => {
    if (!htmlMenuScrapingJobId) return
    refetchHtmlMenuScrapingJob()
  }, [htmlMenuScrapingJobId, refetchHtmlMenuScrapingJob])

  const showLastFullMenu = useMemo(() => {
    if (!htmlMenuScrapingJob) return true

    return (
      menu.htmlMenuScrapingJobId === htmlMenuScrapingJob?.id && showFullMenu
    )
  }, [menu, htmlMenuScrapingJob, showFullMenu])

  useEffect(() => {
    if (showLastFullMenu) {
      setMenuTitlesToShow(menuTitles)
      setDishesToShow(dishes)
    } else if (showFullMenu) {
      const {
        menuTree: { menuTitles: scrapedMenuTitles, dishes: scrapedDishes },
      } = htmlMenuScrapingJob.htmlMenuScrapingResponse.data

      setMenuTitlesToShow(scrapedMenuTitles)
      setDishesToShow(
        attachMenuTitlePathToDishes(scrapedDishes, scrapedMenuTitles),
      )
    } else {
      const changedDishes = getHtmlMenuScrapingVersionDishesChanges(
        htmlMenuScrapingJob,
      )

      const changedMenuTitles = getHtmlMenuScrapingVersionMenuTitlesChanges(
        htmlMenuScrapingJob,
        changedDishes,
      )

      setMenuTitlesToShow(changedMenuTitles)

      setDishesToShow(
        attachMenuTitlePathToDishes(changedDishes, changedMenuTitles),
      )
    }
  }, [menuTitles, dishes, htmlMenuScrapingJob, showFullMenu, showLastFullMenu])

  const menuTitlesForFilter = useMemo(
    () =>
      arrayToTree(
        map(menu.menuTitles, ({ id, title, parentId }) => ({
          text: title || SCRAPED_ITEM_PLACEHOLDER_TITLES.EMPTY_MENU_TITLE,
          value: id,
          id,
          parentId,
        })),
        {
          parentProperty: 'parentId',
        },
      ),
    [menu.menuTitles],
  )

  const handleMenuTitleRowClick = useCallback(
    menuTitle => {
      const collectIds = item => {
        let ids = [get(item, 'id')]
        forEach(get(item, 'children'), child => {
          ids = ids.concat(collectIds(child))
        })
        return ids
      }

      const newSelectedMenuTitleIds = collectIds(menuTitle)
      setSelectedMenuTitleIds(newSelectedMenuTitleIds)
    },
    [setSelectedMenuTitleIds],
  )

  const handleDishTableChange = useCallback(
    (pagination, filters) => {
      const { current, pageSize } = pagination
      history.push(createUrl(pathname, search, { page: current, pageSize }))

      setSelectedMenuTitleIds(filters.menuTitlePath)
      setSelectedDescriptionFilter(filters.description)
      setSelectedCaloriesFilter(filters.calories)
      setSelectedDishDietsFilter(filters.diets)
      setSelectedDishAddonsFilter(filters.addons)
      setSelectedDishMiscInfosFilter(filters.miscInfo)
      setSelectedDishAllergensFilter(filters.allergens)
      setSelectedDishIngredientsFilter(filters.ingredients)
      setSelectedDishNutritionsFilter(filters.nutritions)
    },

    [history, pathname, search],
  )

  const handleSelectedVersionChanged = useCallback(
    selectedHtmlMenuScrapingJobId =>
      setHtmlMenuScrapingJobId(selectedHtmlMenuScrapingJobId),
    [setHtmlMenuScrapingJobId],
  )

  const onSearchByDishName = useCallback(
    ({ target: { value } }) => setSearchByDishName(value),
    [setSearchByDishName],
  )

  const toggleMenuView = () => setShowFullMenu(!showFullMenu)

  const filteredDishesToShow = useMemo(() => {
    const lowercaseSearch = lowerCase(searchByDishName)
    return filter(dishesToShow, dish =>
      includes(lowerCase(dish.name), lowercaseSearch),
    )
  }, [dishesToShow, searchByDishName])

  return (
    <div style={{ marginTop: '20px' }}>
      <Row>
        {!isEmpty(scrapingResponses) && (
          <Col span={4}>
            <MenuScrapingVersionsDropdown
              scrapingResponses={scrapingResponses}
              onChange={handleSelectedVersionChanged}
            />
          </Col>
        )}
        {htmlMenuScrapingJob && (
          <>
            <Col span={13} offset={1}>
              <HtmlMenuScrapingJobStats
                htmlMenuScrapingJob={htmlMenuScrapingJob}
              />
            </Col>
            {!showFullMenu && (
              <Col span={3} offset={3}>
                <MenuChangesColorLegend />
              </Col>
            )}
          </>
        )}
      </Row>
      <Row>
        <Tabs defaultActiveKey="1" onChange={toggleMenuView}>
          <TabPane tab="Full Menu" key="1" />
          <TabPane tab="Changes" key="2" disabled={!htmlMenuScrapingJob} />
        </Tabs>

        <MenuTitlesTable
          menu={menu}
          menuTitlesToShow={menuTitlesToShow}
          lastScrapedMenuTitles={menuTitles}
          isExpanded={isExpanded}
          showLastFullMenu={showLastFullMenu}
          onRowClick={handleMenuTitleRowClick}
          onShowScrapingDetailsButtonClick={onShowScrapingDetailsButtonClick}
        />

        <DishesTable
          menu={menu}
          dishes={filteredDishesToShow}
          selectedCaloriesFilter={selectedCaloriesFilter}
          selectedDishDietsFilter={selectedDishDietsFilter}
          selectedDishAddonsFilter={selectedDishAddonsFilter}
          selectedDishMiscInfosFilter={selectedDishMiscInfosFilter}
          selectedDishAllergensFilter={selectedDishAllergensFilter}
          selectedDishIngredientsFilter={selectedDishIngredientsFilter}
          selectedDishNutritionsFilter={selectedDishNutritionsFilter}
          selectedDescriptionFilter={selectedDescriptionFilter}
          search={searchByDishName}
          onSearch={onSearchByDishName}
          menuTitlesForFilter={menuTitlesForFilter}
          selectedMenuTitleIds={selectedMenuTitleIds}
          showLastFullMenu={showLastFullMenu}
          page={page}
          pageSize={pageSize}
          onTableChange={handleDishTableChange}
          onShowScrapingDetailsButtonClick={onShowScrapingDetailsButtonClick}
        />
      </Row>
    </div>
  )
}

HtmlMenuTitlesAndDishesTables.propTypes = {
  menu: PropTypes.object.isRequired,
  menuTitles: PropTypes.array.isRequired,
  dishes: PropTypes.array.isRequired,
  isExpanded: PropTypes.bool.isRequired,
  page: PropTypes.number.isRequired,
  pageSize: PropTypes.number.isRequired,
  onShowScrapingDetailsButtonClick: PropTypes.func,
}

HtmlMenuTitlesAndDishesTables.defaultProps = {
  onShowScrapingDetailsButtonClick: undefined,
}

export default HtmlMenuTitlesAndDishesTables
