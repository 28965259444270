import { isNull, map, pick } from 'lodash-es'

import {
  APP_NAME,
  EMPTY_VALUE,
  NO,
  SCRAPING_STATUSES,
  YES,
} from '../../../../common/constants'

import {
  MENU_CRAWLING_REJECTION_REASONS,
  MENU_SCRAPING_REJECTION_REASONS,
  MENU_STATUSES,
} from '../../../../menus/constants'
import { TASK_AWAITING_REASONS, TASK_REOPEN_REASONS } from '../../../constants'

const getMenuScrapingSummary = scrapingSummary => ({
  ...scrapingSummary,
  menuAnnotations:
    map(scrapingSummary?.menuAnnotations, ({ fullMenu }) => fullMenu).join(
      ', ',
    ) || EMPTY_VALUE,
})

const getScrapingRejectedBy = values => {
  if (!values.scrapingRejectedDate) return { name: '-' }

  if (values.scrapingRejectedBy) {
    return values.scrapingRejectedBy
  }

  return { name: 'Scraping service' }
}

const getCrawlingRejectedBy = values => {
  if (!values.crawlingRejectedDate) return { name: '-' }

  if (values.crawlingRejectedBy) {
    return values.crawlingRejectedBy
  }

  return { name: 'Crawling service' }
}

const getMenuInitialValues = values => {
  const { pdfFiles, ...rest } = values

  return {
    ...rest,
    status: values.status && MENU_STATUSES[values.status].name,
    scrapingSummary: getMenuScrapingSummary(values.scrapingSummary),
    scrapingRejectedBy: getScrapingRejectedBy(values),
    scrapingRejectedReason: values.scrapingRejectedReason
      ? MENU_SCRAPING_REJECTION_REASONS[values.scrapingRejectedReason].name
      : '',
    crawlingRejectedBy: getCrawlingRejectedBy(values),
    crawlingRejectedReason: values.crawlingRejectedReason
      ? MENU_CRAWLING_REJECTION_REASONS[values.crawlingRejectedReason].name
      : '',
    crawlingStatus: values.menuCrawlingJob?.crawlingStatus
      ? SCRAPING_STATUSES[values.menuCrawlingJob?.crawlingStatus].name
      : '',
    crawlingDuration: values.menuCrawlingJob?.menuCrawlingResponse
      ? JSON.parse(values.menuCrawlingJob?.menuCrawlingResponse.data).duration
      : '',
  }
}

const getTaskInitialValues = values => {
  const task = pick(
    values,
    'id',
    'isAwaiting',
    'status',
    'modifiedDate',
    'verifiedBy',
    'verifiedDate',
    'isVerifiedInDebugMode',
    'resolvedBy',
    'reopenedBy',
    'reopenedDate',
    'reopenReason',
    'miscTaskVerifiedBy',
    'miscTaskVerifiedDate',
    'miscTaskVerifiedComment',
    'miscTaskQCVerifiedBy',
    'miscTaskQCVerifiedDate',
    'miscTaskQCVerifiedComment',
  )

  return {
    ...task,
    isAssigned: !isNull(values.assignedTo),
    assignedTo: {
      id: values.assignedTo?.id || '',
      name: values.assignedTo?.name || '',
    },
    awaitingReason: values.awaitingReason
      ? TASK_AWAITING_REASONS[values.awaitingReason].name
      : '',
    awaitingReasonComment: values.awaitingReasonComment || '',
    reopenedBy: {
      id: values.reopenedBy?.id || '',
      name: values.reopenedBy?.name || APP_NAME,
    },
    reopenReason: values.reopenReason
      ? TASK_REOPEN_REASONS[values.reopenReason].name
      : '',
    isRerunFlow: values.isRerunFlow ? YES : NO,
    isDone: false,
  }
}

const menuScrapingTaskFormInitialValues = values => ({
  task: getTaskInitialValues(values),
  menu: getMenuInitialValues(values.menu || {}),
  menuPdfFiles: values.menu.pdfFiles,
})

export default menuScrapingTaskFormInitialValues
