import { filter, isNull, pick, size } from 'lodash-es'

import { APP_NAME, NO, YES } from '../../../../common/constants'

import {
  MENU_SCRAPING_REJECTION_REASONS,
  MENU_STATUSES,
  PDF_MENU_SCRAPING_REASONS,
  PDF_MENU_SCRAPING_STATUSES,
} from '../../../../menus/constants'
import { TASK_AWAITING_REASONS, TASK_REOPEN_REASONS } from '../../../constants'

const getScrapingRejectedBy = values => {
  if (!values.scrapingRejectedDate) return { name: '-' }

  if (values.scrapingRejectedBy) {
    return values.scrapingRejectedBy
  }

  return { name: 'Scraping service' }
}

const getMenuInitialValues = values => {
  const menu = pick(
    values,
    'id',
    'brand',
    'locations',
    'url',
    'scrapedBy',
    'scrapedDate',
    'menuScrapingTemplateId',
    'menuScrapingClusterId',
    'pdfMenuScrapingJob',
    'scrapingRejectedDate',
    'scrapingRejectedBy',
    'scrapingRejectedComment',
  )

  const { pdfMenuScrapingJob } = menu

  return {
    ...menu,
    pdfMenuScrapingJob: {
      ...pdfMenuScrapingJob,
      scrapingStatus: pdfMenuScrapingJob?.scrapingStatus
        ? PDF_MENU_SCRAPING_STATUSES[pdfMenuScrapingJob.scrapingStatus].name
        : '',
      scrapingReason: pdfMenuScrapingJob?.scrapingReason
        ? PDF_MENU_SCRAPING_REASONS[pdfMenuScrapingJob.scrapingReason].name
        : '',
    },
    status: values.status && MENU_STATUSES[values.status].name,
    dishCount: size(filter(values.dishes, ({ isDeleted }) => !isDeleted)),
    scrapingRejectedBy: getScrapingRejectedBy(values),
    scrapingRejectedReason: values.scrapingRejectedReason
      ? MENU_SCRAPING_REJECTION_REASONS[values.scrapingRejectedReason].name
      : '',
  }
}

const getTaskInitialValues = values => {
  const task = pick(
    values,
    'id',
    'isAwaiting',
    'status',
    'modifiedDate',
    'reopenedBy',
    'reopenedDate',
    'reopenReason',
    'miscTaskVerifiedBy',
    'miscTaskVerifiedDate',
    'miscTaskVerifiedComment',
    'miscTaskQCVerifiedBy',
    'miscTaskQCVerifiedDate',
    'miscTaskQCVerifiedComment',
  )

  return {
    ...task,
    isAssigned: !isNull(values.assignedTo),
    assignedTo: {
      id: values.assignedTo?.id || '',
      name: values.assignedTo?.name || '',
    },
    awaitingReason: values.awaitingReason
      ? TASK_AWAITING_REASONS[values.awaitingReason].name
      : '',
    awaitingReasonComment: values.awaitingReasonComment || '',
    reopenedBy: {
      id: values.reopenedBy?.id || '',
      name: values.reopenedBy?.name || APP_NAME,
    },
    reopenReason: values.reopenReason
      ? TASK_REOPEN_REASONS[values.reopenReason].name
      : '',
    isAutoResolved: values.isAutoResolved ? YES : NO,
    isRerunFlow: values.isRerunFlow ? YES : NO,
    isDone: false,
  }
}

const dishReviewTaskFormInitialValues = values => {
  const task = getTaskInitialValues(values)
  const menu = getMenuInitialValues(values.menu || {})
  const { menuTitles, dishes } = values.menu

  const initialValues = {
    task,
    menu,
    dishes,
    menuTitles,
  }

  return initialValues
}

export default dishReviewTaskFormInitialValues
