import { gql } from 'apollo-boost'

const START_HTML_MENU_SCRAPING_SESSION = gql`
  mutation startHtmlMenuScrapingSession(
    $data: StartHtmlMenuScrapingSessionInput!
  ) {
    startHtmlMenuScrapingSession(data: $data) {
      id
    }
  }
`

export default START_HTML_MENU_SCRAPING_SESSION
