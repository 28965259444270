import { gql } from 'apollo-boost'

const START_HTML_TEMPLATE_VALIDATION_SESSION_FOR_CLUSTER = gql`
  mutation startHtmlTemplateValidationSessionForCluster(
    $id: ID!
    $data: StartHtmlTemplateValidationSessionForClusterInput!
  ) {
    startHtmlTemplateValidationSessionForCluster(id: $id, data: $data) {
      id
    }
  }
`

export default START_HTML_TEMPLATE_VALIDATION_SESSION_FOR_CLUSTER
